import stylesheetExports from '../../stylesheets/export.module.scss';
import { selectStyle } from '../Select/selectStyle';

const getGrayColor = (isDisabled) =>
  isDisabled ? stylesheetExports.gray700 : stylesheetExports.gray900;

export const multiSelectStyle = {
  ...selectStyle,
  control: (base) => ({
    ...base,
    overflow: 'hidden',
    boxShadow: 'none',
    backgroundColor: stylesheetExports.white,
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: stylesheetExports.white,
    borderRadius: stylesheetExports.borderRadius4,
    border: '1px solid',
    margin: '4px 4px',
    borderColor: getGrayColor(state.isDisabled),
    color: getGrayColor(state.isDisabled),
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: getGrayColor(state.isDisabled),
    fontSize: stylesheetExports.fontSize6,
    fontWeight: stylesheetExports.fontWeightSemiBold,
    margin: '0px',
    paddingLeft: '12px',
    paddingRight: '0px',
    paddingTop: '4px',
    paddingBottom: '4px',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    paddingLeft: '8px',
    paddingRight: '12px',
    paddingTop: '4px',
    paddingBottom: '4px',
    color: getGrayColor(state.isDisabled),
    '&:hover': {
      backgroundColor: 'transparent',
      color: stylesheetExports.darkPrimary,
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: stylesheetExports.dark200,
    fontWeight: stylesheetExports.fontWeightRegular,
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    fontSize: stylesheetExports.fontSize5,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 102, // when opened needs to be be above all components, except modals and alerts.
  }),
};
