import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Size } from '../../../../utils/types/Size';
import { Button } from 'reactstrap';
import { SpanTooltip } from '../../../../components/SpanTooltip';

interface PropTypes {
  text: string;
  url: string;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
  icon?: string;
  openInNewTab?: boolean;
  size?: Size;
}

function onClick(url: string, openInNewTab?: boolean): () => void {
  if (openInNewTab) {
    return () => window.open(url, '_blank', 'noopener,noreferrer');
  }

  return () => (window.location.href = url);
}

function ButtonWrapper(props: PropTypes) {
  return (
    <Button
      size={props.size}
      color='borderless'
      className={props.className}
      disabled={props.disabled}
      onClick={onClick(props.url, props.openInNewTab)}
    >
      {props.text}
    </Button>
  );
}

export function ActivityButton(props: PropTypes) {
  return props.icon ? (
    <IconButton
      buttonText={props.text}
      buttonClass={props.className}
      size={props.size}
      color='borderless'
      disabled={props.disabled}
      onClick={onClick(props.url, props.openInNewTab)}
      iconName={props.icon}
    />
  ) : props.tooltip ? (
    <SpanTooltip
      text={<ButtonWrapper {...props} />}
      tooltipText={props.tooltip}
    />
  ) : (
    <ButtonWrapper {...props} />
  );
}
