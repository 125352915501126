import React from 'react';
import { UseFormReset } from 'react-hook-form';
import { Button } from 'reactstrap';
import { PushToHrisFormValues } from '../..';

interface PropTypes {
  onClose: (succeeded: boolean) => void;
  reset: UseFormReset<PushToHrisFormValues>;
  isValid: boolean;
}

export function ExportAndCloseButtons(props: PropTypes) {
  const handleClose = () => {
    props.onClose(false);
    props.reset();
  };

  return (
    <div className='d-flex justify-content-end'>
      <Button className='me-2' color='secondary' onClick={() => handleClose()}>
        Cancel
      </Button>
      <Button color='primary' type='submit' disabled={!props.isValid}>
        Export
      </Button>
    </div>
  );
}
