import React from 'react';
import { IconEntry } from '../../IconEntry';
import { CopyButton } from '../../../../../../components/CopyButton';

interface PropTypes {
  redacted: boolean;
  email?: string;
}

export function EmailDetails(props: PropTypes) {
  if (props.email == null) {
    return null;
  }

  return (
    <IconEntry label={props.email} redacted={props.redacted} icon='bi-envelope'>
      <a className='text-info' href={`mailto:${props.email}`}>
        {props.email}
      </a>
      <CopyButton buttonClass='ms-1' hideText textToBeCopied={props.email} />
    </IconEntry>
  );
}
