import { SignableOfferForm } from '../..';
import { VARIABLES } from '../../../variables';

export const KNOWN_VARIABLES = VARIABLES.filter((v) => v.form).map(
  (v) => v.name,
);

export function isFormValid(form: SignableOfferForm, variables?: string[]) {
  if (variables == null) {
    return false;
  }

  for (const variable of variables) {
    switch (variable) {
      case 'bonus':
        if (!form.bonus || !form.bonusCurrency) {
          return false;
        }
        break;
      case 'candidate_name':
        if (!form.candidateName) {
          return false;
        }
        break;
      case 'company_name':
        if (!form.companyName) {
          return false;
        }
        break;
      case 'direct_manager':
        if (!form.directManager) {
          return false;
        }
        break;
      case 'equity':
        if (!form.equity) {
          return false;
        }
        break;
      case 'job_title':
        if (!form.jobTitle) {
          return false;
        }
        break;
      case 'offer_expiration_date':
        if (!form.offerExpirationDate) {
          return false;
        }
        break;
      case 'paid_time_off':
        if (!form.paidTimeOff) {
          return false;
        }
        break;
      case 'salary':
        if (!form.salary || !form.salaryCurrency) {
          return false;
        }
        break;
      case 'sender_name':
        if (!form.senderName) {
          return false;
        }
        break;
      case 'sick_days':
        if (!form.sickDays) {
          return false;
        }
        break;
      case 'starting_date':
        if (!form.startingDate) {
          return false;
        }
        break;
      case 'today_date':
        if (!form.todayDate) {
          return false;
        }
        break;
      case 'working_hours':
        if (!form.workingHours) {
          return false;
        }
        break;
      default:
        if (!form.customVariables.get(variable)) {
          return false;
        }
        break;
    }
  }

  return true;
}
