import React from 'react';
import { SignableOfferFeed } from '../../../../../entities/ApplicationFeed';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { AlertObject } from '../../../../../components/Alert';
import { GenericEntry } from '../..';
import { ViewOfferButton } from '../ViewOfferButton';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLastItemInPage: boolean;
  entry: SignableOfferFeed;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function SignableOfferAccepted(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-check2-circle'
      iconClassName='bg-light-success'
      title={
        <span>
          <strong>{props.application.candidate.name}</strong> has accepted the
          offer for the <strong>{props.application.job.name}</strong> role and
          all the documents have been signed.
        </span>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <ViewOfferButton entry={props.entry} />
    </GenericEntry>
  );
}
