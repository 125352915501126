import React from 'react';
import { AlertObject } from '../../../components/Alert';
import { SendSignableOfferModal } from '../SignableOffers/SendSignableOfferModal';
import { SendOfferModal } from '../Offers/SendOfferModal';

interface PropTypes {
  applicationId: number;
  atsSignableOfferEnabled: boolean;
  hasEmailConnected: boolean;
  isOpen: boolean;
  to: string;
  onClose: (succedded?: boolean) => void;
  setAlert: (alert: AlertObject) => void;
}

export function SendOfferSwitch(props: PropTypes) {
  if (props.atsSignableOfferEnabled) {
    return <SendSignableOfferModal {...props} />;
  }

  return <SendOfferModal {...props} />;
}
