import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../components/PageTitle';
import { Candidate } from '../../../../entities/Candidate';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { CandidateService } from '../../../../services/CandidateService';
import { Chip } from '../../../../components/Chip';
import { applicationStatusChipColor } from '../../../../utils/applicationStatusChipColor';
import { humanize } from '../../../../utils/humanize';
import { BackArrow } from '../../../../components/BackArrow';
import { Card } from '../../../../components/Card';
import { JobPostService } from '../../../../services/applicant_tracking/JobPostService';
import { FormState } from 'react-hook-form';
import { ApplicationForm } from '../ApplicationForm';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { Button } from 'reactstrap';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { Source } from '../../../../entities/applicant_tracking/Source';
import { Application } from '../../../../entities/applicant_tracking/Application';
import { SubmitButton } from '../../../../components/SubmitButton';
import { SourceService } from '../../../../services/v1/applicant_tracking/SourceService';
import { getRedirectUriQueryParam } from '../../../../utils/url';

interface PropTypes {
  currentUserId: number;
}

export default function NewApplicationPage(props: PropTypes) {
  const params = new URLSearchParams(window.location.search);
  const candidateId = parseInt(params.get('candidate_id'), 10);
  const isReferral = params.get('referral') === 'true';
  const [candidate, setCandidate] = useState<Candidate>(null);
  const [jobPosts, setJobPosts] = useState<JobPost[]>(null);
  const [appliedJobPostIds, setAppliedJobPostIds] = useState<number[]>([]);
  const [sources, setSources] = useState<Source[]>(null);

  useEffect(() => {
    CandidateService.show(candidateId).then((candidate) =>
      setCandidate(candidate),
    );
    JobPostService.list({
      excludingCandidateId: candidateId,
      restrictedAccess: !isReferral,
    }).then((jobPostList) => {
      setJobPosts(jobPostList.jobPosts);
      setAppliedJobPostIds(jobPostList.disabledJobPostIds);
    });
    SourceService.list({ pageSize: 100 }).then((sourceList) =>
      setSources(sourceList.sources),
    );
  }, []);

  async function handleSuccessfulSubmit(application: Application) {
    window.location.href = isReferral
      ? '/applicant_tracking/referrals'
      : getRedirectUriQueryParam() ?? `/applications/${application.id}`;
  }

  if (candidate == null) return <LoadingSpinner />;

  return (
    <div className='d-flex flex-column'>
      <PageHeader candidate={candidate} />
      <Card className='mt-4'>
        <ApplicationForm
          candidateId={candidate.id}
          jobPosts={jobPosts}
          appliedJobPostIds={appliedJobPostIds}
          sources={sources}
          onSuccessfulSubmit={handleSuccessfulSubmit}
          headerFactory={(formState) => (
            <FormHeader formState={formState} isReferral={isReferral} />
          )}
          referrerUserId={isReferral && props.currentUserId}
        />
      </Card>
    </div>
  );
}

function PageHeader(props: { candidate: Candidate }) {
  return (
    <div className='d-flex align-items-center'>
      <BackArrow />
      <PageTitle
        text={
          <>
            {props.candidate.firstName} {props.candidate.lastName}
          </>
        }
      />
      <Chip
        className={'fw-semibold ms-2h'}
        color={applicationStatusChipColor(props.candidate.status)}
      >
        {humanize(props.candidate.status)}
      </Chip>
    </div>
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  isReferral: boolean;
}) {
  const title = props.isReferral
    ? 'Add Referral Job Application'
    : 'Add Job Application';

  return (
    <div className='d-flex'>
      <h2>{title}</h2>
      <div className='ms-auto d-flex gap-2'>
        <Button onClick={() => history.back()}>Cancel</Button>
        <SubmitButton formState={props.formState}>Save and Submit</SubmitButton>
      </div>
    </div>
  );
}
