import React from 'react';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { AlertObject, AlertType } from '../../../../../components/Alert';
import { ApiError } from '../../../../../services/ApiService/errors/ApiError';
import { getNounWithPossessiveCase } from '../../../../../utils/grammar';
import { SignableOfferService } from '../../../../../services/v1/applicant_tracking/SignableOfferService';
import { SignableOffer } from '../../../../../entities/v1/applicant_tracking/SignableOffer';

interface PropTypes {
  id: number;
  isOpen: boolean;
  reloadApplication: () => void;
  setAlert: (alertObject: AlertObject) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export function RevokeSignableOfferModal(props: PropTypes) {
  async function handleConfirm() {
    await SignableOfferService.revoke(props.id)
      .then((offer: SignableOffer) =>
        props.setAlert({
          type: AlertType.Warning,
          message: (
            <span>
              <b>
                {getNounWithPossessiveCase(
                  offer.application?.candidate?.name || 'Candidate',
                )}
              </b>{' '}
              Offer for <b>{offer.application?.job?.name || 'the job'}</b> was
              successfully revoked!
            </span>
          ),
        }),
      )
      .catch((e: ApiError) =>
        props.setAlert({
          type: AlertType.Danger,
          message: e.body?.message || 'It was not possible to revoke the offer',
        }),
      );

    props.setIsOpen(false);
    props.reloadApplication();
  }

  return (
    <ConfirmationModal
      title='Revoking Offer'
      body={<span>Are you sure you want to revoke this Offer?</span>}
      isOpen={props.isOpen}
      onConfirm={handleConfirm}
      onCancel={() => props.setIsOpen(false)}
      confirmText='Revoke'
      size='md'
    />
  );
}
