import React from 'react';
import { InterviewListItem } from '../../../../entities/InterviewListItem';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import {
  formatTime,
  shortFormatDateWithHours,
} from '../../../../utils/timeFormat';
import { InterviewRatingResponse } from '../../../InterviewRatingResponse';
import { Avatar } from '../../../../components/Avatar';
import { DropdownInterview } from '../../../DropdownInterview';
import { User } from '../../../../entities/User';
import { HiringMember } from '../../../../entities/HiringMember';
import { JobHiringMemberRole } from '../../../../entities/JobHiringMemberRole';
import { capitalize } from 'humanize-plus';
import { getDisplayName } from '../../../../utils/getDisplayName';
import InterviewNoRecordingReasonInfo from '../../../InterviewsPage/InterviewNoRecordingReasonInfo';
import { appendRedirectUri } from '../../../../utils/url';
import { Link } from '../../../../components/Link';

interface PropTypes {
  currentUser: User;
  applicationId: number;
  interview: InterviewListItem;
  applicantTrackingEnabled: boolean;
  jobHiringMemberRole: JobHiringMemberRole;
  onInterviewSelected: (interview: InterviewListItem) => void;
  onCancelInterview: (id: string) => void;
}

function isInterviewUnscheduled(interview: InterviewListItem): boolean {
  return interview.status == 'unscheduled';
}

function Participants(props: { hiringMembers: HiringMember[] }) {
  return (
    <>
      {props.hiringMembers.map((hiring_member, idx) => (
        <Avatar
          key={idx}
          type={hiring_member.userPhotoUrl ? 'photo' : 'initials'}
          name={getDisplayName(
            hiring_member.name,
            capitalize(hiring_member.email),
            hiring_member.userPhotoUrl,
          )}
          size='sm'
        />
      ))}
    </>
  );
}

export function InterviewRow(props: PropTypes) {
  return (
    <tr>
      <td className='py-4 align-middle left-col'>
        <Link
          classNames={classNames('opacity-100 text-decoration-none', {
            'text-blue': props.interview.status === 'completed',
            'text-primary': props.interview.status !== 'completed',
          })}
          href={appendRedirectUri(props.interview.interviewUrl)}
          disabled={props.interview.status !== 'completed'}
        >
          <h3 className='m-0'>{props.interview.stage || 'N/A'}</h3>
        </Link>
      </td>
      <td className='align-middle'>
        <Badge
          color={props.interview.interviewStatusColor}
          className='rounded-pill me-1 fw-semibold'
        >
          <div className='d-flex align-items-center'>
            <div
              className={classNames(
                `text-${props.interview.interviewStatusTextColor}`,
              )}
            >
              {props.interview.interviewStatusText}
            </div>
            {props.interview.noRecordingReasons &&
              props.interview.noRecordingReasons.length > 0 && (
                <InterviewNoRecordingReasonInfo
                  noRecordingReasons={props.interview.noRecordingReasons}
                />
              )}
          </div>
        </Badge>
        {props.interview.interviewStatusText === 'Recorded' &&
          props.interview.transcriptStatusText === 'Transcription Failed' && (
            <Badge
              color={props.interview.transcriptStatusColor}
              className='rounded-pill me-1'
            >
              {props.interview.transcriptStatusText}
            </Badge>
          )}
      </td>
      <td className='py-4 align-middle'>
        <div className='d-inline-flex'>
          <Participants hiringMembers={props.interview.interviewers} />
        </div>
      </td>
      <td className='py-4 align-middle'>
        {!isInterviewUnscheduled(props.interview) && props.interview.duration
          ? formatTime(props.interview.duration)
          : '-'}
      </td>
      <td className='py-4 align-middle'>
        {!isInterviewUnscheduled(props.interview) && props.interview.date
          ? shortFormatDateWithHours(props.interview.date)
          : '-'}
      </td>
      <td className='py-4 align-middle'>
        {!isInterviewUnscheduled(props.interview) ? (
          <InterviewRatingResponse
            hideLabel={props.interview.interviewers.length > 1}
            interviewers={props.interview.interviewers}
            responses={props.interview.ratingResponses}
            ratingOptions={props.interview.ratingOptions}
          />
        ) : (
          '-'
        )}
      </td>
      {props.applicantTrackingEnabled && (
        <td className='py-4 align-middle'>
          <DropdownInterview
            applicationId={null}
            interview={props.interview}
            onCancelInterview={props.onCancelInterview}
            currentUser={props.currentUser}
            jobHiringMemberRole={props.jobHiringMemberRole}
            showSummaryOption={true}
            onInterviewSummarySelected={props.onInterviewSelected}
          />
        </td>
      )}
    </tr>
  );
}
