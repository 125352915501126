import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { SignableDocumentPreview } from '../../../../entities/v1/applicant_tracking/SignableDocumentPreview';

interface PreviewRequest {
  applicationId: number;
  variables: string;
}
export class SignableDocumentService {
  public static preview(
    id: number,
    previewRequest: PreviewRequest,
  ): Promise<SignableDocumentPreview> {
    return ApiService.get<SignableDocumentPreview>(
      `/api/v1/applicant_tracking/signable_documents/${id}/preview`,
      snakecaseKeys(previewRequest),
    );
  }
}
