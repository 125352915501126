import React, { useState } from 'react';
import { HorizontalCarousel } from '../../../../components/HorizontalCarousel';
import { User } from '../../../../entities/User';
import { CandidateCard } from '../CandidateCard';
import { JobDetails } from '../../../../entities/JobDetails';
import { JobStageLane } from '../JobStageLane';
import { AlertObject } from '../../../../components/Alert';
import { alertManager } from '../../components/MoveCandidateStageModal/alertManager';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { MoveCandidateProps } from '../../components/MoveCandidateStageModal';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { SortingColumn } from '../../../../utils/sorting';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PipelineJobStage } from '../../../../entities/v1/applicant_tracking/PipelineJobStage';

interface PropTypes {
  atsSignableOfferEnabled: boolean;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobDetails: JobDetails;
  pipelineJobStages: PipelineJobStage[];
  reloadJobStages: () => Promise<void>;
  setAlert: (alert: AlertObject) => void;
  sortingColumn: SortingColumn;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
}

function HorizontalCarouselPanel(
  props: PropTypes & {
    changeStageDragEndEvent: DragEndEvent;
    handleCandidateStageMove: (action: MoveCandidateProps) => void;
  },
) {
  const [isLoading, setIsLoading] = useState(true);
  const [jobStageIdsLoaded, setJobStageIdsLoaded] = useState<Set<number>>(
    new Set([]),
  );

  const addJobStageIdLoaded = (jobStageId: number) => {
    setJobStageIdsLoaded(jobStageIdsLoaded.add(jobStageId));

    setIsLoading(jobStageIdsLoaded.size !== props.pipelineJobStages.length);
  };

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <HorizontalCarousel
        elements={props.pipelineJobStages.map(
          (pipelineJobStage: PipelineJobStage, index: number) => (
            <JobStageLane
              key={index}
              atsSignableOfferEnabled={props.atsSignableOfferEnabled}
              changeStageDragEndEvent={props.changeStageDragEndEvent}
              addJobStageIdLoaded={addJobStageIdLoaded}
              applyAndScheduleLinkEnabled={props.applyAndScheduleLinkEnabled}
              selfSchedulingLinkOptionsEnabled={
                props.selfSchedulingLinkOptionsEnabled
              }
              jobDetails={props.jobDetails}
              pipelineJobStage={pipelineJobStage}
              currentHiringMember={props.jobDetails.hiringMembers.find(
                (v) => v.userId === props.currentUser.id,
              )}
              currentUser={props.currentUser}
              onMoveCandidate={props.handleCandidateStageMove}
              setAlert={props.setAlert}
              reloadJobStages={props.reloadJobStages}
              emailAccountIntegration={props.emailAccountIntegration}
              sortingColumn={props.sortingColumn}
            />
          ),
        )}
        classNames='ps-2 pb-5'
        buttonColor='white'
        border={false}
        allowHorizontalScroll={true}
        scrollWidth={266} // 250 (width) + 16 (margin)
      />
    </>
  );
}

export function JobPipelineBoard(props: PropTypes) {
  const [dragElement, setDragElement] = useState(null);
  const [changeStageDragEndEvent, setChangeStageDragEndEvent] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // 5px - minimum distance to start dragging (To differentiate from click event)
      },
    }),
  );

  const handleCandidateStageMove = async (action: MoveCandidateProps) => {
    alertManager(action, props.setAlert);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    if (event.over && event.active) {
      const application = event.active.data.current.application;
      const applicationId = parseInt(event.active.id.toString());

      const originalStageName = event.active.data.current.currentStage;
      const droppedStageName = event.over.id.toString();

      if (originalStageName === droppedStageName) {
        // If the user drops the card in the same stage, do nothing
        return;
      }

      setChangeStageDragEndEvent(event);

      const jobStageId = props.pipelineJobStages.find(
        (pipelineJobStage) =>
          pipelineJobStage.jobStage.name === droppedStageName,
      )?.jobStageId;
      try {
        await ApplicationService.moveStage(applicationId, jobStageId);

        handleCandidateStageMove({
          candidateName: application.candidate.name,
          jobStageName: droppedStageName,
          state: 'Success',
        });
      } catch (e) {
        console.error(
          `Could not move application ${applicationId} to stage ${jobStageId}`,
          e,
        );
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={setDragElement}
      onDragEnd={handleDragEnd}
    >
      <HorizontalCarouselPanel
        {...props}
        changeStageDragEndEvent={changeStageDragEndEvent}
        handleCandidateStageMove={handleCandidateStageMove}
      />
      <DragOverlay dropAnimation={null}>
        {dragElement && <CandidateCard {...dragElement.active.data.current} />}
      </DragOverlay>
    </DndContext>
  );
}
