import React, { useState } from 'react';
import { SignableOfferFeed } from '../../../../../entities/ApplicationFeed';
import { Button } from 'reactstrap';
import { SignableOfferSummaryModal } from '../../../ProfileTab/SignableOfferPanel/SignableOfferSummaryModal';
import { SpanTooltip } from '../../../../../components/SpanTooltip';

interface PropTypes {
  entry: SignableOfferFeed;
}

function isOfferDisabled(status: string) {
  if (status === 'pending') {
    return true;
  }

  return false;
}

function ButtonWrapper(props: {
  disabled?: boolean;
  setModalOpen: (flag: boolean) => void;
}) {
  return (
    <Button
      disabled={props.disabled}
      onClick={() => props.setModalOpen(true)}
      color='borderless'
    >
      View Offer
    </Button>
  );
}

export function ViewOfferButton(props: PropTypes) {
  const [modalOpen, setModalOpen] = useState(false);
  const disableOfferAction = isOfferDisabled(props.entry.signableOfferStatus);

  return (
    <>
      <SignableOfferSummaryModal
        isOpen={modalOpen}
        signableOfferId={props.entry.signableOfferId}
        onClose={() => setModalOpen(false)}
      />
      {disableOfferAction ? (
        <SpanTooltip
          className='text-center'
          text={<ButtonWrapper disabled={true} setModalOpen={setModalOpen} />}
          tooltipText='The offer submission is processing'
        />
      ) : (
        <ButtonWrapper setModalOpen={setModalOpen} />
      )}
    </>
  );
}
