import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { JobPublishPermissions } from '../../../../entities/v1/applicant_tracking/JobPublishPermissions';
import { JobUnpublishPermissions } from '../../../../entities/v1/applicant_tracking/JobUnpublishPermissions';

export class JobPermissionService {
  public static publish(
    jobId: number,
    expand?: string[],
  ): Promise<JobPublishPermissions> {
    return ApiService.get<JobPublishPermissions>(
      `/api/v1/applicant_tracking/job_permissions/publish`,
      snakecaseKeys({ jobId: jobId, expand: expand }),
    );
  }

  public static unpublish(
    jobId: number,
    expand?: string[],
  ): Promise<JobUnpublishPermissions> {
    return ApiService.get<JobUnpublishPermissions>(
      `/api/v1/applicant_tracking/job_permissions/unpublish`,
      snakecaseKeys({ jobId: jobId, expand: expand }),
    );
  }
}
