import React from 'react';
import {
  ApplicationListingFilters,
  FilterType,
  getAvailableFilters,
} from '../../utils';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DropdownGroupedList } from '../../../../../../../../components/DropdownGroupedList';
import { GroupedOption } from '../../../../../../../../components/GroupedMultiSelect';
import { updateUrlWithFilters } from '../../utils/filterUrlParams';

interface PropTypes {
  atsTagsPanelEnabled: boolean;
  filterTypes: FilterType[];
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
  setIsFiltersOpen: (value: boolean) => void;
}

function buildOptions(
  filterTypes: FilterType[],
  atsTagsPanelEnabled: boolean,
): GroupedOption[] {
  // This logic shall be reverted when we remove the atsTagsPanelEnabled feature flag
  const availableFilters = getAvailableFilters(atsTagsPanelEnabled);

  return availableFilters.map((filter) => ({
    label: filter.name,
    value: filter.value,
    selected: filter.default || filterTypes.includes(filter),
    category: filter.category,
    isDisabled: filter.default,
  }));
}

export function AddFilterButton(props: PropTypes) {
  // This logic shall be reverted when we remove the atsTagsPanelEnabled feature flag
  const availableFilters = getAvailableFilters(props.atsTagsPanelEnabled);
  return (
    <DropdownGroupedList
      buttonIcon={{ name: 'bi-plus-circle' }}
      buttonText='Add/Remove Filter'
      buttonClassName={classNames(
        'bg-blue text-white px-2h',
        styles['add-filter-button'],
      )}
      menuPlacementClass={classNames(
        'dropdown-menu-start mt-1',
        styles['dropdown-menu'],
      )}
      options={buildOptions(props.filterTypes, props.atsTagsPanelEnabled)}
      onChange={(filterName, value) => {
        if (value) {
          props.setFilterTypes([
            ...props.filterTypes,
            availableFilters.find((filter) => filter.value === filterName),
          ]);
          props.setIsFiltersOpen(true);
        } else {
          props.setFilterTypes(
            props.filterTypes.filter((filter) => filter.value !== filterName),
          );

          const newFilters = { ...props.filters };
          delete newFilters[filterName];
          props.setFilters(newFilters);
          updateUrlWithFilters(newFilters);
        }
      }}
    />
  );
}
