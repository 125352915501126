import React from 'react';
import { Col } from 'reactstrap';
import { ApplicationListingFilters } from '../../utils';
import { SelectOption } from '../../../../../../../../components/Select';
import { MultiSelectAsyncFilter } from '../../../../../../../../components/MultiSelectAsyncFilter';
import { humanize } from '../../../../../../../../utils/humanize';

export const CANDIDATE_STATUS_OPTIONS = [
  'active',
  'draft',
  'hired',
  'rejected',
];

interface PropTypes {
  isLoading: boolean;
  filters: ApplicationListingFilters;
  setFilters: (filters: ApplicationListingFilters) => void;
}

export function StatusFilter(props: PropTypes) {
  const loadStatuses = (search: string, _page: number) => {
    return Promise.resolve({
      options: CANDIDATE_STATUS_OPTIONS.filter((opt: string) =>
        search ? opt.includes(search.toLowerCase()) : true,
      ).map((status: string) => {
        return {
          value: status,
          label: humanize(status),
          selected:
            props.filters.statuses?.find((v) => v.value === status) != null,
        };
      }),
      entriesCount: CANDIDATE_STATUS_OPTIONS.length,
      page: 1,
      pageCount: 1,
      nextPage: null,
    });
  };

  return (
    <Col xs='3' className='mt-3h'>
      <MultiSelectAsyncFilter
        isMulti={true}
        loadOptions={loadStatuses}
        selected={props.filters.statuses}
        label='Status'
        labelClassName='fw-semibold'
        placeholder='Select statuses'
        onChange={(options: SelectOption[]) =>
          props.setFilters({ ...props.filters, statuses: options })
        }
      />
    </Col>
  );
}
