import React, { useEffect, useState } from 'react';
import { EmailModalBody } from '../../../../../SendEmailModal/EmailModalBody';
import { SelectOption } from '../../../../../../components/Select';
import { SignableOfferForm } from '../..';
import { AttachmentInputList } from '../../../../../SendEmailModal/EmailModalBody/EmailFooter/AttachmentInputList';
import { AttachmentDto } from '../../../../../../services/dtos/EmailDto';

interface PropTypes {
  applicationIds: number[];
  files: File[];
  form: SignableOfferForm;
  clearUploadedFiles: () => void;
  removeAttachmentFileByIndex: (index: number) => void;
  setForm: (form: SignableOfferForm) => void;
  setIsValid: (value: boolean) => void;
}

export function OfferEmail(props: PropTypes) {
  const [attachments, setAttachments] = useState<AttachmentDto[]>(
    props.form.attachments,
  );
  const [email, setEmail] = useState<string>(props.form.email);
  const [emailJson, setEmailJson] = useState<string>(props.form.emailJson);
  const [subject, setSubject] = useState<string>(props.form.subject);
  const [subjectJson, setSubjectJson] = useState<string>(
    props.form.subjectJson,
  );

  useEffect(() => {
    props.setForm({
      ...props.form,
      attachments: attachments,
      email: email,
      emailJson: emailJson,
      subject: subject,
      subjectJson: subjectJson,
    });
  }, [attachments, email, emailJson, subject, subjectJson]);

  return (
    <>
      <EmailModalBody
        applicationIds={props.applicationIds}
        bcc={props.form.bcc}
        cc={props.form.cc}
        disclaimer='The Dropbox sign link is always included in a button at the end of each email.'
        emailJson={props.form.emailJson}
        subjectJson={props.form.subjectJson}
        toEmail={props.form.to}
        strictTemplate={props.form.signableOfferTemplate.emailTemplate}
        clearUploadedFiles={props.clearUploadedFiles}
        setIsValid={props.setIsValid}
        setBcc={(bcc: SelectOption[]) =>
          props.setForm({ ...props.form, bcc: bcc })
        }
        setCc={(cc: SelectOption[]) => props.setForm({ ...props.form, cc: cc })}
        setEmailJson={setEmailJson}
        setHtml={setEmail}
        setSubjectText={setSubject}
        setSubjectJson={setSubjectJson}
        setAttachments={setAttachments}
      />
      <AttachmentInputList
        attachments={props.form.attachments}
        removeFileByIndex={props.removeAttachmentFileByIndex}
      />
    </>
  );
}
