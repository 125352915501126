import React from 'react';
import { Button } from 'reactstrap';
import { SurveyRequest } from '../../reference_check/entities/SurveyRequest';

export function ViewStatusButton(props: {
  surveyRequest: SurveyRequest;
  onClick: (open: boolean) => void;
}) {
  const dotClassName = inferDotClassName(props.surveyRequest);

  return (
    <Button type='button' color='secondary' onClick={() => props.onClick(true)}>
      <div className='d-flex justify-content-center align-items-center'>
        <div className={dotClassName} style={{ fontSize: '.5em' }}>
          <i className='bi bi-circle-fill position-static'></i>
        </div>
        <span className='ms-2'>View Status</span>
      </div>
    </Button>
  );
}

function inferDotClassName(surveyRequest?: SurveyRequest) {
  if (!surveyRequest) return 'text-success';

  const surveys = surveyRequest.referrals
    .map((referral) => referral.surveys)
    .flat();

  const allSubmitted =
    surveyRequest.referrals.reduce(
      (prev, curr) => prev + curr.surveys.length,
      0,
    ) >= surveyRequest.referrals.length;
  const allAnswered = surveys.every((survey) => survey.status === 'completed');
  const matchingIpExists = surveys.some(
    (survey) => survey.ip_matching_names.length > 0,
  );

  if (matchingIpExists) {
    return 'text-danger';
  } else if (!allSubmitted || !allAnswered) {
    return 'text-warning';
  } else {
    return 'text-success';
  }
}
