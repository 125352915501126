import React from 'react';
import { JobPostingDetailsForm } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails';
import { PostingBundle } from '../PostingBundle';
import { IndeedToggle } from './IndeedToggle';

interface PropTypes {
  form: JobPostingDetailsForm;
  indeedEasyApplyEnabled: boolean;
  indeedEnabled: boolean;
  atsIndeedIntegrationEnabled: boolean;
  organicEnabled: boolean;
  handleInputChange: (name: string, value: unknown) => void;
}

export function JobBoards(props: PropTypes) {
  return (
    <div>
      {props.atsIndeedIntegrationEnabled && (
        <IndeedToggle
          indeedEnabled={props.indeedEnabled}
          indeedEasyApplyEnabled={props.indeedEasyApplyEnabled}
          setIndeedEnabled={() =>
            props.handleInputChange('indeedEnabled', !props.indeedEnabled)
          }
          setIndeedEasyApplyEnabled={() =>
            props.handleInputChange(
              'indeedEasyApplyEnabled',
              !props.indeedEasyApplyEnabled,
            )
          }
        />
      )}
      <PostingBundle
        enabled={props.organicEnabled}
        form={props.form}
        setOrganicEnabled={() =>
          props.handleInputChange('organicEnabled', !props.organicEnabled)
        }
      />
    </div>
  );
}
