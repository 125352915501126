import { useCallback, useState } from 'react';
import { User } from '../../../../../../../entities/User';
import { SelectOption } from '../../../../../../../components/Select';
import { IconButton } from '../../../../../../../components/IconButton';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { InterviewerParticipant } from '../../../../../../../entities/applicant_tracking/InterviewerParticipant';
import { InterviewService } from '../../../../../../../services/v1/applicant_tracking/InterviewService';
import { InterviewUser } from '../../../../../../../entities/v1/applicant_tracking/InterviewUser';
import { SinglePaginatedSelect } from '../../../../../../../components/Select/SinglePaginatedSelect';
import { SchedulingUserIntegration } from '../../../../../../../entities/SchedulingUserIntegration';

interface PropTypes {
  participants: InterviewerParticipant[];
  setParticipants: (participants: InterviewerParticipant[]) => void;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

function buildSchedulingUserIntegration(
  interviewUser: InterviewUser,
): SchedulingUserIntegration {
  if (interviewUser.schedulingUserIntegration == null) return null;

  return {
    ...interviewUser.schedulingUserIntegration,
    availabilities: interviewUser.schedulingAvailabilities,
    availableMeetingProviders: interviewUser.availableMeetingProviders,
    defaultMeetingProvider: interviewUser.defaultMeetingProvider,
  };
}

function buildUser(interviewUser: InterviewUser): User {
  return {
    id: interviewUser.id,
    name: interviewUser.name,
    email: interviewUser.email,
    isAdmin: interviewUser.isAdmin,
    photoUrl: interviewUser.photoUrl,
    hasCalendarConnected:
      interviewUser.schedulingUserIntegration?.connectionStatus == 'connected',
    schedulingUserIntegration: buildSchedulingUserIntegration(interviewUser),
  };
}

export function AddParticipant(props: PropTypes) {
  const [availableParticipants, setAvailableParticipants] = useState<
    InterviewUser[]
  >([]);

  const loadAvailableParticipants = async (
    search: string,
    loadedOptions: unknown,
    { page }: AdditionalType,
  ) => {
    try {
      const list = await InterviewService.listAvailableParticipants({
        page: page,
        pageSize: 10,
        name: search,
      });

      if (page == 1) {
        setAvailableParticipants(list.interviewUsers);
      } else {
        availableParticipants.push(...list.interviewUsers);
      }

      const options = list.interviewUsers
        .filter(
          (interviewUser) =>
            props.participants.map((p) => p.user.id).indexOf(interviewUser.id) <
            0,
        ) // filter out the participants already added to the interview
        .map(
          (user) =>
            ({
              label: user.name,
              value: user.id.toString(),
            } as SelectOption),
        );

      return {
        options: options,
        hasMore: !!list.nextPage,
        additional: {
          page: page + 1,
        },
      };
    } catch (e) {
      console.error('Could not load available participants.', e);
    }
  };

  const [addingParticipant, setAddingParticipant] = useState<boolean>(false);

  const onParticipantSelection = useCallback(
    (option: SelectOption) => {
      if (option === null) return;

      props.setParticipants(
        props.participants.concat({
          user: buildUser(
            availableParticipants.find(
              (user) => user.id.toString() === option.value,
            ),
          ),
          isMeetingOrganizer: false,
          ignoreCalendar: false,
        }),
      );
      setAddingParticipant(false);
    },
    [props.participants, availableParticipants],
  );

  return (
    <div className={'mt-2h w-60'}>
      {addingParticipant && (
        <SinglePaginatedSelect
          autoFocus
          additional={defaultAdditional}
          placeholder='Select participant'
          onChange={onParticipantSelection}
          className='mt-3'
          isClearable={false}
          loadOptions={loadAvailableParticipants}
          closeMenuOnSelect={true}
          maxMenuHeight={150}
        />
      )}
      {!addingParticipant && (
        <IconButton
          color='borderless'
          size='sm'
          iconName='bi-plus-circle'
          buttonText={'Add Participant'}
          buttonClass={classNames(styles['add-participant'], 'fs-5')}
          disabled={
            availableParticipants.length > 0 &&
            props.participants.length >= availableParticipants.length
          }
          onClick={() => {
            setAddingParticipant(true);
          }}
        />
      )}
    </div>
  );
}
