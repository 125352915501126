import React from 'react';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../../../../components/InputFormField';
import classNames from 'classnames';
import { PhoneInputFormField } from '../../../../../../../components/PhoneInputFormField';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { PushToHrisFormValues } from '../../..';

interface PropTypes {
  register: UseFormRegister<PushToHrisFormValues>;
  control: Control<PushToHrisFormValues>;
  errors: FieldErrors;
}

export function ContactsRow(props: PropTypes) {
  return (
    <Row className='mb-3'>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('personalEmail', {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
          fieldName={'Email'}
          placeholder={'Insert email'}
          isRequired={true}
          classNames={classNames(props.errors.email && 'is-invalid', 'fs-5')}
        />
      </Col>
      <Col sm={6}>
        <PhoneInputFormField
          control={props.control}
          disabled={false}
          required={true}
          fieldName={'Phone'}
          controllerName={'phoneNumber'}
          placeholder={'Insert phone'}
          registerReturn={props.register('phoneNumber', {
            required: true,
          })}
          className={classNames(props.errors.phoneNumber && 'is-invalid')}
        />
      </Col>
    </Row>
  );
}
