import React from 'react';
import { EmailField } from '../EmailField';
import { JobPostingDetailsForm } from '..';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  mandatory?: boolean;
  disabled?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function ContactEmail(props: PropTypes) {
  return (
    <EmailField
      label='Contact Email'
      value={props.form.contactEmail}
      name='contactEmail'
      error={props.errors['contactInfo']?.['emailAddress']}
      mandatory={props.mandatory}
      onInputChange={props.onInputChange}
      placeholder={'Insert Email'}
      disabled={props.disabled}
    />
  );
}
