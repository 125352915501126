import React from 'react';
import { JobPostPageTab } from '../..';
import { Button } from 'reactstrap';
import ahoy from 'ahoy.js';
import { useFeatureFlags } from '../../../../../../contexts/FeatureFlagContext';
import { JobPostClickEvents } from '../../../../../../enums/ClickEvents/JobPostClickEvents';

interface PropTypes {
  previewMode: boolean;
  setActiveTab: (tab: JobPostPageTab) => void;
  organizationId: number;
  jobPostId: number;
}

export function OverviewApplyButton(props: PropTypes) {
  const { atsCaptureEventsAhoy } = useFeatureFlags();

  const handleClick = () => {
    if (atsCaptureEventsAhoy) {
      ahoy.track(JobPostClickEvents.OverviewApplyClicked, {
        organization_id: props.organizationId,
        applicant_tracking_job_post_id: props.jobPostId,
      });
    }
    props.setActiveTab('application_form');
  };

  return (
    <Button
      color={'primary'}
      size={'lg'}
      type={'button'}
      disabled={props.previewMode}
      onClick={handleClick}
    >
      Apply
    </Button>
  );
}
