import React, { useState } from 'react';
import { IconSpan } from '../../../../../components/IconSpan';
import { SignableDocumentService } from '../../../../../services/v1/applicant_tracking/SignableDocumentService';
import { SignableDocument } from '../../../../../entities/v1/applicant_tracking/SignableDocument';
import { LoadingSpinnerIcon } from '../../../../../components/LoadingSpinnerIcon';
import { SignableOfferForm } from '..';
import { variablesToMap } from '../../variables';
import { Button } from 'reactstrap';
import { Icon } from '../../../../../components/Icon';

interface PropTypes {
  signableDocuments: SignableDocument[];
  applicationId?: number;
  form?: SignableOfferForm;
}

interface DocumentPropTypes {
  document: SignableDocument;
  applicationId?: number;
  form?: SignableOfferForm;
}

async function previewDocument(
  applicationId: number,
  documentId: number,
  variables: Record<string, any>,
  setLoadingPreview: (flag: boolean) => void,
) {
  setLoadingPreview(true);

  try {
    const document = await SignableDocumentService.preview(documentId, {
      applicationId,
      variables: JSON.stringify(variables),
    });

    if (document.previewUrl) {
      window.open(document.previewUrl, '_blank');
    }
  } catch (e: any) {
    console.error('It was not possible to preview the document', e);
  } finally {
    setLoadingPreview(false);
  }
}

function DocumentPreview(props: DocumentPropTypes) {
  const [loadingPreview, setLoadingPreview] = useState(false);

  return (
    <div className='d-flex bg-gray rounded-2 mt-2 px-3 py-3h justify-content-between'>
      <IconSpan
        icon={{ name: 'bi-paperclip', className: 'text-dark-500 fs-5' }}
        spanClassName='text-info fs-5'
        spanText={props.document.fileName}
      />
      <Button
        type='button'
        color='borderless'
        disabled={loadingPreview}
        onClick={() =>
          props.form
            ? previewDocument(
                props.applicationId,
                props.document.id,
                variablesToMap(props.form),
                setLoadingPreview,
              )
            : window.open(props.document.url, '_blank')
        }
      >
        {loadingPreview ? (
          <LoadingSpinnerIcon className='text-info' />
        ) : (
          <Icon className='text-info' name='bi-box-arrow-up-right' />
        )}
      </Button>
    </div>
  );
}

export function OfferDocuments(props: PropTypes) {
  if (props.signableDocuments == null) {
    return null;
  }

  return (
    <div>
      <span className='fw-semibold fs-5'>
        The candidate will receive these documents to sign:
      </span>
      <div>
        {props.signableDocuments.map((d, index) => (
          <DocumentPreview
            key={index}
            document={d}
            applicationId={props.applicationId}
            form={props.form}
          />
        ))}
      </div>
    </div>
  );
}
