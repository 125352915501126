import React from 'react';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../../../../components/InputFormField';
import classNames from 'classnames';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { PushToHrisFormValues } from '../../..';
import { SelectOption } from '../../../../../../../components/Select';
import { DepartmentService } from '../../../../../../../services/v1/applicant_tracking/DepartmentService';
import { SinglePaginatedSelect } from '../../../../../../../components/Select/SinglePaginatedSelect';
import { InfoTooltip } from '../../../../../../../components/InfoTooltip';

interface PropTypes {
  register: UseFormRegister<PushToHrisFormValues>;
  errors: FieldErrors;
  control: Control<PushToHrisFormValues>;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

function DepartmentSelect(props: {
  selected: SelectOption;
  disabled: boolean;
  onChange: (opt: SelectOption) => void;
}) {
  const loadOptions = async (
    search: string,
    _loadedOptions: unknown,
    { page }: AdditionalType,
  ) => {
    const result = await DepartmentService.list({
      page: page,
      name: search,
    });

    return {
      options: result.departments.map(
        (department) =>
          ({
            label: department.name,
            value: department.id.toString(),
          } as SelectOption),
      ),
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <SinglePaginatedSelect
      additional={defaultAdditional}
      isClearable={true}
      value={props.selected}
      loadOptions={loadOptions}
      onChange={props.onChange}
      debounceTimeout={300}
      placeholder='Select Department'
      closeMenuOnSelect={true}
    />
  );
}

export function JobAndDepartmentRow(props: PropTypes) {
  return (
    <Row className='mb-3 align-items-end'>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('jobName', {
            required: true,
          })}
          fieldName={'Job Title'}
          placeholder={'Insert job name'}
          isRequired={true}
          classNames={classNames(props.errors.jobName && 'is-invalid', 'fs-5')}
        />
      </Col>
      <Col sm={6}>
        <div className='d-flex gap-2'>
          <FormFieldHeader fieldName={'Department'} isRequired={false} />
          <InfoTooltip>
            If no exact match is found in the HRIS, the closest available option
            will be selected.
          </InfoTooltip>
        </div>
        <Controller
          name='department'
          control={props.control}
          render={({ field }) => (
            <DepartmentSelect
              selected={field.value}
              onChange={field.onChange}
              disabled={false}
            />
          )}
        />
      </Col>
    </Row>
  );
}
