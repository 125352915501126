import React from 'react';
import { FormFieldHeader } from '../../../components/FormFieldHeader';
import { RichTextEditor } from '../../../components/RichTextEditor';
import { FormState } from './FormState';
import { AreaRichTextComposer } from '../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { UpdateStatePlugin } from '../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { PasteVariablePlugin } from '../../../components/RichTextEditor/plugins/PasteVariablePlugin';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { TEMPLATE_VARIABLES } from './EmailVariables';

interface PropTypes {
  control: Control<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
}

export function EmailBody(props: PropTypes) {
  const jsonBody = props.getValues('emailBodyJson');

  return (
    <div className='col-8'>
      <FormFieldHeader fieldName='Body' isRequired={true} />
      <RichTextEditor<FormState>
        formHookContext={{
          controllerName: 'emailBodyJson',
          formControl: props.control,
          required: true,
        }}
        render={(field) => (
          <AreaRichTextComposer
            defaultEditorState={field.value as string}
            placeholder='Insert email text'
            onChangeHtml={(value) => props.setValue('emailBodyHtml', value)}
            onChangeJson={(value: string) => field.onChange(value)}
            size='md'
            externalPlugins={[
              <UpdateStatePlugin key={1} editorState={jsonBody} />,
              <PasteVariablePlugin
                key={2}
                variables={Object.values(TEMPLATE_VARIABLES)}
              />,
            ]}
          />
        )}
      />
      <small className='mt-2 d-flex text-dark-200'>
        The signing link is always included in a button at the end of each
        email.
      </small>
    </div>
  );
}
