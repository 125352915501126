import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import * as CronofyElements from 'cronofy-elements';
import { CronofyOptions } from '../CronofyOptions';
import { EventSlot } from './EventSlot';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { Interview } from '../../../entities/applicant_tracking/Interview';
import { InterviewerParticipant } from '../../../entities/applicant_tracking/InterviewerParticipant';
import { buildAvailabilityQuery } from './utils/buildAvailabilityQuery';
import { waitForElement } from '../../../utils/waitForElement';
import { NoSlotsEmptyState } from './NoSlotsEmptyState';
import { InfoTooltip } from '../../InfoTooltip';

const TYPE_DATE_SELECTED = 'date_selected';
const TYPE_NO_SLOTS_FOUND = 'no_slots_found';
const TYPE_SLOT_SELECTED = 'slot_selected';

interface PropTypes {
  applicationInterviews: InterviewListItem[];
  interview?: Interview;
  options: CronofyOptions;
  participants: InterviewerParticipant[];
  startDate: Date;
  durationMinutes: number;
  timezone: string;
  searchDays?: number;
  startHours: number;
  onConfirm: (event: EventSlot) => void;
  setSlotsAvailable: (b: boolean) => void;
  candidateView: boolean;
}

function buildOptions(props: PropTypes) {
  const onConfirm = (eventSlot: EventSlot) => {
    if (eventSlot.type === TYPE_DATE_SELECTED) {
      return;
    }

    if (eventSlot.type === TYPE_NO_SLOTS_FOUND) {
      props.setSlotsAvailable(false);

      const cronofyRightColumn = document.querySelector(
        'div.cronofy__column-right',
      );

      waitForElement('div.cronofy__no-slots', 1500, cronofyRightColumn)
        .then((element) => {
          if (element) {
            element.classList.add('h-100');

            const root = createRoot(element);
            root.render(<NoSlotsEmptyState />);
          }
        })
        .catch((e) =>
          console.warn('Could not wait for element cronofy__no-slots', e),
        );

      return;
    }

    if (eventSlot.type === TYPE_SLOT_SELECTED) {
      const button = document.querySelector(
        'button.cronofy__confirm-button',
      ) as HTMLButtonElement;

      if (button) button.disabled = true;

      props.onConfirm(eventSlot);
    }
  };

  const availabilityQuery = buildAvailabilityQuery(
    props.options,
    props.participants,
    props.startDate,
    props.startHours,
    props.durationMinutes,
    props.applicationInterviews,
    props.timezone,
    props.searchDays,
    props.interview,
  );

  return {
    element_token: props.options.elementToken,
    target_id: 'cronofy-date-time-picker',
    availability_query: availabilityQuery,
    tzid: props.timezone,
    styles: {
      prefix: 'cronofy',
      colors: {
        buttonConfirm: '#08243E',
        buttonText: '#08243E',
        buttonActive: '#08243E',
        buttonActiveText: '#FFFFFF',
        buttonHover: '#08243E',
        buttonTextHover: '#FFFFFF',
      },
    },
    callback: (event) => onConfirm(event.notification),
    data_center: props.options.dataCenter,
  };
}

function UnavailableSlotsTooltip() {
  return (
    <InfoTooltip
      tooltipStyle={{
        maxWidth: 'none',
        width: '400px',
      }}
    >
      <div className={'py-2 px-2'}>
        <p>Some time slots may not be shown for the following reasons:</p>
        <ul className={'mb-0'}>
          <li>
            The candidate already has an interview scheduled for the same time
            slot;
          </li>
          <li>
            A user already has a scheduled interview for the same time slot;
          </li>
          <li>
            Users’ availability is not defined for certain time slots (check
            user availability settings);
          </li>
          <li>
            If no availability is set, only slots between 9 AM and 6 PM are
            shown.
          </li>
        </ul>
      </div>
    </InfoTooltip>
  );
}

function addTooltipToDay(candidateView: boolean) {
  if (candidateView) return;

  waitForElement('div.cronofy__time-slots-list--header', 3000)
    .then((element) => {
      if (element) {
        const tooltipDivId = 'day_tooltip';
        const tooltipDiv = document.querySelector(`#${tooltipDivId}`);

        if (!tooltipDiv) {
          element.classList.add('d-flex', 'justify-content-center');

          const newDiv = document.createElement('div');
          newDiv.id = tooltipDivId;
          newDiv.classList.add('ms-2');
          const root = createRoot(newDiv);
          root.render(<UnavailableSlotsTooltip />);

          element.appendChild(newDiv);
        }
      }
    })
    .catch((e) =>
      console.warn(
        'Could not wait for element cronofy__time-slots-list--header',
        e,
      ),
    );
}

export function CronofyDatePicker(props: PropTypes) {
  const [element, setElement] = useState(null);

  const options = buildOptions({ ...props });

  useEffect(() => {
    if (!element) {
      setElement(CronofyElements.DateTimePicker(options));
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.update(options);
    }

    addTooltipToDay(props.candidateView);
  }, [options]);

  return <div id='cronofy-date-time-picker'></div>;
}
