import { DragEndEvent } from '@dnd-kit/core';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';
import { PipelineJobStage } from '../../../../../entities/v1/applicant_tracking/PipelineJobStage';
import { MoveCandidateProps } from '../../../components/MoveCandidateStageModal';

export function handleDragEndEvent(
  changeStageDragEndEvent: DragEndEvent,
  currentPipelineJobStage: PipelineJobStage,
  jobStageApplications: PipelineApplication[],
  setApplications: (applications) => void,
  setTotalApplications: (total) => void,
): void {
  if (
    changeStageDragEndEvent?.active?.id &&
    changeStageDragEndEvent?.over?.id
  ) {
    const newJobStageName = changeStageDragEndEvent.over.id;

    // when the application was moved to the current stage
    if (newJobStageName === currentPipelineJobStage.jobStage.name) {
      const newApplication: PipelineApplication =
        changeStageDragEndEvent.active.data.current.application;

      if (!newApplication) return;

      newApplication.daysInCurrentJobStage = 0;
      newApplication.jobStage = {
        id: currentPipelineJobStage.jobStageId,
        name: currentPipelineJobStage.jobStage.name,
      };

      addApplication(newApplication, setApplications, setTotalApplications);

      return;
    }

    const application = jobStageApplications.find(
      (a) => a.id === changeStageDragEndEvent.active.id,
    );

    // when the application was moved from the current stage
    if (application) {
      removeApplication(application.id, setApplications, setTotalApplications);
    }
  }
}

export function handleMoveToStage(
  application: PipelineApplication,
  currentPipelineJobStage: PipelineJobStage,
  setApplications: (applications) => void,
  setTotalApplications: (total) => void,
  action?: MoveCandidateProps,
): void {
  if (!action) return;

  const fromJobStageId = application.jobStage.id;
  if (fromJobStageId === currentPipelineJobStage.jobStageId) {
    removeApplication(application.id, setApplications, setTotalApplications);
    return;
  }

  const toJobStageName = action.jobStageName;
  if (toJobStageName === currentPipelineJobStage.jobStage.name) {
    addApplication(application, setApplications, setTotalApplications);
  }
}

function addApplication(
  newApplication: PipelineApplication,
  setApplications: (applications) => void,
  setTotalApplications: (total) => void,
) {
  setApplications((applications) => {
    return [...applications, newApplication];
  });
  setTotalApplications((total) => total + 1);
}

function removeApplication(
  applicationId: number,
  setApplications: (applications) => void,
  setTotalApplications: (total) => void,
) {
  setApplications((applications) => {
    return applications.filter((a) => a.id !== applicationId);
  });
  setTotalApplications((total) => total - 1);
}
