import React, { useState } from 'react';
import { Application } from '../../../entities/Application';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { Chip, ChipColor } from '../../../components/Chip';
import { CandidatePanel } from './CandidatePanel';
import { JobApplicationsPanel } from './JobApplicationsPanel';
import { LatestActivityPanel } from './LatestActivityPanel';
import { PendingActionsPanel } from './PendingActionsPanel';
import { User } from '../../../entities/User';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { ApplicationQuestionsPanel } from './ApplicationQuestionsPanel';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import classNames from 'classnames';
import { Card } from '../../../components/Card';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';
import { ResumePanel } from './ResumePanel';
import { TagsPanel } from './TagsPanel';
import { SignableOfferPanel } from './SignableOfferPanel';
interface PropTypes {
  application: Application;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobHiringMemberRole?: JobHiringMemberRole;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
  applicationStylesPath: string;
  setIsLoading: (v: boolean) => void;
  resumePanelFFEnabled: boolean;
  atsTagsPanelEnabled: boolean;
}

function StageChip(props: {
  label?: string;
  chipColor: ChipColor;
  value: JSX.Element;
}) {
  return (
    <div className='d-flex flex-wrap gap-2 align-items-center'>
      {props.label && <span className='fs-5'>{props.label}</span>}
      <Chip className={classNames('py-2')} color={props.chipColor}>
        <h5 className='mb-0'>{props.value}</h5>
      </Chip>
    </div>
  );
}

function StagePanel(props: { application: Application }) {
  const daysInProcess = moment().diff(
    moment(props.application.appliedAt),
    'days',
  );

  return (
    <Card className='gap-3'>
      <h3 className='mb-0 me-auto'>Process Overview</h3>
      <StageChip
        chipColor={ChipColor.DangerLight}
        value={<>{props.application.job_stage_name}</>}
      />
      <div className='d-flex justify-content-between'>
        <StageChip
          label='Time on stage'
          chipColor={ChipColor.BlueLight}
          value={<>{props.application.daysInJobStage} days</>}
        />
        <StageChip
          label='Time in process'
          chipColor={ChipColor.BlueLight}
          value={<>{daysInProcess} days</>}
        />
      </div>
    </Card>
  );
}

export function ProfileTab(props: PropTypes) {
  const [alert, setAlert] = useState<AlertObject>(null);

  return (
    <>
      {alert && (
        <Alert
          clearable
          type={alert.type ?? AlertType.Success}
          autoClearTimeout={4000}
          onClose={() => {
            setAlert(null);
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <CandidatePageSubHeader text='Profile' />
          <div className='d-flex gap-6'>
            <div className='ms-3 fs-5 fw-normal text-muted'>
              Applied at{' '}
              {moment(props.application.appliedAt).format('MMM DD, YYYY')}
            </div>
            <div className='fs-5 text-muted'>
              Updated at{' '}
              {moment(props.application.lastInteractionAt).format(
                'MMMM DD, YYYY, hh:mm A',
              )}
            </div>
          </div>
        </div>
        <Row className='flex-nowrap mx-0'>
          <Col xs='3'>
            <Row className='mb-4'>
              <CandidatePanel
                application={props.application}
                reloadApplication={props.reloadApplication}
                setIsLoading={props.setIsLoading}
              />
            </Row>
            {props.atsTagsPanelEnabled && (
              <Row>
                <TagsPanel
                  application={props.application}
                  setAlert={setAlert}
                />
              </Row>
            )}
          </Col>
          <Col xs='6' className='px-5h'>
            <ApplicationQuestionsPanel application={props.application} />
            <Row className='mb-4'>
              <LatestActivityPanel
                application={props.application}
                applicationStylesPath={props.applicationStylesPath}
                currentUser={props.currentUser}
                emailAccountIntegration={props.emailAccountIntegration}
                reloadApplication={props.reloadApplication}
                setAlert={props.setAlert}
              />
            </Row>
            {props.resumePanelFFEnabled && (
              <Row>
                <ResumePanel application={props.application} />
              </Row>
            )}
          </Col>
          <Col className='d-flex flex-column gx-0 gap-4' xs='3'>
            <StagePanel application={props.application} />
            <SignableOfferPanel application={props.application} />
            <PendingActionsPanel
              application={props.application}
              currentUser={props.currentUser}
              jobHiringMemberRole={props.jobHiringMemberRole}
            />
            {props.application.candidate.applications?.length > 1 && (
              <JobApplicationsPanel application={props.application} />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
