import React from 'react';
import { CloseableModal } from '../../../components/CloseableModal';
import { VARIABLES, Variable } from '../SignableOffers/variables';
import { CopyButton } from '../../../components/CopyButton';
import { Chip, ChipColor } from '../../../components/Chip';
import { Icon } from '../../../components/Icon';

interface PropTypes {
  open: boolean;
  onOpen: (v: boolean) => void;
}

export function VariablesModal(props: PropTypes) {
  return (
    <CloseableModal
      isOpen={props.open}
      size='xl'
      headerTitle='Document Variables'
      bodyChildren={<Body />}
      onClose={() => props.onOpen(false)}
    />
  );
}

function Body() {
  return (
    <div>
      <p className='fs-5'>
        Copy variables by clicking on the variable name and pasting them into
        your documents. Candidate e-signature variable is mandatory in all
        documents. To add custom variables, insert terms in braces e.g., {'{{{'}
        office{'}}}'}, using the Latin alphabet without spaces.
      </p>
      <table className='table align-middle'>
        <thead className='text-primary fw-semibold'>
          <tr>
            <th scope='col'>Variable</th>
            <th scope='col'>Type</th>
            <th scope='col'>Label</th>
          </tr>
        </thead>
        <tbody>
          {VARIABLES.map((v) => (
            <VariableRow key={v.name} var={v} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function VariableRow(props: { var: Variable }) {
  return (
    <tr>
      <th scope='row'>
        <CopyButton
          text={props.var.variable}
          size='md'
          position='start'
          textToBeCopied={`{{{${props.var.name}}}}`}
        />
      </th>
      <td>
        <Chip color={ChipColor.Gray}>
          <div className='d-flex align-items-baseline gap-2'>
            <TypeIcon type={props.var.type} />
            {props.var.type}
          </div>
        </Chip>
      </td>
      <td>
        <span className='fs-5 text-primary'>{props.var.label}</span>
      </td>
    </tr>
  );
}

function TypeIcon(props: { type: string }) {
  switch (props.type) {
    case 'Numeric':
      return <Icon name='bi-hash' />;
    case 'Signature':
      return <Icon name='bi-pen' />;
    case 'Date':
      return <Icon name='bi-calendar3' />;
    case 'Text':
      return <Icon name='bi-textarea-t' />;
    case 'Salary':
      return <Icon name='bi-wallet2' />;
  }
}
