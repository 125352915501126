import React from 'react';
import { SignableOfferFeed } from '../../../../../entities/ApplicationFeed';
import { User } from '../../../../../entities/User';
import { Application } from '../../../../../entities/Application';
import { AlertObject } from '../../../../../components/Alert';
import { GenericEntry } from '../..';
import { ViewOfferButton } from '../ViewOfferButton';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLastItemInPage: boolean;
  entry: SignableOfferFeed;
  setAlert: (data: AlertObject) => void;
  reloadFeed: () => void;
}

export function SignableOfferExpired(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-clock-history'
      iconClassName='bg-yellow-light'
      title={
        <span>
          <strong>{props.application.job.name}</strong> Offer has expired and is
          no longer valid.
        </span>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <ViewOfferButton entry={props.entry} />
    </GenericEntry>
  );
}
