import { AlertMessage } from '../../../../../components/AlertMessage';
import { CloseableModal } from '../../../../../components/CloseableModal';
import React, { useEffect, useState } from 'react';
import { AsyncSelect } from '../../../../../components/Select/async';
import { SelectOption } from '../../../../../components/Select';
import { InterviewTemplateService } from '../../../../../services/v1/applicant_tracking/InterviewTemplateService';
import { Input } from 'reactstrap';
import { CopyButton } from '../../../../../components/CopyButton';
import { JobDetails } from '../../../../../entities/JobDetails';
import { ApplyAndScheduleLinkService } from '../../../../../services/v1/applicant_tracking/ApplyAndScheduleLinkService';
import { ApplyAndScheduleLink } from '../../../../../entities/v1/applicant_tracking/ApplyAndScheduleLink';
import { PipelineJobStage } from '../../../../../entities/v1/applicant_tracking/PipelineJobStage';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  job: JobDetails;
  pipelineJobStage: PipelineJobStage;
}

export function ApplyAndScheduleModal(props: PropTypes) {
  return (
    <CloseableModal
      isOpen={props.open}
      size='md'
      headerTitle='Copy Self-Scheduling Link'
      bodyChildren={<Body {...props} />}
      onClose={props.onClose}
    />
  );
}

function Body(props: PropTypes) {
  const [options, setOptions] = useState<SelectOption[]>(null);
  const [link, setLink] = useState<Link>(null);

  const selected = link != null &&
    link != 'LOADING' && {
      value: link.interviewTemplate.id.toString(),
      label: link.interviewTemplate.name,
    };
  const text = linkText(link);

  async function loadOptions(_input: string): Promise<SelectOption[]> {
    const list = await InterviewTemplateService.list();
    const vals = list.interviewTemplates.map((templ) => ({
      value: templ.id.toString(),
      label: templ.name,
    }));
    setOptions(vals);
    return vals;
  }

  async function handleChange(option: SelectOption) {
    setLink('LOADING');
    const link = await ApplyAndScheduleLinkService.create(
      props.job.id,
      props.pipelineJobStage.jobStageId,
      parseInt(option.value, 10),
      ['interview_template'],
    );
    setLink(link);
  }

  useEffect(() => {
    if (options == null) return;
    const id = props.pipelineJobStage.interviewTemplateId?.toString();
    if (id == null) return;

    handleChange(options.find((option) => option.value === id));
  }, [options]);

  return (
    <div className='d-flex flex-column gap-3'>
      <p className='fs-5 mb-0'>
        Create a self-scheduling link for the{' '}
        <strong>{props.pipelineJobStage.jobStage.name}</strong>.
      </p>
      <div>
        <label className='fw-semibold fs-5'>Scheduling Template</label>
        <AsyncSelect
          loadOptions={loadOptions}
          selected={selected}
          onChange={handleChange}
          placeholder='Select Scheduling Template'
          disabled={options?.length === 0}
        />
      </div>
      <div className='d-flex gap-3'>
        <Input disabled={true} value={text} className='fs-5' />
        <CopyButton
          textToBeCopied={text}
          text='Copy'
          size='md'
          position='start'
          color='primary'
          disabled={link == null || link == 'LOADING'}
        />
      </div>
      <AlertMessage
        icon={{ name: 'bi-info-circle' }}
        text={<AlertText options={options} />}
        hasOverlay={false}
        className='bg-light-warning rounded align-self-start fs-5'
      />
    </div>
  );
}

type Link = ApplyAndScheduleLink | 'LOADING' | null;

function linkText(link: Link) {
  if (link == null)
    return 'A link will be generated once a template is selected.';
  if (link == 'LOADING') return 'Generating link...';
  return link.url;
}

function AlertText(props: { options: SelectOption[] }) {
  if (props.options?.length === 0) {
    return (
      <>
        Add a scheduling template in{' '}
        <a
          className='text-info'
          href='/organization_settings/interview_templates'
        >
          Settings &gt; Scheduling Templates
        </a>{' '}
        to generate your self-scheduling link.
      </>
    );
  }

  return (
    <>
      After self-scheduling, the candidate will automatically be moved to the
      selected stage.
    </>
  );
}
