import React, { useEffect, useState } from 'react';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';
import { appendQueryParam, getRedirectUriQueryParam } from '../../../utils/url';
import { IconSpan } from '../../../components/IconSpan';
import { ApplicationsContainer } from './ApplicationsContainer';
import {
  getApplicationIdsToBulkReview,
  getBulkReviewData,
} from '../../../utils/applicant_tracking/bulkApplicationReview';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { JobService } from '../../../services/v1/applicant_tracking/JobService';
import { redirectWithAlert } from '../../../services/FlashService';
import { ApplicationIdList } from '../../../entities/v1/ApplicationIdList';

export type BULK_REVIEW_NAVIGATION_TYPE = 'rejected' | 'moved' | 'skipped';

function prepareRedirectUri(
  uri: string,
  rejectedCount: number,
  movedToNextStageCount: number,
  skippedCount: number,
): string {
  uri = appendQueryParam(uri, 'rejected', `${rejectedCount}`);
  uri = appendQueryParam(uri, 'moved', `${movedToNextStageCount}`);
  uri = appendQueryParam(uri, 'skipped', `${skippedCount}`);
  return uri;
}

export default function BulkApplicationReview() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [alert, setAlert] = useState<AlertObject>(null);
  const [preSelectedRejectReasonId, setPreSelectedRejectReasonId] =
    useState<number>(null);
  const [rejectedCount, setRejectedCount] = useState<number>(0);
  const [skippedCount, setSkippedCount] = useState<number>(0);
  const [movedToNextStageCount, setMovedToNextStageCount] = useState<number>(0);
  const [exitedApplicationId, setExitedApplicationId] = useState<number>(null);
  const [applicationIds, setApplicationIds] = useState<Set<number>>(
    new Set<number>(),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalApplications, setTotalApplications] = useState<number>(0);

  const loadApplications = (offset: number) => {
    const urlApplicationIds = getApplicationIdsToBulkReview();
    if (urlApplicationIds.length > 0) {
      setApplicationIds(new Set<number>(urlApplicationIds));
      setTotalApplications(urlApplicationIds.length);
      setIsLoading(false);
      return;
    }

    const bulkReviewParams = getBulkReviewData();
    const sortingColumn = bulkReviewParams?.['sorting_column'];
    const jobId = bulkReviewParams?.['job_id'];
    const jobStageId = bulkReviewParams?.['job_stage_id'];

    if (!jobId) {
      redirectWithAlert(
        getRedirectUriQueryParam(),
        'It was not possible to review the candidates.',
        AlertType.Danger,
      );
    }

    JobService.listApplicationIds({
      id: jobId,
      jobStageId: jobStageId,
      offset: offset,
      limit: 10,
      statuses: ['active', 'hired'],
      sortColumn: sortingColumn?.column_name,
      sortDirection: sortingColumn?.direction,
    })
      .then((applicationIdList: ApplicationIdList) => {
        const retrievedApplicationIds = applicationIdList.applicationIds;
        if (currentIndex == 0) {
          setApplicationIds(new Set<number>(retrievedApplicationIds));
          setTotalApplications(applicationIdList.entriesCount);
        } else {
          retrievedApplicationIds.forEach((id) => applicationIds.add(id));
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    window.location.href = prepareRedirectUri(
      getRedirectUriQueryParam(),
      rejectedCount,
      movedToNextStageCount,
      totalApplications - rejectedCount - movedToNextStageCount,
    );
  };

  const handleNext = (navType: BULK_REVIEW_NAVIGATION_TYPE) => {
    switch (navType) {
      case 'moved':
        setMovedToNextStageCount((prevCount) => prevCount + 1);
        break;
      case 'rejected':
        setRejectedCount((prevCount) => prevCount + 1);
        break;
      case 'skipped':
        setSkippedCount((prevCount) => prevCount + 1);
        break;
    }
    setExitedApplicationId(applicationIds[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);

    if (currentIndex > 0 && currentIndex % 5 == 0) {
      // As the candidates are being rejected and moved to another stage, the offset should be equals to the skipped
      // applications. The rejected and moved applications are not considered when we perform another load.
      loadApplications(skippedCount);
    }
  };

  useEffect(() => {
    loadApplications(0);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (currentIndex + 1 > applicationIds.size) {
      handleClose();
    }
  }, [currentIndex]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {alert && (
        <Alert
          clearable
          type={alert.type ?? AlertType.Success}
          autoClearTimeout={4000}
          onClose={() => {
            setAlert(null);
          }}
        >
          <IconSpan
            icon={{ name: 'bi-info-circle' }}
            spanText={alert.message}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <ApplicationsContainer
        applicationIds={[...applicationIds]}
        currentIndex={currentIndex}
        preSelectedRejectReasonId={preSelectedRejectReasonId}
        exitedApplicationId={exitedApplicationId}
        onClose={handleClose}
        setAlert={setAlert}
        setPreSelectedRejectReasonId={setPreSelectedRejectReasonId}
        onNext={handleNext}
        totalApplications={totalApplications}
      />
    </>
  );
}
