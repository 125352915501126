import React, { useCallback, useState } from 'react';
import { PublishJobConfirmationModal } from '../../../../applicant_tracking/Jobs/PublishJob/PublishJobConfirmationModal';
import { JobDetails } from '../../../../../entities/JobDetails';
import { PublishIconButton } from './PublishIconButton';
import { UnpublishIconButton } from './UnpublishIconButton';
import { useJobPublishPermissions } from '../../../../../queries/v1/applicant_tracking/useJobPublishPermissions';
import { useJobUnpublishPermissions } from '../../../../../queries/v1/applicant_tracking/useJobUnpublishPermissions';

interface PropTypes {
  jobDetails: JobDetails;
  updateEnabled: boolean;
}

export function PublishButton(props: PropTypes) {
  const [isPublishJobModalOpen, setIsPublishJobModalOpen] = useState(false);
  const onClick = useCallback(async () => {
    setIsPublishJobModalOpen(true);
  }, []);
  const { isLoading: isLoadingPublishPermissions, data: publishPermissions } =
    useJobPublishPermissions(props.jobDetails.id, []);
  const {
    isLoading: isLoadingUnpublishPermissions,
    data: unpublishPermissions,
  } = useJobUnpublishPermissions(props.jobDetails.id, []);
  const actionName =
    props.jobDetails.status == 'published' ? 'Unpublish' : 'Publish';

  return (
    <>
      <PublishJobConfirmationModal
        jobId={props.jobDetails.id}
        jobName={props.jobDetails.name}
        publishAction={actionName}
        isModalOpen={isPublishJobModalOpen}
        setIsModalOpen={setIsPublishJobModalOpen}
        redirectUri={window.location.href}
      />
      {props.jobDetails.status === 'published' ? (
        <UnpublishIconButton
          jobDetails={props.jobDetails}
          actionName={actionName}
          jobUnpublishPermissions={unpublishPermissions}
          isLoadingPermissions={isLoadingUnpublishPermissions}
          onUnpublish={onClick}
        />
      ) : (
        <PublishIconButton
          jobDetails={props.jobDetails}
          actionName={actionName}
          jobPublishPermissions={publishPermissions}
          isLoadingPermissions={isLoadingPublishPermissions}
          onPublish={onClick}
        />
      )}
    </>
  );
}
