import React from 'react';
import { User } from '../../../../entities/User';
import InterviewTemplatePage from '../InterviewTemplatePage';
import { ActionType } from '../../../../enums/ActionType';

interface PropTypes {
  meetingOrganizer: User;
}

export default function NewInterviewTemplatePage(props: PropTypes) {
  return (
    <InterviewTemplatePage
      meetingOrganizer={props.meetingOrganizer}
      actionType={ActionType.New}
    />
  );
}
