import classNames from 'classnames';
import { InputFormField } from '../../../components/InputFormField';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { NARROW_CONTAINER_WIDTH } from './consts';
import { FormState } from './FormState';

interface PropTypes {
  errors: FieldErrors;
  disabled?: boolean;
  register: UseFormRegister<FormState>;
}

export function NameInput(props: PropTypes) {
  return (
    <div style={{ maxWidth: NARROW_CONTAINER_WIDTH }}>
      <InputFormField
        placeholder='Insert offer template name'
        isRequired={true}
        disabled={props.disabled}
        fieldName='Name'
        classNames={classNames(
          props.errors.name && 'is-invalid',
          'fs-5 fw-normal',
        )}
        error={props.errors?.name?.message as string}
        registerReturn={props.register('name', { required: true })}
        type='text'
      />
    </div>
  );
}
