// Height size of chromium launched by puppeteer
// 3000px for Linux and 2900 for Mac OS
const PDF_A4_HEIGHT_IN_PX = 3000;
const PDF_PAGE_DEFAULT_MARGIN_IN_PX = 40;

export const REPORTING_SECTION_CLASS = 'reporting-section';
export const REPORTING_PAGE_BREAK_CLASS = 'reporting-page-break';

export const handlePDFPageBreak = () => {
  if (document.documentElement.scrollHeight > PDF_A4_HEIGHT_IN_PX) {
    const reportingSections = document.querySelectorAll(
      `.${REPORTING_SECTION_CLASS}`,
    );
    const reportingPageBreaks = document.querySelectorAll(
      `.${REPORTING_PAGE_BREAK_CLASS}`,
    );

    let currentPageHeightInPx = 0;

    reportingSections.forEach((sectionDiv, index) => {
      const sectionHeightInPx = sectionDiv.scrollHeight;
      currentPageHeightInPx += sectionHeightInPx;

      if (currentPageHeightInPx > PDF_A4_HEIGHT_IN_PX) {
        const pageBreakDiv = reportingPageBreaks.item(index - 1) as HTMLElement;

        if (pageBreakDiv !== null) {
          //forces page break
          pageBreakDiv.style.marginTop = `${PDF_A4_HEIGHT_IN_PX}px`;
          //applies pdf margin
          pageBreakDiv.style.height = `${PDF_PAGE_DEFAULT_MARGIN_IN_PX}px`;

          currentPageHeightInPx = 0;
        }
      }
    });
  }
};

export function isPDFUrl(url: string): boolean {
  try {
    return new URL(url).pathname.endsWith('.pdf');
  } catch (e: any) {
    console.error(`An unexpected URL ${url} was passed to this function`);
    return false;
  }
}
