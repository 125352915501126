const REDIRECT_URI_QUERY_PARAM = 'redirect_uri';

export interface QueryParam {
  key: string;
  value: string | string[];
}

export function appendQueryParam(
  url: string,
  queryParamName: string,
  queryParamValue: string,
) {
  const uri = new URL(url, window.location.origin);
  uri.searchParams.set(queryParamName, queryParamValue);

  return url.split('?')[0] + '?' + uri.searchParams.toString();
}

export function appendQueryParamToCurrentTab(
  queryParamName: string,
  queryParamValue: string,
) {
  if (!queryParamValue) return;

  const currentURL = window.location.href;
  const uri = new URL(currentURL, window.location.origin);

  uri.searchParams.set(queryParamName, queryParamValue);

  const updatedURL =
    currentURL.split('?')[0] + '?' + uri.searchParams.toString();

  window.history.replaceState(null, '', updatedURL);
}

export function removeQueryParamFromCurrentTab(queryParamName: string) {
  const currentURL = window.location.href;
  const uri = new URL(currentURL, window.location.origin);

  uri.searchParams.delete(queryParamName);

  const updatedURL =
    currentURL.split('?')[0] + '?' + uri.searchParams.toString();

  window.history.replaceState(null, '', updatedURL);
}

export function appendRedirectUri(
  url: string,
  redirectUri = window.location.href,
  preservePreviousRedirectUri = false,
) {
  if (!redirectUri) return url;

  const parsedRedirectUri = preservePreviousRedirectUri
    ? redirectUri
    : removePreviousRedirectUri(redirectUri);

  return appendQueryParam(url, REDIRECT_URI_QUERY_PARAM, parsedRedirectUri);
}

function removePreviousRedirectUri(url: string): string {
  const uri = new URL(url, window.location.origin);
  uri.searchParams.delete(REDIRECT_URI_QUERY_PARAM);

  return uri.toString();
}

export function appendCurrentRedirectUri(url: string) {
  return appendRedirectUri(url, getRedirectUriQueryParam());
}

export function getRedirectUriQueryParam() {
  const redirectUri = getQueryParam(REDIRECT_URI_QUERY_PARAM);

  if (!redirectUri) return null;

  return decodeURI(redirectUri);
}

export function getArrayQueryParam(name: string): string[] {
  const query = new URLSearchParams(window.location.search);
  return query.getAll(name);
}

export function getQueryParam(name: string) {
  const query = new URLSearchParams(window.location.search);
  return query.get(name);
}

export function getUrlOriginPath() {
  return removeTrailingSlashes(
    `${window.location.origin}${window.location.pathname}`,
  );
}

export function removeTrailingSlashes(pathname: string) {
  return pathname.replace(/\/*$/, '');
}

export function getPathnameLastSegment(pathname: string) {
  return removeTrailingSlashes(pathname).split('/').pop();
}

// baseOriginUrl should include protocol and domain. e.g. https://app.screenloop.com
// query params are encoded inside this function
export function buildUrl(baseOriginUrl: string, queryParams: QueryParam[]) {
  const baseUrl = new URL(removeTrailingSlashes(baseOriginUrl));

  queryParams.forEach((qp: QueryParam) => {
    if (Array.isArray(qp.value)) {
      qp.value.forEach((item) => baseUrl.searchParams.append(qp.key, item));
    } else {
      baseUrl.searchParams.append(qp.key, qp.value);
    }
  });

  return baseUrl.href;
}

export function replaceFinalSegment(segment: string) {
  const currentURL = window.location.pathname;
  const segments = removeTrailingSlashes(currentURL).split('/');
  segments[segments.length - 1] = segment;

  const newPath = segments.join('/');
  const redirect_uri = getRedirectUriQueryParam();

  if (redirect_uri) return appendRedirectUri(newPath, redirect_uri);

  return newPath;
}

export function updateQueryParam(url: string, param: string, value: string) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;

  params.set(param, value);

  return urlObj.toString();
}
