import React, { useState } from 'react';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Layout } from '../Layout';
import { StartingPointSelector } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { useForm } from 'react-hook-form';
import { NameInput } from '../NameInput';
import { DaysToExpirationInput } from '../DaysToExpirationInput';
import { SignableOfferTemplateList } from '../../../../entities/v1/applicant_tracking/SignableOfferTemplateList';
import { SignableOfferTemplateService } from '../../../../services/v1/applicant_tracking/SignableOfferTemplateService';
import { DocumentsSection } from '../DocumentsSection';
import { EmailMessageSection } from '../EmailMessageSection';
import { FormState, formToParams, templateToForm } from '../FormState';
import { WIDE_CONTAINER_WIDTH } from '../consts';
import { redirectWithAlert } from '../../../../services/FlashService';
import { AlertType } from '../../../../components/Alert';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

const BACK_URL = '/organization_settings/signable_offer_templates';

export type StartingPoint =
  | { value: string; label: string }
  | 'FROM_SCRATCH'
  | undefined;

async function filterTemplates(
  templateList: Promise<SignableOfferTemplateList>,
  q: string,
) {
  return templateList.then((list) =>
    list.signableOfferTemplates
      .filter((template) =>
        template.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.name,
      })),
  );
}

export default function NewPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);

  const {
    control,
    formState,
    setValue,
    getValues,
    handleSubmit,
    register,
    watch,
    reset,
    resetField,
    setError,
  } = useForm<FormState>({
    mode: 'onChange',
  });

  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;
    if (startingPoint === 'FROM_SCRATCH') {
      setValue('basedOnId', null);
      return;
    }

    setIsLoading(true);
    const basedOnId = parseInt(startingPoint.value, 10);
    const template = await SignableOfferTemplateService.show(basedOnId, [
      'signatories',
      'signatories.user',
      'signable_documents',
      'email_template',
    ]);
    reset({ basedOnId, ...templateToForm(template) });
    setIsLoading(false);
  }

  async function onSubmit(data: FormState) {
    setIsLoading(true);
    const params = formToParams(data);
    try {
      await SignableOfferTemplateService.create(params);
      redirectWithAlert(BACK_URL, 'Offer Template created!', AlertType.Success);
    } catch (e) {
      setError(e.body.param, { message: e.body.message });
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    window.location.href = BACK_URL;
  }

  return (
    <div className='container'>
      {isLoading && <LoadingSpinner showBackdrop />}
      <PageHeader title='Add Offer Template' backUrl={BACK_URL} />
      <Layout
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        isValid={formState.isValid}
      >
        <div className='d-flex flex-column fs-5 gap-4 pb-4'>
          <NameInput errors={formState.errors} register={register} />
          <div style={{ maxWidth: WIDE_CONTAINER_WIDTH }}>
            <StartingPointSelector
              startingPoint={startingPoint}
              initialState={() => SignableOfferTemplateService.list()}
              filterOptions={filterTemplates}
              onStartingPointChange={handleStartingPointChange}
              placeholder='Select template'
            />
          </div>
          <DaysToExpirationInput
            errors={formState.errors}
            register={register}
          />
        </div>
        <DocumentsSection
          control={control}
          resetField={resetField}
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
        <EmailMessageSection
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      </Layout>
    </div>
  );
}
