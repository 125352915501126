import React, { useState } from 'react';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { BulkActionButton } from '../BulkActionButton';
import { ModalBody } from './ModalBody';
import {
  MoveCandidateProps,
  MoveCandidateStageModal,
} from '../../../../../../JobPage/components/MoveCandidateStageModal';
import { BULK_ACTION_ERROR_MESSAGE } from '..';
import { checkIfSelectedCandidatesHaveSameStatus } from '../utils';

interface PropTypes {
  candidates: ListCandidate[];
  selectedCandidates: ListCandidate[];
  selectionCountLabel: string;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

function successAlertMessage(
  recordsSubmitted: number,
  recordsProcessed: number,
  selectionCountLabel: string,
  jobStageName: string,
) {
  return recordsProcessed === recordsSubmitted ? (
    <>
      <span className='fw-bold'>{selectionCountLabel}</span> were successfully
      moved to the <span className='fw-bold'>{jobStageName}</span> stage!
    </>
  ) : (
    <>
      <span className='fw-bold'>{recordsProcessed}</span> out of{' '}
      <span className='fw-bold'>{selectionCountLabel}</span> were successfully
      moved to the <span className='fw-bold'>{jobStageName}</span> stage. The
      remainder candidates are draft/rejected and cannot be moved.
    </>
  );
}

function actionStatusAndTooltipText(
  selectedCandidates: ListCandidate[],
  multipleJobsSelected: boolean,
) {
  let toolTipText = 'Move to Stage';
  let actionDisabled: boolean = selectedCandidates.length === 0;

  const allRejected = selectedCandidates.every(
    (candidate) => candidate.application.status === 'rejected',
  );
  const sameStatusCandidatesSelected =
    checkIfSelectedCandidatesHaveSameStatus(selectedCandidates);

  if (allRejected) {
    toolTipText = 'Bulk move to stage is not available for rejected candidates';
    actionDisabled = true;
  } else if (multipleJobsSelected) {
    toolTipText =
      'Bulk move to stage is only available for candidates that share the same job';
    actionDisabled = true;
  } else if (!sameStatusCandidatesSelected) {
    toolTipText =
      'Bulk move to stage is only available for candidates that share the same status';
    actionDisabled = true;
  }

  return { actionDisabled, toolTipText };
}

export function MoveToStageButton(props: PropTypes) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const selectedCandidateApplicationUniqueJobs = props.selectedCandidates
    .map((c) => c.application.job)
    .filter(
      (job, index, self) => self.findIndex((j) => job.id === j.id) === index,
    );
  const selectedJob = selectedCandidateApplicationUniqueJobs[0];

  const { actionDisabled, toolTipText } = actionStatusAndTooltipText(
    props.selectedCandidates,
    selectedCandidateApplicationUniqueJobs.length > 1,
  );

  const onClosingModal = () => setIsModalOpen(false);

  const handleMoveStage = (moveProps: MoveCandidateProps) => {
    if (moveProps?.state === 'Success') {
      props.reloadPage();
      props.setAlert({
        message: successAlertMessage(
          props.selectedCandidates.length,
          moveProps.recordsProcessed,
          props.selectionCountLabel,
          moveProps.jobStageName,
        ),
        type: AlertType.Success,
      });
    } else if (moveProps?.state === 'Error') {
      props.setAlert({
        message: BULK_ACTION_ERROR_MESSAGE,
        type: AlertType.Danger,
      });
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <BulkActionButton
        iconName='bi-arrow-return-right'
        toolTipText={toolTipText}
        disabled={actionDisabled || props.selectedCandidates.length === 0}
        onClick={() => setIsModalOpen(true)}
        analyticsId='bulk-move-to-stage-button'
      />
      <MoveCandidateStageModal
        applicationId={null}
        currentStage={null}
        candidateName={null}
        jobId={null}
        isOpen={isModalOpen && !!selectedJob}
        onClose={onClosingModal}
        onMovingCandidate={handleMoveStage}
        customModalBody={
          <ModalBody
            selectedCandidates={props.selectedCandidates}
            job={selectedJob}
            onClose={onClosingModal}
            onMovingCandidate={handleMoveStage}
          />
        }
      />
    </>
  );
}
