import React from 'react';
import { IconSpan } from '../../../../components/IconSpan';
import { appendQueryParam, appendRedirectUri } from '../../../../utils/url';
import { DropdownAction } from '../../../../components/Dropdown/DropdownMenu/DropdownAction';

export const REFERENCE_JOB_ID_QUERY_PARAM = 'reference_job_id';
export const CREATE_JOB_URL = '/jobs/setup/job_details';

interface PropTypes {
  jobId: number;
}

export default function DuplicateJobButton(props: PropTypes) {
  return (
    <DropdownAction
      action={() =>
        (window.location.href = appendRedirectUri(
          appendQueryParam(
            CREATE_JOB_URL,
            REFERENCE_JOB_ID_QUERY_PARAM,
            props.jobId.toString(),
          ),
        ))
      }
      buttonChild={
        <IconSpan
          className='ms-2'
          spanText='Duplicate'
          icon={{ name: 'bi-files-alt' }}
        />
      }
    />
  );
}
