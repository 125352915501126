import React from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { PushToHrisFormValues } from '../..';
import { JobAndDepartmentRow } from './JobAndDepartmentRow';
import { ResumeRow } from './ResumeRow';
import { CoverLetterRow } from './CoverLetterRow';
import { OfficeAndWorkEmailRow } from './OfficeAndWorkEmailRow';

interface PropTypes {
  register: UseFormRegister<PushToHrisFormValues>;
  control: Control<PushToHrisFormValues>;
  getValues: UseFormGetValues<PushToHrisFormValues>;
  setValue: UseFormSetValue<PushToHrisFormValues>;
  errors: FieldErrors;
}

export function ProfessionalInformationRows(props: PropTypes) {
  return (
    <>
      <h4 className='mb-3'>Professional Information</h4>
      <JobAndDepartmentRow
        register={props.register}
        errors={props.errors}
        control={props.control}
      />
      <ResumeRow
        getValues={props.getValues}
        setValue={props.setValue}
        register={props.register}
      />
      <CoverLetterRow
        getValues={props.getValues}
        setValue={props.setValue}
        register={props.register}
      />
      <OfficeAndWorkEmailRow
        control={props.control}
        register={props.register}
        errors={props.errors}
      />
    </>
  );
}
