import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  showBackdrop?: boolean;
  minHeight?: string;
  message?: string; // The left CSS property is affected by the existence of a message.
}

// This spinner to load panels/pages. Use LoadingSpinnerIcon for IconSpan buttons.
export function LoadingSpinner(props: PropTypes) {
  const customStyle =
    props.minHeight == null ? null : { minHeight: props.minHeight };

  return (
    <>
      {props.showBackdrop ? <div className={styles.backdrop} /> : null}
      <div style={customStyle}>
        <svg
          className={classNames(
            styles.spinner,
            props.message
              ? styles.spinnerWithMessage
              : styles.spinnerWithoutMessage,
          )}
          viewBox='0 0 50 50'
        >
          <circle
            className={styles.path}
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='5'
          />
        </svg>
        {props.message && (
          <label
            className={classNames(
              'fw-semibold fs-5',
              styles['spinner-message'],
            )}
          >
            {props.message}
          </label>
        )}
      </div>
    </>
  );
}
