import React from 'react';
import { ListCandidate } from '../../../../../../../entities/applicant_tracking/ListCandidate';
import { BulkActionButton } from '../BulkActionButton';
import {
  checkIfSelectedCandidatesAreInTheSameInterviewStage,
  checkIfSelectedCandidatesHaveSameJob,
} from '../utils';
import { redirectToBulkSelfSchedulingPage } from '../../../../../../../utils/applicant_tracking/buildSelfScheduling';
import { BulkSelfSchedulingType } from '../../../../../../../services/dtos/BulkSelfSchedulingDto';

interface PropTypes {
  selectedCandidates: ListCandidate[];
}

export function SelfSchedulingButton(props: PropTypes) {
  const actionDisabled =
    props.selectedCandidates.length === 0 ||
    !checkIfSelectedCandidatesHaveSameJob(props.selectedCandidates) ||
    !checkIfSelectedCandidatesAreInTheSameInterviewStage(
      props.selectedCandidates,
    );
  const toolTipText = actionDisabled
    ? 'Bulk Self-Scheduling is only available for candidates that share the same job and stage. The stage needs to include an interview.'
    : 'Send Self-Scheduling Link';

  const onClick = () => {
    if (props.selectedCandidates.length == 0) {
      return;
    }

    const jobId = props.selectedCandidates[0].application.job.id;
    const interviewStageId =
      props.selectedCandidates[0].application.currentStage.interviewStageId;

    redirectToBulkSelfSchedulingPage(
      BulkSelfSchedulingType.Applications,
      jobId,
      interviewStageId,
      props.selectedCandidates.length,
      props.selectedCandidates.map((c) => c.application.id),
    );
  };

  return (
    <BulkActionButton
      iconName='bi-calendar2-check'
      toolTipText={toolTipText}
      disabled={actionDisabled}
      onClick={onClick}
      analyticsId='bulk-self-scheduling-button'
    />
  );
}
