import React, { useEffect, useState } from 'react';
import { TableRowLayout } from './TableRowLayout';
import { Name } from './Name';
import { Jobs } from './Jobs';
import { shortFormatDate } from '../../../../../../../utils/timeFormat';
import Stages from './Stages';
import { PendingActions } from './PendingActions';
import { Dropdown } from '../../../../../../../components/Dropdown';
import {
  appendRedirectUri,
  removeTrailingSlashes,
} from '../../../../../../../utils/url';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { ArchiveCandidate } from './ArchiveCandidate';
import classNames from 'classnames';
import { Channel } from './Channel';
import { CheckboxButton } from '../../../../../../../components/CheckboxButton';
import { DeleteCandidate } from './DeleteCandidate';
import { User } from '../../../../../../../entities/User';
import { HrisEmployee } from '../../../../../../../entities/applicant_tracking/HrisEmployees';
import { PushToHrisModal } from '../../../../../../CandidatePage/CandidateActions/PushToHrisModal';
import { HrisProvider } from '../../../../../../../enums/IntegrationProvider/HrisProvider';
import { renderPushToHris } from '../../../../../../CandidatePage/CandidateActions/DropdownActions';
import { AlertObject } from '../../../../../../../components/Alert';
import { EmailAccountIntegration } from '../../../../../../../entities/EmailAccountIntegration';
import { NewListCandidate } from '../../../../../../../entities/v1/applicant_tracking/NewListCandidate';
import { ApplicationService } from '../../../../../../../services/v1/applicant_tracking/ApplicationService';
import { SendOfferSwitch } from '../../../../../SendOfferSwitch';

interface PropTypes {
  atsSignableOfferEnabled: boolean;
  candidates: NewListCandidate[];
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  selectedCandidates: NewListCandidate[];
  setSelectedCandidates: (candidates: NewListCandidate[]) => void;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

interface RowActionPropTypes {
  currentUser: User;
  candidate: NewListCandidate;
  hrisEmployee?: HrisEmployee;
  hrisProvider?: HrisProvider;
  anonymized: boolean;
  onClickArchive: () => void;
  onClickDelete: () => void;
  onClickPushToHris: () => void;
  onClickSendOffer: () => void;
}

interface CandidateRowPropTypes {
  candidate: NewListCandidate;
  hrisEmployee?: HrisEmployee;
  hrisProvider?: HrisProvider;
  currentUser: User;
  checked: boolean;
  selectedCandidates: NewListCandidate[];
  setSelectedCandidates: (candidates: NewListCandidate[]) => void;
  setCandidateForAction: (candidate: NewListCandidate) => void;
  setArchiveModalActive: (active: boolean) => void;
  setDeleteModalActive: (active: boolean) => void;
  setPushToHrisModalActive: (active: boolean) => void;
  setSendOfferModalActive: (active: boolean) => void;
}

function menuAction(
  action: () => void,
  text: string,
  iconName: string,
  disabled = false,
) {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={text}
        icon={{ name: iconName, className: 'fs-4' }}
        className={classNames(disabled ? 'text-muted' : 'text-blue')}
      />
    ),
    isDisabled: disabled,
  };
}

function getCandidateUrl(candidate: NewListCandidate) {
  if (candidate.application == null) return '#';

  return appendRedirectUri(
    `${removeTrailingSlashes(window.location.origin)}/applications/${
      candidate.application.id
    }`,
  );
}

function editCandidateProfile(
  editCandidatePermitted: boolean,
  candidate: NewListCandidate,
) {
  if (!editCandidatePermitted || candidate.redacted) return null;

  const url = appendRedirectUri(
    `/applicant_tracking/candidates/${candidate.id}/edit`,
  );

  return menuAction(
    () => (window.location.href = url.toString()),
    'Edit Candidate Profile',
    'bi-person',
  );
}

function addJobApplicationAction(
  newApplicationPermitted: boolean,
  candidate: NewListCandidate,
) {
  if (!newApplicationPermitted || candidate.redacted) return null;

  const url = new URL(
    `/applicant_tracking/applications/new`,
    window.location.origin,
  );
  url.searchParams.set('candidate_id', candidate.id.toString());

  return menuAction(
    () => (window.location.href = appendRedirectUri(url.toString())),
    'Add Job Application',
    'bi-plus-circle',
  );
}

function editJobApplicationAction(
  editApplicationPermitted: boolean,
  candidate: NewListCandidate,
) {
  if (!candidate.application || !editApplicationPermitted || candidate.redacted)
    return null;

  const url = `/applicant_tracking/applications/${candidate.application.id}/edit`;

  return menuAction(
    () => (window.location.href = appendRedirectUri(url.toString())),
    'Edit Job Application',
    'bi-pencil',
  );
}

function deleteApplicationAction(
  candidate: NewListCandidate,
  currentUser: User,
  onClickDelete: () => void,
) {
  if (candidate.status === 'active' || !currentUser.isAdmin) return null;
  if (candidate.application != null) return null;

  return menuAction(() => onClickDelete(), 'Delete', 'bi-trash');
}

function sendOfferAction(onClickSendOffer: () => void, enabled?: boolean) {
  if (!enabled) return null;

  return menuAction(
    () => onClickSendOffer(),
    'Send Offer',
    'bi-file-earmark-medical',
  );
}

function RowActions(props: RowActionPropTypes) {
  const [menuActions, setMenuActions] = useState(null);

  useEffect(() => {
    (async () => {
      const actions = await ApplicationService.listApplicationActions({
        applicationId: props.candidate.application?.id,
        candidateId: props.candidate.id,
      });

      if (props.anonymized) {
        return setMenuActions([
          actions &&
            menuAction(
              () => props.onClickArchive(),
              archiveAction,
              'bi-archive',
              !actions.archivable,
            ),
        ]);
      }

      setMenuActions(
        [
          menuAction(
            () => (window.location.href = getCandidateUrl(props.candidate)),
            'View',
            'bi-eye',
            props.candidate.application == null,
          ),
          editCandidateProfile(actions.editCandidatePermitted, props.candidate),
          addJobApplicationAction(
            actions.newApplicationPermitted,
            props.candidate,
          ),
          editJobApplicationAction(
            actions.editApplicationPermitted,
            props.candidate,
          ),
          actions &&
            menuAction(
              () => props.onClickArchive(),
              archiveAction,
              'bi-archive',
              archiveAction === 'Archive'
                ? !actions.archivable
                : !actions.unarchivable,
            ),
          renderPushToHris(
            () => props.onClickPushToHris(),
            props.candidate.redacted,
            props.candidate?.application?.status,
            props.hrisEmployee?.externalUrl,
            actions.pushCandidateToHrisPermitted ? props.hrisProvider : null,
          ),
          deleteApplicationAction(
            props.candidate,
            props.currentUser,
            props.onClickDelete,
          ),
          sendOfferAction(
            props.onClickSendOffer,
            actions.sendOfferPermitted && !props.candidate.redacted,
          ),
        ].filter(Boolean),
      );
    })();
  }, []);

  const archiveAction =
    props.candidate.status === 'archived' ? 'Unarchive' : 'Archive';

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-5 text-blue',
      }}
      isLoading={menuActions == null}
      menuActions={menuActions || []}
    />
  );
}

function getUpdatedAtString(
  applicationUpdatedAt?: Date,
  candidateUpdatedAt?: Date,
): string {
  if (applicationUpdatedAt == null && candidateUpdatedAt == null) {
    return 'N/A';
  }

  if (!applicationUpdatedAt || candidateUpdatedAt > applicationUpdatedAt) {
    return shortFormatDate(candidateUpdatedAt);
  }

  return shortFormatDate(applicationUpdatedAt);
}

function isCandidateSelected(
  selectedCandidates: NewListCandidate[],
  candidate: NewListCandidate,
): boolean {
  return (
    selectedCandidates.find(
      (c) =>
        c.id === candidate.id &&
        c.application?.id === candidate.application?.id,
    ) != null
  );
}

function handleCheckCandidate(
  selectedCandidates: NewListCandidate[],
  candidate: NewListCandidate,
  checked: boolean,
  setSelectedCandidates: (selectedCandidates: NewListCandidate[]) => void,
) {
  if (checked) {
    if (!isCandidateSelected(selectedCandidates, candidate)) {
      const combinedList = [...selectedCandidates, candidate];

      setSelectedCandidates(combinedList);
    }
  } else {
    setSelectedCandidates(
      selectedCandidates.filter(
        (c) =>
          c.id !== candidate.id ||
          c.application?.id !== candidate.application?.id,
      ),
    );
  }
}

function CandidateRow(props: CandidateRowPropTypes) {
  const bulkSelectionEnabled =
    props.candidate.anonymized === false &&
    props.candidate.status !== 'draft' &&
    props.candidate.application?.bulkActionsPermitted;

  return (
    <TableRowLayout classNames={props.checked && 'bg-light-info'}>
      <CheckboxButton
        checked={props.checked}
        onChange={() =>
          handleCheckCandidate(
            props.selectedCandidates,
            props.candidate,
            !props.checked,
            props.setSelectedCandidates,
          )
        }
        name={null}
        disabled={!bulkSelectionEnabled}
      />
      <Name
        candidate={props.candidate}
        hrisEmployee={props.hrisEmployee}
        application={props.candidate.application}
      />
      <Jobs candidate={props.candidate} />
      <span>
        {getUpdatedAtString(
          props.candidate.application?.updatedAt,
          props.candidate.updatedAt,
        )}
      </span>
      <Channel application={props.candidate.application} />
      <Stages
        stageName={props.candidate.application?.currentStage?.name}
        millisInStage={props.candidate.application?.currentStage?.millisInStage}
      />
      <PendingActions application={props.candidate.application} />
      <RowActions
        candidate={props.candidate}
        hrisEmployee={props.hrisEmployee}
        hrisProvider={props.hrisProvider}
        currentUser={props.currentUser}
        anonymized={props.candidate.anonymized}
        onClickArchive={() => {
          props.setCandidateForAction(props.candidate);
          props.setArchiveModalActive(true);
        }}
        onClickDelete={() => {
          props.setCandidateForAction(props.candidate);
          props.setDeleteModalActive(true);
        }}
        onClickPushToHris={() => {
          props.setCandidateForAction(props.candidate);
          props.setPushToHrisModalActive(true);
        }}
        onClickSendOffer={() => {
          props.setCandidateForAction(props.candidate);
          props.setSendOfferModalActive(true);
        }}
      />
    </TableRowLayout>
  );
}

export function TableBody(props: PropTypes) {
  const [archiveModalActive, setArchiveModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [sendOfferModalActive, setSendOfferModalActive] = useState(false);
  const [candidateForAction, setCandidateForAction] =
    useState<NewListCandidate>(null);
  const [pushToHrisModalActive, setPushToHrisModalActive] =
    useState<boolean>(false);

  const handlePushToHrisModalClose = () => {
    setPushToHrisModalActive(false);
    props.reloadPage();
  };

  const handleSendOfferClose = () => {
    setSendOfferModalActive(false);
    props.reloadPage();
  };

  return (
    <>
      <ArchiveCandidate
        archiveModalActive={archiveModalActive}
        candidate={candidateForAction}
        onClose={() => setArchiveModalActive(false)}
      />
      <DeleteCandidate
        deleteModalActive={deleteModalActive}
        candidate={candidateForAction}
        onClose={() => setDeleteModalActive(false)}
      />
      <PushToHrisModal
        applicationIds={[candidateForAction?.application?.id]}
        allowedHrisProvider={props.hrisProvider}
        isOpen={pushToHrisModalActive}
        onClose={handlePushToHrisModalClose}
        setAlert={props.setAlert}
        candidateName={candidateForAction?.displayName}
        jobName={candidateForAction?.application?.job?.name}
      />
      <SendOfferSwitch
        applicationId={candidateForAction?.application?.id}
        atsSignableOfferEnabled={props.atsSignableOfferEnabled}
        to={candidateForAction?.email}
        isOpen={sendOfferModalActive}
        onClose={handleSendOfferClose}
        setAlert={props.setAlert}
        hasEmailConnected={props.currentUser.hasEmailConnected || false}
      />
      <tbody>
        {props.candidates.map((candidate: NewListCandidate, idx: number) => {
          return (
            <CandidateRow
              key={idx}
              candidate={candidate}
              hrisEmployee={props.hrisEmployees.find(
                (e) => e.applicationId === candidate.application?.id,
              )}
              hrisProvider={props.hrisProvider}
              currentUser={props.currentUser}
              checked={isCandidateSelected(props.selectedCandidates, candidate)}
              selectedCandidates={props.selectedCandidates}
              setArchiveModalActive={setArchiveModalActive}
              setCandidateForAction={setCandidateForAction}
              setDeleteModalActive={setDeleteModalActive}
              setSelectedCandidates={props.setSelectedCandidates}
              setPushToHrisModalActive={setPushToHrisModalActive}
              setSendOfferModalActive={setSendOfferModalActive}
            />
          );
        })}
      </tbody>
    </>
  );
}
