import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ScorecardAttribute } from '../ScorecardAttribute';
import { Survey } from '../../../../../../../entities/v1/survey_engine/Survey';
import { Section } from '../../../../../../../entities/v1/survey_engine/Section';
import { Question } from '../../../../../../../entities/v1/survey_engine/Question';

interface PropTypes {
  editable: boolean;
  attributes: Question[];
  section: Section;
  survey: Survey;
  fillColorClass?: string;
  scorecardId?: number;
  className?: string;
  setSurvey: (survey: Survey) => void;
}

export function ScorecardRatingQuestion(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-3 mb-2h'>{props.section.name}</div>
      <div
        className={classNames(
          'd-flex border border-blue-gray-200 rounded-2 py-4h ps-4 pe-5',
          props.className,
        )}
      >
        <div className='w-100'>
          <div
            className={classNames(
              'd-flex',
              'flex-column',
              'gap-2',
              styles['scorecard-category-list'],
            )}
          >
            {props.attributes.map((attribute: Question, index: number) => (
              <ScorecardAttribute
                scorecardId={props.scorecardId}
                editable={props.editable}
                attribute={attribute}
                section={props.section}
                key={index}
                survey={props.survey}
                setSurvey={props.setSurvey}
                fillColorClass={props.fillColorClass}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
