import React from 'react';
import { InputFormField } from '../../../components/InputFormField';
import { Button } from 'reactstrap';
import { UseFormRegister } from 'react-hook-form';
import { ShortApplication } from './ShortApplication';

interface PropTypes {
  register: UseFormRegister<ShortApplication>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
  isValid: boolean;
}

export function PersonalDetailsForm(props: PropTypes) {
  return (
    <form className='d-flex flex-column gap-3' onSubmit={props.onSubmit}>
      <div className='row'>
        <div className='col'>
          <InputFormField
            type='text'
            fieldName='First Name'
            isRequired={true}
            placeholder='John'
            classNames='fs-5'
            registerReturn={props.register('firstName', { required: true })}
          />
        </div>
        <div className='col'>
          <InputFormField
            type='text'
            fieldName='Last Name'
            isRequired={true}
            placeholder='Smith'
            classNames='fs-5'
            registerReturn={props.register('lastName', { required: true })}
          />
        </div>
      </div>
      <div>
        <InputFormField
          type='email'
          fieldName='Your Email'
          isRequired={true}
          placeholder='john.smith@example.com'
          classNames='fs-5'
          registerReturn={props.register('email', { required: true })}
        />
      </div>
      <Button
        type='submit'
        color='info'
        size='lg'
        disabled={props.isSubmitting || !props.isValid}
      >
        Continue
        {props.isSubmitting && (
          <span
            className='ms-2 spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          ></span>
        )}
      </Button>
      <p className='text-dark-200 fs-5 mb-0 text-center'>
        By continuing, I consent to the use of my personal information for
        current and future job opportunities.
      </p>
    </form>
  );
}
