import React from 'react';
import indeedImage from '../../../../../../../images/indeed.png';
import { ToggleButton } from '../../../../../../../components/ToggleButton';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { CheckboxButton } from '../../../../../../../components/CheckboxButton';
import { InfoTooltip } from '../../../../../../../components/InfoTooltip';
import classNames from 'classnames';

interface PropTypes {
  indeedEasyApplyEnabled: boolean;
  indeedEnabled: boolean;
  setIndeedEasyApplyEnabled: () => void;
  setIndeedEnabled: () => void;
}

function EasyApply(props: PropTypes) {
  return (
    <div className='d-flex align-items-center'>
      <CheckboxButton
        name='indeedEasyApplyEnabled'
        checked={props.indeedEasyApplyEnabled}
        disabled={!props.indeedEnabled}
        onChange={props.setIndeedEasyApplyEnabled}
      />
      <span className='fs-5 mx-2'>Easy Apply</span>
      <InfoTooltip>
        This option allows candidates to apply easily without leaving Indeed.
      </InfoTooltip>
    </div>
  );
}

export function IndeedToggle(props: PropTypes) {
  return (
    <div className='border border-blue-gray-500 rounded-3 py-4 ps-4 pe-6h mb-3'>
      <div
        className='d-flex gap-4 align-items-center'
        style={{ height: '50px' }}
      >
        <div
          className={classNames(
            'd-flex align-items-end me-auto',
            !props.indeedEnabled && 'opacity-50',
          )}
        >
          <img src={indeedImage} style={{ height: '24px' }} />
          <h3 className='mb-0 ms-4'>Indeed</h3>
        </div>
        <div className='d-flex align-items-center'>
          <EasyApply {...props} />
          <VerticalDivider classNames='mx-3 pb-4' />
          <span className='fs-5'>
            {props.indeedEnabled ? 'Enabled' : 'Disabled'}
          </span>
          <ToggleButton
            name='indeedEnabled'
            checked={props.indeedEnabled}
            onChange={props.setIndeedEnabled}
            color='info'
            classNames='ms-2h'
          />
        </div>
      </div>
    </div>
  );
}
