import React from 'react';
import { Section } from './Section';
import { WIDE_CONTAINER_WIDTH } from './consts';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { FormState } from './FormState';
import { EmailSubject } from './EmailSubject';
import { EmailBody } from './EmailBody';
import { EmailVariables } from './EmailVariables';

interface PropTypes {
  control: Control<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
}

export function EmailMessageSection(props: PropTypes) {
  return (
    <Section title='Email Message'>
      <div className='d-flex flex-column gap-4'>
        <div style={{ maxWidth: WIDE_CONTAINER_WIDTH }}>
          <EmailSubject {...props} />
        </div>
        <div className='w-100 d-flex gap-4'>
          <EmailBody {...props} />
          <EmailVariables />
        </div>
      </div>
    </Section>
  );
}
