import { JobPostingDetailsForm } from '../../JobBoardsTabUI/JobPostingDetails';
import {
  validateSalaryCurrency,
  validateSalaryPeriod,
  validateSalaryFromInput,
} from './sharedFormValidations';

const FORM_MANDATORY_FIELDS = ['country', 'contactEmail', 'salaryRangeFrom'];

export function isIndeedFormValid(form: JobPostingDetailsForm): boolean {
  if (form == null) {
    return false;
  }

  for (const [key, value] of Object.entries(form)) {
    if (FORM_MANDATORY_FIELDS.includes(key) && !value) {
      return false;
    }
  }

  if (!validateSalaryCurrency(form)) {
    return false;
  }

  if (!validateSalaryPeriod(form)) {
    return false;
  }

  if (validateSalaryFromInput(form)) {
    return false;
  }

  if (!validateSalaryCurrency(form)) {
    return false;
  }

  if (!validateSalaryPeriod(form)) {
    return false;
  }

  return true;
}
