import React, { useEffect } from 'react';
import { CompanyInfo } from '../../../../entities/CompanyInfo';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { LogoWithBackgroundHeader } from '../../../Headers/LogoWithBackgroundHeader';
import { CareersPageBody } from './CareersPageBody';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { CareersPageFooter } from './CareersPageFooter';
import ahoy from 'ahoy.js';
import { FeatureFlagContext } from '../../../../contexts/FeatureFlagContext';
import { JobPostClickEvents } from '../../../../enums/ClickEvents/JobPostClickEvents';

interface PropTypes {
  jobPosts: JobPost[];
  companyInfo?: CompanyInfo;
  atsCaptureEventsAhoy: boolean;
}

export default function CareersPage(props: PropTypes) {
  const whitelabelEnabled = [
    props.companyInfo?.backgroundColor == null ||
      props.companyInfo?.backgroundColor == '',
    props.companyInfo?.logoUrl == null,
  ].some((el) => el != true);

  useEffect(() => {
    if (props.atsCaptureEventsAhoy) {
      ahoy.track(JobPostClickEvents.JobPostsListLoaded, {
        organization_id: props.companyInfo.organizationId,
      });
    }
  }, [props.atsCaptureEventsAhoy, props.companyInfo.organizationId]);

  return (
    <FeatureFlagContext.Provider
      value={{ atsCaptureEventsAhoy: props.atsCaptureEventsAhoy }}
    >
      <CenteredLayout
        heightClass='h-100'
        innerContainerClassName='d-flex flex-column h-100'
      >
        <LogoWithBackgroundHeader
          companyInfo={props.companyInfo}
          whitelabelEnabled={whitelabelEnabled}
        />
        <CareersPageBody
          jobPosts={props.jobPosts}
          whitelabelEnabled={whitelabelEnabled}
          companyInfo={props.companyInfo}
        />
        <CareersPageFooter />
      </CenteredLayout>
    </FeatureFlagContext.Provider>
  );
}
