import React from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';

interface PropTypes {
  applicationId: number;
  currentStage: string;
  candidateName: string;
  isOpen: boolean;
  jobId: string;
  customModalBody?: React.ReactNode;
  onClose: () => void;
  onMovingCandidate: (action?: MoveCandidateProps) => void;
}

export interface MoveCandidateProps {
  jobStageName: string;
  state: 'Success' | 'Error';
  candidateName?: string;
  recordsProcessed?: number;
}

export function MoveCandidateStageModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Move to Stage'
      bodyChildren={
        props.customModalBody ?? (
          <ModalBody
            applicationId={props.applicationId}
            candidateName={props.candidateName}
            jobId={props.jobId}
            currentStage={props.currentStage}
            onClose={props.onClose}
            onMovingCandidate={props.onMovingCandidate}
          />
        )
      }
    />
  );
}
