import { Trigger } from '../../../../entities/applicant_tracking/Trigger';
import { JobService } from '../../../../services/applicant_tracking/JobService';
import { EditJobDto } from '../../../../services/applicant_tracking/JobService/EditJobDto';

function modifyName(name: string): string {
  return `Copy of ${name}`;
}

function modifyTriggers(triggers: Trigger[]): Trigger[] {
  return triggers.map((trigger) => ({
    ...trigger,
    id: null,
  }));
}

function referenceJobModifier(
  jobDTO: EditJobDto,
  referenceJobId: string,
): EditJobDto {
  return {
    ...jobDTO,
    jobForm: {
      ...jobDTO.jobForm,
      jobId: null,
      jobPostId: null,
      name: modifyName(jobDTO.jobForm.name),
      triggers: modifyTriggers(jobDTO.jobForm.triggers),
      referenceJobId: parseInt(referenceJobId, 10),
    },
  };
}

export function loadReferenceJob(
  referenceJobId: string,
  setJobDto: (dto: EditJobDto) => void,
  setIsLoading: (loading: boolean) => void,
) {
  if (!referenceJobId) return setIsLoading(false);

  JobService.get(referenceJobId).then((dto: EditJobDto) => {
    setJobDto(referenceJobModifier(dto, referenceJobId));
    setIsLoading(false);
  });
}
