import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { SingleDatePicker } from '../../../../../../../components/SingleDatePicker';
import moment, { Moment } from 'moment';

interface PropTypes {
  label: string;
  date?: string;
  setDate: (date: string) => void;
}

export const DATE_FORMAT = 'DD/MM/yyyy';

export function DateInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={props.label} isRequired={true} />
      <SingleDatePicker
        className='fs-5'
        dateFilter={{
          start: props.date && moment(props.date, DATE_FORMAT),
        }}
        minDate={moment.utc()}
        onChange={(moment: Moment) =>
          moment != null && props.setDate(moment.format(DATE_FORMAT))
        }
      />
    </>
  );
}
