import React, { useState } from 'react';
import { SpanTooltip } from '../../../components/SpanTooltip';
import { IconButton } from '../../../components/IconButton';
import copy from 'copy-to-clipboard';

export const TEMPLATE_VARIABLES: { [key: string]: string } = {
  "Candidate's Name": '{{{candidate_name}}}',
  'Job Title': '{{{job_title}}}',
  'Company Name': '{{{company_name}}}',
  'Sender Name': '{{{sender_name}}}',
};

export function EmailVariables() {
  return (
    <div className='col'>
      <h4>Email Variables</h4>
      <div className='d-flex flex-column'>
        {Object.entries(TEMPLATE_VARIABLES).map(([key, value]) => (
          <CopyButton key={key} label={key} value={value} />
        ))}
      </div>
    </div>
  );
}

function CopyButton(props: { label: string; value: string }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <SpanTooltip
      className='w-auto'
      text={
        <IconButton
          key={props.value}
          iconName='bi-files'
          color='borderless'
          buttonText={props.label}
          onClick={() => handleCopy(props.value, setIsOpen)}
        />
      }
      tooltipText='Copied to Clipboard'
      controlledTooltip={{ isOpen: isOpen }}
    />
  );
}

function handleCopy(value: string, setIsOpen: (open: boolean) => void) {
  copy(value);
  setIsOpen(true);
  setTimeout(() => setIsOpen(false), 2000);
}
