import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../../components/CloseableModal';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../../../../components/Select';
import { selectTheme } from '../../../../../components/Select/selectTheme';
import { multiSelectStyle } from '../../../../../components/Select/multiSelectStyle';
import { Tag } from '../../../../../entities/v1/applicant_tracking/Tag';
import { TagList } from '../../../../../entities/v1/applicant_tracking/TagList';
import { ApplicationTag } from '../../../../../entities/v1/applicant_tracking/ApplicationTag';
import { AlertObject } from '../../../../../components/Alert';
import { AddAndCancelButtons } from './AddAndCancelButtons';
import { ApplicationService } from '../../../../../services/v1/applicant_tracking/ApplicationService';

interface PropTypes {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  applicationId: number;
  onTagsAdded: (newTags: ApplicationTag[]) => void;
  setAlert: (alert: AlertObject) => void;
}

export function AddTagModal(props: PropTypes) {
  const [isTagsLoading, setIsTagsLoading] = useState<boolean>(true);
  const [selectedTags, setSelectedTag] = useState<SelectOption[]>(null);

  const [tagList, setTagList] = useState<TagList>(null);
  const [tags, setTags] = useState<Tag[]>([]);

  const loadAvailableTags = (input?: string) => {
    const nextPage = tagList == null || input != null ? 1 : tagList.nextPage;

    if (nextPage == null) return;

    setIsTagsLoading(true);

    ApplicationService.listAvailableTags(
      props.applicationId,
      nextPage,
      10,
      input,
    )
      .then((response: TagList) => {
        setTagList(response);
        if (response.page == 1) {
          setTags(response.tags);
        } else {
          tags.push(...response.tags);
        }
      })
      .finally(() => setIsTagsLoading(false));
  };

  useEffect(() => {
    loadAvailableTags();
  }, []);

  const buildTagOptions = (
    tags: Tag[],
    selected: SelectOption[],
  ): SelectOption[] => {
    return tags
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((tag) => ({
        value: tag.name,
        label: tag.name,
        selected: selected?.map((s) => s.value).includes(tag.name) ?? false,
      }));
  };

  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.setIsModalOpen(false)}
      isOpen={props.isModalOpen}
      size={'md'}
      headerTitle={'Add Tags to Candidate'}
      bodyChildren={
        <>
          <h5>Tags</h5>
          <CreatableSelect
            formatCreateLabel={(value) => (
              <div className='text-info'>Create {value}</div>
            )}
            isLoading={isTagsLoading}
            value={selectedTags}
            isMulti
            hideSelectedOptions={true}
            tabSelectsValue
            components={{ DropdownIndicator: null }}
            theme={selectTheme}
            styles={multiSelectStyle}
            isClearable={false}
            placeholder={'Select or create tags'}
            options={buildTagOptions(tags, selectedTags)}
            isOptionSelected={(option: SelectOption) => !!option.selected}
            onChange={(values: SelectOption[]) => setSelectedTag(values)}
            onMenuScrollToBottom={() => loadAvailableTags()}
            onInputChange={(newValue, _actionMeta) =>
              loadAvailableTags(newValue)
            }
          />
          <AddAndCancelButtons
            setIsModalOpen={props.setIsModalOpen}
            selectedTags={selectedTags}
            onTagsAdded={props.onTagsAdded}
            setAlert={props.setAlert}
            applicationId={props.applicationId}
          />
        </>
      }
    />
  );
}
