import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { TextAreaWithFormats } from '../../../../../../../components/TextAreaWithFormats';
import {
  ContentLength,
  ContentType,
  TextAreaFormatState,
} from '../../../../../../../components/TextAreaWithFormats/types';
import { GenerateState } from '../../../../../../../components/FillWithAIButton';
import { RegeneratePanel } from './RegeneratePanel';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { generateAiResponse } from './generateAiResponse';
import { Scorecard } from '../../../../../../../entities/applicant_tracking/Scorecard';
import { ScorecardTextQuestionTitle } from './ScorecardTextQuestionTitle';

interface PropTypes {
  title: JSX.Element;
  label: string;
  attribute: string;
  scorecard: Scorecard;
  aiScorecardFill?: boolean;
  className?: string;
  disabled?: boolean;
  value?: string;
  onUpdate?: (value: string) => void;
}

export function ScorecardTextQuestion(props: PropTypes) {
  const isInitialRender = useRef(true);
  const [contentType, setContentType] = useState<ContentType>('paragraphs');
  const [contentLength, setContentLength] = useState<ContentLength>('medium');
  const [regeneratePrompt, setRegeneratePrompt] = useState<string>('');
  const [showRegeneratePanel, setShowRegeneratePanel] =
    useState<boolean>(false);
  const [aiGenerateState, setAiGenerateState] =
    useState<GenerateState>('Generate');

  const callGenerateResponse = (prompt: string) =>
    generateAiResponse(
      props.scorecard.id,
      prompt,
      contentType,
      contentLength,
      props.attribute,
      props.onUpdate,
      setAiGenerateState,
    );

  const generateResponse = (prompt: string) => {
    if (aiGenerateState === 'Regenerate' && !showRegeneratePanel)
      return setShowRegeneratePanel(true);

    setShowRegeneratePanel(false);
    callGenerateResponse(prompt);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    callGenerateResponse(regeneratePrompt);
  }, [contentType, contentLength]);

  return (
    <div className='w-100 mt-2h'>
      <ScorecardTextQuestionTitle
        title={props.title}
        aiScorecardFill={props.aiScorecardFill}
        regeneratePrompt={regeneratePrompt}
        showRegeneratePanel={showRegeneratePanel}
        aiGenerateState={aiGenerateState}
        generateResponse={generateResponse}
      />
      {props.aiScorecardFill && showRegeneratePanel && (
        <RegeneratePanel
          regeneratePrompt={regeneratePrompt}
          setRegeneratePrompt={setRegeneratePrompt}
          onCancel={() => setShowRegeneratePanel(false)}
          onRegenerate={(prompt) => generateResponse(prompt)}
        />
      )}
      <TextAreaWithFormats
        disabled={props.disabled}
        readOnly={aiGenerateState === 'Generating'}
        formatChangesDisabled={aiGenerateState === 'Generating'}
        className={classNames(props.className, styles['open-text-input'])}
        value={props.value}
        placeholder={props.label}
        includeFormats={props.aiScorecardFill && aiGenerateState !== 'Generate'}
        onChange={(state: TextAreaFormatState) => {
          props.onUpdate?.(state.value);
          setContentLength(state.contentLength);
          setContentType(state.contentType);
        }}
      />
      {props.aiScorecardFill && aiGenerateState !== 'Generate' && (
        <IconSpan
          className='fs-6 text-dark-500 pt-1 mt-2'
          spanText='AI responses may include mistakes. Check all relevant information before making a decision.'
          icon={{ name: 'bi-info-circle' }}
        />
      )}
    </div>
  );
}
