import React, { useEffect, useState } from 'react';
import { OfferSummary } from '../../../../applicant_tracking/SignableOffers/SendSignableOfferModal/BodyWrapper/OfferSummary';
import { CloseableModal } from '../../../../../components/CloseableModal';
import { SignableOffer } from '../../../../../entities/v1/applicant_tracking/SignableOffer';
import { Button } from 'reactstrap';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import styles from './styles.module.scss';
import { SignableOfferService } from '../../../../../services/v1/applicant_tracking/SignableOfferService';

interface PropTypes {
  isOpen: boolean;
  signableOfferId: number;
  onClose: () => void;
}

function OfferSummaryBody(props: { signableOffer: SignableOffer }) {
  if (props.signableOffer == null) {
    return <LoadingSpinner />;
  }

  return (
    <OfferSummary
      alertMessage={
        props.signableOffer?.status === 'expired' &&
        'This offer has expired and is no longer valid. If applicable, a new offer needs to be sent to the candidate.'
      }
      className={styles['modal-body']}
      emailHtml={
        props.signableOffer.email.bodyText || props.signableOffer.email.html
      }
      emailAttachments={props.signableOffer.email.attachments?.map((v) => {
        return {
          filename: v.filename,
          size: null,
        };
      })}
      isLoading={props.signableOffer == null}
      signableDocuments={props.signableOffer?.signableOfferDocuments.map(
        (x, index) => {
          return {
            id: x.id,
            fileName: x.filePath,
            url: x.url,
            order: index,
            kind: 'offer',
          };
        },
      )}
      signatories={props.signableOffer.signatories}
    />
  );
}

export function SignableOfferSummaryModal(props: PropTypes) {
  const [signableOffer, setSignableOffer] = useState<SignableOffer>(null);

  useEffect(() => {
    (async () => {
      if (props.isOpen && signableOffer == null) {
        setSignableOffer(
          await SignableOfferService.show({
            id: props.signableOfferId,
          }),
        );
      }
    })();
  }, [props.isOpen]);

  return (
    <CloseableModal
      size='xl'
      isOpen={props.isOpen}
      className='modal-dialog-centered'
      onClose={props.onClose}
      headerTitle={'Offer Summary'}
      bodyChildren={<OfferSummaryBody signableOffer={signableOffer} />}
      footerChildren={
        <Button color='primary' onClick={props.onClose}>
          Close
        </Button>
      }
    />
  );
}
