import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Control, Controller } from 'react-hook-form';
import {
  LocationOption,
  LocationSelect,
} from '../../../../../../../components/LocationSelect';
import { getSelectedOption } from '../../../../../../applicant_tracking/Candidates/LocationInput';
import { PushToHrisFormValues } from '../../..';

interface PropTypes {
  control: Control<PushToHrisFormValues>;
}

export function LocationRow(props: PropTypes) {
  return (
    <Row className='mb-3'>
      <Col sm={6}>
        <FormFieldHeader
          fieldName='Location'
          classNames='fw-semibold'
          isRequired={false}
        />
        <Controller
          control={props.control}
          name={'location'}
          render={({ field }) => (
            <LocationSelect
              selected={getSelectedOption(field.value)}
              onChange={(option: LocationOption) => {
                if (option == null) {
                  field.onChange(null);
                  return;
                }
                field.onChange({
                  name: option.label,
                  address: option.address,
                  city: option.city,
                  state: option.state,
                  country: option.country,
                });
              }}
              className='fs-5'
              isClearable={true}
            />
          )}
        />
      </Col>
    </Row>
  );
}
