import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Application } from '../../../../../entities/Application';
import { AlertMessage } from '../../../../../components/AlertMessage';
import { SelectOption } from '../../../../../components/Select';
import { ScorecardTemplate } from '../../../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { ScorecardService } from '../../../../../services/v1/applicant_tracking/ScorecardService';
import { appendRedirectUri } from '../../../../../utils/url';
import { ScorecardTemplateService } from '../../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { AsyncSelect } from '../../../../../components/Select/async';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  onClose: () => void;
}

function buildScorecardTemplateOptions(
  scorecardTemplates: ScorecardTemplate[],
) {
  return scorecardTemplates.map((scorecard) => ({
    label: scorecard.surveyTemplate.name,
    value: scorecard.id.toString(),
  }));
}

export function ModalBody(props: PropTypes) {
  const [scorecardTemplates, setScorecardTemplates] =
    useState<SelectOption[]>(null);
  const [selectedScorecardTemplateId, setSelectedScorecardTemplateId] =
    useState<number>(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateScorecard = () => {
    setIsLoading(true);
    ScorecardService.create(
      props.application.id,
      selectedScorecardTemplateId,
    ).then(
      (scorecard) =>
        (window.location.href = appendRedirectUri(
          `/applicant_tracking/scorecards/${scorecard.id}`,
        )),
    );
  };

  const loadScorecardTemplates = async (): Promise<SelectOption[]> => {
    const response = await ScorecardTemplateService.list({
      jobId: props.application.job.id,
      expand: ['survey_template'],
    });
    const scorecardTemplates = buildScorecardTemplateOptions(
      response.scorecardTemplates,
    );

    setScorecardTemplates(scorecardTemplates);

    return scorecardTemplates;
  };

  return (
    <>
      <div className='fs-5 fw-semibold mb-2'>Scorecard</div>
      <AsyncSelect
        isClearable={true}
        selected={scorecardTemplates?.find(
          (template) =>
            template.value === selectedScorecardTemplateId?.toString(),
        )}
        placeholder='Select scorecard'
        isSearchable={false}
        onChange={(option: SelectOption) => {
          if (!option) return setSelectedScorecardTemplateId(null);
          setSelectedScorecardTemplateId(parseInt(option.value));
        }}
        loadOptions={loadScorecardTemplates}
      />
      <AlertMessage
        text='Provide feedback using a predefined workflow scorecard, this will not be linked to an interview.'
        icon={{ name: 'bi-info-circle' }}
        className='bg-light-warning mt-4'
        textClassName='fs-5'
      />
      <div className='d-flex justify-content-end mt-4 gap-3 w-100'>
        <Button color='danger' onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={generateScorecard}
          disabled={!selectedScorecardTemplateId || isLoading}
        >
          Create
        </Button>
      </div>
    </>
  );
}
