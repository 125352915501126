import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { AttachmentDto } from '../../../dtos/EmailDto';
import { SignableOffer } from '../../../../entities/v1/applicant_tracking/SignableOffer';
import * as queryString from 'query-string';

export interface CreateSignableOfferParams {
  applicationId: number;
  signableOfferTemplateId: number;
  variables: string;
  emailHtml: string;
  subjectText: string;
  to: string;
  bcc?: string[];
  cc?: string[];
  attachments: AttachmentDto[];
}

export class SignableOfferService {
  public static create(
    params: CreateSignableOfferParams,
  ): Promise<SignableOffer> {
    return ApiService.post<SignableOffer>(
      `/api/v1/applicant_tracking/signable_offers?${this.getOfferExpandableParams()}`,
      snakecaseKeys(params),
    );
  }

  public static show(params: { id: number }): Promise<SignableOffer> {
    return ApiService.get<SignableOffer>(
      `/api/v1/applicant_tracking/signable_offers/${
        params.id
      }?${this.getOfferExpandableParams([
        'signable_offer_documents',
        'signatories',
        'signatories.user',
        'email',
      ])}`,
    );
  }

  public static last(params: {
    applicationId: number;
  }): Promise<SignableOffer> {
    return ApiService.get<SignableOffer>(
      `/api/v1/applicant_tracking/signable_offers/most_recent?application_id=${
        params.applicationId
      }&${this.getOfferExpandableParams(['signable_offer_documents'])}`,
    );
  }

  public static revoke(id: number): Promise<SignableOffer> {
    return ApiService.put<SignableOffer>(
      `/api/v1/applicant_tracking/signable_offers/${id}/revoke?${this.getOfferExpandableParams()}`,
    );
  }

  private static getOfferExpandableParams(extra?: string[]): string {
    const params = {
      expand: [
        'application',
        'application.job',
        'application.candidate',
        ...(extra ? extra : []),
      ],
    };

    return queryString.stringify(params, {
      arrayFormat: 'bracket',
    });
  }
}
