import React, { useContext, useEffect, useState } from 'react';
import {
  Control,
  FormState,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../components/Select';
import {
  CreateJobDto,
  HiringMember,
} from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { JobRequisitionStatus } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionService } from '../../../../../../services/v1/applicant_tracking/JobRequisitionService';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { JobRequisitionList } from '../../../../../../entities/v1/applicant_tracking/JobRequisitionList';
import { SetupJobContext } from '../../index';

interface PropTypes {
  isEditFlow: boolean;
  formControl: Control<CreateJobDto>;
  formState: FormState<CreateJobDto>;
  formWatch: UseFormWatch<CreateJobDto>;
  formSetValue: UseFormSetValue<CreateJobDto>;
  formSetError: UseFormSetError<CreateJobDto>;
  setJobRequisition: (jr: JobRequisition) => void;
}

interface JobRequisitionSelectProps {
  disabled: boolean;
  options: SelectOption[];
}

const FORM_FIELD_NAME = 'jobRequisitionId';

export async function loadJobRequisitions(): Promise<JobRequisitionList> {
  return await JobRequisitionService.list({
    status: JobRequisitionStatus.Approved,
    jobCreationEligible: true,
    expand: [],
  });
}

function buildJobRequisitionSelectOption(
  jobRequisition: JobRequisition,
): SelectOption {
  return {
    label: jobRequisition.uid,
    value: jobRequisition.id.toString(),
  };
}

function buildJobRequisitionSelectProps(
  updateJobRequisitionEnabled: boolean,
  jobRequisitions: JobRequisition[],
): JobRequisitionSelectProps {
  return {
    disabled: !updateJobRequisitionEnabled,
    options: jobRequisitions.map((requisition) =>
      buildJobRequisitionSelectOption(requisition),
    ),
  };
}

function updateJobForm(
  formWatch: UseFormWatch<CreateJobDto>,
  formSetValue: UseFormSetValue<CreateJobDto>,
  jobRequisition?: JobRequisition,
) {
  if (!jobRequisition) {
    return;
  }

  formSetValue(FORM_FIELD_NAME, jobRequisition.id.toString());
  formSetValue('jobOpenings', jobRequisition.openings?.toString());
  formSetValue('departmentId', jobRequisition.departmentId.toString());

  if (!formWatch('locationId')) {
    formSetValue('locationId', jobRequisition.locationId.toString());
  }

  if (!formWatch('locationType')) {
    formSetValue('locationType', jobRequisition.locationType);
  }

  if (!formWatch('employmentType')) {
    formSetValue('employmentType', jobRequisition.employmentType);
  }

  if (!formWatch('hiringTeamRecruiterId')) {
    formSetValue(
      'hiringTeamRecruiterId',
      jobRequisition.ownerUserId.toString(),
    );
  }

  if (formWatch('hiringTeamMembers')?.length == 0) {
    const hiringTeamMembers: HiringMember[] = [
      {
        userId: jobRequisition.hiringManagerUserId.toString(),
        roleId: 'hiring_manager',
      },
    ];
    formSetValue('hiringTeamMembers', hiringTeamMembers);
  }
}

function selectedRequisitionNotAvailable(props: PropTypes) {
  props.formSetError(FORM_FIELD_NAME, {
    type: 'manual',
    message: 'There are no openings available in the selected requisition',
  });
}

function preSelectedJobRequisitionOption(jobRequisitionId: string) {
  return [
    {
      value: jobRequisitionId,
      label: `JREQ-${jobRequisitionId.toString().padStart(2, '0')}`,
    },
  ];
}

export function JobRequisitionInput(props: PropTypes) {
  const setupJobContext = useContext(SetupJobContext);

  if (!setupJobContext.jobRequisitionEnabled) {
    return null;
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobRequisitions, setJobRequisitions] = useState<JobRequisition[]>([]);

  useEffect(() => {
    (async () => {
      if (setupJobContext.updateJobRequisitionEnabled) {
        loadJobRequisitions()
          .then((list) => setJobRequisitions(list.jobRequisitions))
          .finally(() => setIsLoading(false));
      } else {
        setJobRequisitions([setupJobContext.jobRequisition].filter(Boolean));
      }

      setIsLoading(false);
    })();
  }, []);

  const jobRequisitionId = props.formWatch(FORM_FIELD_NAME);
  useEffect(() => {
    if (!jobRequisitionId || isLoading) {
      return;
    }

    const selectedJobRequisition = jobRequisitions.find(
      (requisition) =>
        requisition.id.toString() === jobRequisitionId.toString(),
    );

    if (
      !selectedJobRequisition &&
      !props.isEditFlow &&
      setupJobContext.jobRequisitionMandatory
    ) {
      return selectedRequisitionNotAvailable(props);
    }

    props.setJobRequisition(selectedJobRequisition);

    updateJobForm(props.formWatch, props.formSetValue, selectedJobRequisition);
  }, [jobRequisitionId, jobRequisitions]);

  const jobRequisitionSelectProps = buildJobRequisitionSelectProps(
    setupJobContext.updateJobRequisitionEnabled,
    jobRequisitions,
  );

  const error = props.formState.errors['jobRequisitionId']?.message;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Requisition ID'}
          placeholder={'Select job requisition'}
          formHookContext={{
            controllerName: FORM_FIELD_NAME,
            formControl: props.formControl,
            required: setupJobContext.jobRequisitionMandatory,
          }}
          options={
            props.isEditFlow
              ? preSelectedJobRequisitionOption(jobRequisitionId)
              : jobRequisitionSelectProps.options
          }
          danger={!!error}
          disabled={jobRequisitionSelectProps.disabled}
          isLoading={isLoading}
        />
      </Col>
      {error && <div className='text-danger-500 mt-2 fs-6'>{error}</div>}
    </Row>
  );
}
