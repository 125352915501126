import React from 'react';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../../../../components/InputFormField';
import classNames from 'classnames';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { PushToHrisFormValues } from '../../..';

interface PropTypes {
  register: UseFormRegister<PushToHrisFormValues>;
  errors: FieldErrors;
}

export function FirstAndLastNameRow(props: PropTypes) {
  return (
    <Row className='mb-3'>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('firstName', {
            required: true,
          })}
          fieldName={'First Name'}
          placeholder={'Insert first name'}
          isRequired={true}
          classNames={classNames(
            props.errors.firstName && 'is-invalid',
            'fs-5',
          )}
        />
      </Col>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('lastName', {
            required: true,
          })}
          fieldName={'Last Name'}
          placeholder={'Insert last name'}
          isRequired={true}
          classNames={classNames(props.errors.lastName && 'is-invalid', 'fs-5')}
        />
      </Col>
    </Row>
  );
}
