import { useEffect, useState } from 'react';
import React from 'react';
import { SelectOption } from '../../../../../../components/Select';
import { SignableOfferTemplate } from '../../../../../../entities/v1/applicant_tracking/SignableOfferTemplate';
import { AsyncSelect } from '../../../../../../components/Select/async';
import { SignableOfferTemplateService } from '../../../../../../services/v1/applicant_tracking/SignableOfferTemplateService';
import { OfferDocuments } from '../../OfferDocuments';

interface PropTypes {
  offerTemplate?: SignableOfferTemplate;
  setIsValid: (valid: boolean) => void;
  setOfferTemplate: (offerTemplate: SignableOfferTemplate) => void;
}

function selectedOption(
  loadedTemplates: SignableOfferTemplate[],
  selectedId: string,
): SelectOption {
  if (selectedId == null) {
    return null;
  }

  const selected = loadedTemplates?.find((i) => i.id.toString() === selectedId);

  if (selected == null) {
    return null;
  }

  return {
    label: selected.name,
    value: selected.id.toString(),
  };
}

export function OfferTemplate(props: PropTypes) {
  const [loadedTemplates, setLoadedTemplates] =
    useState<SignableOfferTemplate[]>(null);

  const loadTemplates = async (): Promise<SelectOption[]> => {
    const response = await SignableOfferTemplateService.list();
    setLoadedTemplates(response.signableOfferTemplates);

    return response.signableOfferTemplates.map((v) => {
      return {
        label: v.name,
        value: v.id.toString(),
      };
    });
  };

  const handleChangeSelected = async (selected: SelectOption) => {
    props.setOfferTemplate(
      await SignableOfferTemplateService.show(parseInt(selected?.value), [
        'signatories',
        'signatories.user',
        'signable_documents',
        'email_template',
      ]),
    );
  };

  useEffect(() => {
    props.setIsValid(props.offerTemplate != null);
  }, [props.offerTemplate]);

  return (
    <>
      <div className='d-flex flex-column gap-4 w-75'>
        <div className='d-flex align-items-center gap-2'>
          <label className='text-nowrap fw-semibold fs-5'>Offer Template</label>
          <AsyncSelect
            className='w-100'
            isLoading={loadedTemplates == null}
            isClearable
            placeholder='Select the offer template'
            onChange={handleChangeSelected}
            loadOptions={loadTemplates}
            selected={selectedOption(
              loadedTemplates,
              props.offerTemplate?.id?.toString(),
            )}
          />
        </div>
        <OfferDocuments
          signableDocuments={props.offerTemplate?.signableDocuments}
        />
      </div>
    </>
  );
}
