import React from 'react';
import { JobDetails } from '../../../../../../entities/JobDetails';
import { JobPublishPermissions } from '../../../../../../entities/v1/applicant_tracking/JobPublishPermissions';
import { BaseIconButton } from '../BaseIconButton';

interface PropTypes {
  jobDetails: JobDetails;
  actionName: string;
  isLoadingPermissions: boolean;
  jobPublishPermissions?: JobPublishPermissions;
  onPublish: () => void;
}

function validatePublish(jobPublishPermissions?: JobPublishPermissions) {
  if (jobPublishPermissions == null) return null;

  if (!jobPublishPermissions.currentRoleAllowed)
    return 'Your role does not have permission to publish this job';

  if (!jobPublishPermissions.currentStatusAllowed)
    return 'Archived jobs cannot be published';

  if (!jobPublishPermissions.mandatoryFieldsFilled)
    return 'Edit the job and fill in all mandatory fields before you can publish it';
}

export function PublishIconButton(props: PropTypes) {
  const actionError = validatePublish(props.jobPublishPermissions);
  const disabled = props.isLoadingPermissions || actionError != null;

  return (
    <BaseIconButton
      jobDetails={props.jobDetails}
      actionName={props.actionName}
      actionError={actionError}
      disabled={disabled}
      onClick={props.onPublish}
    />
  );
}
