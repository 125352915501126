import React, { useEffect, useState } from 'react';
import { QuestionAverage } from '../../../../../entities/candidate_experience/QuestionAverage';
import { QuestionAveragesPanel } from '../../../shared_panels/QuestionAveragesPanel';
import { FilterStates } from '../../../../../components/candidate_experience/Filters/filterState';
import { fromFilterState } from '../../../../../services/v1/candidate_experience/filterParams';
import { QuestionAveragesService } from '../../../../../services/v1/candidate_experience/QuestionAveragesService';
import { SelectOption } from '../../../../../components/Select';

interface PropTypes {
  filterStates: Partial<FilterStates>;
  benchmarkType: SelectOption;
}

export default function ProcessPanel(props: PropTypes) {
  const [data, setData] = useState<QuestionAverage[]>(null);

  useEffect(() => {
    setData(null);

    QuestionAveragesService.get(fromFilterState(props.filterStates))
      .then((analytics) => {
        if (analytics == null) return;

        setData(
          analytics.questionAverages?.map((questionAverage) => ({
            question_text: questionAverage.questionText,
            average: questionAverage.average,
            benchmark_average: questionAverage.benchmarkAverage,
            average_trend: questionAverage.averageTrend,
          })),
        );
      })
      .catch((e) => console.error('Could not get pulse question averages', e));
  }, [props.filterStates]);

  return (
    <QuestionAveragesPanel
      questionAverages={data}
      benchmarkType={props.filterStates.benchmarkType.selected}
      title='Process'
      isLazyLoading={true}
    />
  );
}
