import React from 'react';
import classNames from 'classnames';
import { RatingOptions } from '../../../../../InterviewPage/RatingModal/RatingOptions';
import { RateOption } from '../../../../../../entities/RateOption';
import styles from './styles.module.scss';
import { ChipColor } from '../../../../../../components/Chip';

interface PropTypes {
  rating?: string;
  readonly?: boolean;
  setRating?: (rating: string) => void;
}

export const RATING_OPTIONS = [
  {
    icon: 'bi-x-circle-fill',
    title: 'Definitely Not',
    value: 'definitely_no',
    color: 'red-500',
    chipColor: ChipColor.DangerLight,
  },
  {
    icon: 'bi-hand-thumbs-down-fill',
    title: 'No',
    value: 'no',
    color: 'red-500',
    chipColor: ChipColor.DangerLight,
  },
  {
    icon: 'bi-hand-thumbs-up-fill',
    title: 'Yes',
    value: 'yes',
    color: 'success-500',
    chipColor: ChipColor.SuccessLight,
  },
  {
    icon: 'bi-star-fill',
    title: 'Strong Yes',
    value: 'definitely_yes',
    color: 'success-500',
    chipColor: ChipColor.SuccessLight,
  },
];

export function ScorecardScore(props: PropTypes) {
  return (
    <>
      <div className='text-center fw-semibold mt-5h'>
        Overall Recommendation
        {!props.readonly && <span className='ps-1 text-danger'>*</span>}
      </div>
      <RatingOptions
        ratingOptionClass={classNames(
          styles['rating-option'],
          'bg-blue-gray-light',
          'text-dark-200',
        )}
        ratingOptions={RATING_OPTIONS}
        activeOption={RATING_OPTIONS.find(
          (option: RateOption) => option.value === props?.rating,
        )}
        readonly={props.readonly}
        setActiveOption={(option: RateOption) =>
          props.setRating?.(option.value)
        }
      />
    </>
  );
}
