import React, { useState } from 'react';
import { SignableOfferForm } from '..';
import { OfferDetails } from './OfferDetails';
import { HorizontalStepper } from '../../../../../components/HorizontalStepper';
import { OfferEmail } from './OfferEmail';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { OfferSummary } from './OfferSummary';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { OfferTemplate } from './OfferTemplate';
import { SignableOfferTemplate } from '../../../../../entities/v1/applicant_tracking/SignableOfferTemplate';

interface PropTypes {
  applicationId: number;
  elementIdentifier: string;
  form: SignableOfferForm;
  isLoading: boolean;
  step: number;
  to: string;
  files: File[];
  clearUploadedFiles: () => void;
  removeAttachmentFileByIndex: (index: number) => void;
  setForm: (form: SignableOfferForm) => void;
  setValidStep: (valid: boolean) => void;
}

const STEPPER_ITEMS = [
  {
    title: 'Offer Template',
    subtitle: 'Select Offer Template',
  },
  {
    title: 'Offer Details',
    subtitle: 'Add Offer Details',
  },
  {
    title: 'Offer Email',
    subtitle: 'Review Offer Email',
  },
];

function BodySelector(props: PropTypes) {
  if (props.isLoading) {
    return <LoadingSpinner />;
  }

  const [variables, setVariables] = useState<string[]>(null);

  switch (props.step) {
    case 0:
      return (
        <OfferTemplate
          setIsValid={props.setValidStep}
          offerTemplate={props.form.signableOfferTemplate}
          setOfferTemplate={(offerTemplate: SignableOfferTemplate) =>
            props.setForm({
              ...props.form,
              to: props.to,
              signableOfferTemplate: offerTemplate,
            })
          }
        />
      );
    case 1:
      return (
        <OfferDetails
          applicationId={props.applicationId}
          form={props.form}
          offerTemplate={props.form.signableOfferTemplate}
          variables={variables}
          setForm={props.setForm}
          setIsValid={props.setValidStep}
          setVariables={setVariables}
        />
      );
    case 2:
      return (
        <OfferEmail
          applicationIds={[props.applicationId]}
          form={props.form}
          files={props.files}
          clearUploadedFiles={props.clearUploadedFiles}
          removeAttachmentFileByIndex={props.removeAttachmentFileByIndex}
          setForm={props.setForm}
          setIsValid={props.setValidStep}
        />
      );
  }
}

export function BodyWrapper(props: PropTypes) {
  return props.step < STEPPER_ITEMS.length ? (
    <>
      <HorizontalStepper
        displayInModal
        items={STEPPER_ITEMS}
        activeIndex={props.step}
      />
      <div
        id={props.elementIdentifier}
        className={classNames(
          styles['modal-body'],
          'px-4',
          'mb-3',
          'overflow-auto',
        )}
      >
        <BodySelector {...props} />
      </div>
    </>
  ) : (
    <OfferSummary
      applicationId={props.applicationId}
      className={styles['modal-body']}
      form={props.form}
      signatories={props.form.signableOfferTemplate.signatories}
      signableDocuments={props.form.signableOfferTemplate.signableDocuments}
      isLoading={props.isLoading}
      emailHtml={props.form.email}
      emailAttachments={props.form.attachments}
    />
  );
}
