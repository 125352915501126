import React, { useState } from 'react';
import { Dropdown } from '../../../../../components/Dropdown';
import { IconSpan } from '../../../../../components/IconSpan';
import { JobHiringMemberRole } from '../../../../../entities/JobHiringMemberRole';
import { User } from '../../../../../entities/User';
import { buildSchedulingActionInfo } from '../../../../../utils/applicant_tracking/actions/schedulingActions';
import { PropTypes as ActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { ScheduleInterviewModal } from '../../../../../components/ApplicantTracking/ScheduleInterviewModal';
import {
  allowedJobRole,
  ApplicationActionInfo,
} from '../../../../../utils/applicant_tracking/actions/applicationActions';
import {
  PipelineApplication,
  PipelineApplicationResume,
} from '../../../../../entities/applicant_tracking/PipelineApplication';
import { renderPushToHris } from '../../../../CandidatePage/CandidateActions/DropdownActions';
import { PushToHrisModal } from '../../../../CandidatePage/CandidateActions/PushToHrisModal';
import { AlertObject } from '../../../../../components/Alert';
import { RedactedResumeService } from '../../../../../services/v1/applicant_tracking/RedactedResumeService';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { appendQueryParam } from '../../../../../utils/url';
import { SendOfferSwitch } from '../../../../applicant_tracking/SendOfferSwitch';
import { EditablePushToHrisModal } from '../../../../CandidatePage/CandidateActions/EditablePushToHrisModal';
import { useFeatureFlags } from '../../../../../contexts/FeatureFlagContext';

interface PropTypes {
  application: PipelineApplication;
  atsSignableOfferEnabled: boolean;
  authorizeUrl: string;
  currentUser: User;
  jobHiringMemberRole?: JobHiringMemberRole;
  moveCandidateToStage: () => void;
  moveToStageActionInfo: ApplicationActionInfo;
  offerEnabled: boolean;
  onRejectApplication: (application: PipelineApplication) => void;
  profileLink: string;
  setAlert: (alert: AlertObject) => void;
  setDragEnabled: (b: boolean) => void;
}

function viewCandidate(link: string) {
  return {
    action: () => window.open(link, '_blank', 'noopener,noreferrer'),
    buttonChild: (
      <IconSpan
        spanText={'View Candidate'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function moveToStage(moveCandidateToStage: () => void) {
  return {
    action: moveCandidateToStage,
    buttonChild: (
      <IconSpan
        spanText={'Move to Stage'}
        icon={{ name: 'bi-arrow-return-right', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function scheduleInterview(
  setIsScheduleInterviewModalOpen: (isOpen: boolean) => void,
  setDragEnabled: (b: boolean) => void,
) {
  return {
    action: () => {
      setIsScheduleInterviewModalOpen(true);
      setDragEnabled(false);
    },
    buttonChild: (
      <IconSpan
        spanText={'Schedule Interview'}
        icon={{ name: 'bi-calendar4-week', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function viewCV(
  setIsLoading: (isLoading: boolean) => void,
  redacted: boolean,
  resume: PipelineApplicationResume,
) {
  function handleClick() {
    setIsLoading(true);

    RedactedResumeService.show(resume.id).then((resume) => {
      window.open(resume.url, '_blank');
      setIsLoading(false);
    });
  }

  return {
    action: () =>
      redacted ? handleClick() : window.open(resume.url, '_blank'),
    buttonChild: (
      <IconSpan
        spanText={'View CV'}
        icon={{ name: 'bi-box-arrow-up-right', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function viewInterviews(profileLink: string) {
  const link = appendQueryParam(profileLink, 'tab', 'interviews');

  return {
    action: () => window.open(link, '_blank', 'noopener,noreferrer'),
    buttonChild: (
      <IconSpan
        spanText={'View Interviews'}
        icon={{ name: 'bi-collection-play', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function sendOffer(
  setIsOfferModalOpen: (isOpen: boolean) => void,
  setDragEnabled: (b: boolean) => void,
) {
  return {
    action: () => {
      setIsOfferModalOpen(true);
      setDragEnabled(false);
    },
    buttonChild: (
      <IconSpan
        spanText={'Send Offer'}
        icon={{ name: 'bi-file-earmark-medical', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function rejectCandidate(
  application: PipelineApplication,
  rejectApplication: (application: PipelineApplication) => void,
) {
  return {
    action: () => rejectApplication(application),
    buttonChild: (
      <IconSpan
        spanText={'Reject Candidate'}
        icon={{ name: 'bi-file-x', className: 'fs-4', topStyle: '2px' }}
        className={'text-blue'}
      />
    ),
  };
}

function buildMenuActions(
  props: PropTypes,
  setIsLoading: (b: boolean) => void,
  setIsScheduleInterviewModalOpen: (b: boolean) => void,
  setIsPushToHrisModalOpen: (b: boolean) => void,
  setIsOfferModalOpen: (b: boolean) => void,
  onRejectApplication: (application: PipelineApplication) => void,
): ActionPropTypes[] {
  const menuActions: ActionPropTypes[] = [viewCandidate(props.profileLink)];

  if (!props.application.redacted) {
    menuActions.push(viewInterviews(props.profileLink));
  }

  if (
    props.moveToStageActionInfo.allowed &&
    !props.moveToStageActionInfo.disabled
  ) {
    menuActions.push(moveToStage(props.moveCandidateToStage));
  }

  if (props.application.resume != null) {
    menuActions.unshift(
      viewCV(
        setIsLoading,
        props.application.redacted,
        props.application.resume,
      ),
    );
  }

  const schedulingPermission = buildSchedulingActionInfo(
    props.application,
    props.currentUser,
    props.jobHiringMemberRole,
  );

  if (
    schedulingPermission.allowed &&
    !schedulingPermission.disabled &&
    !props.application.redacted
  ) {
    menuActions.push(
      scheduleInterview(setIsScheduleInterviewModalOpen, props.setDragEnabled),
    );
  }

  const pushToHrisAction = renderPushToHris(
    () => {
      setIsPushToHrisModalOpen(true);
      props.setDragEnabled(false);
    },
    props.application.redacted,
    props.application.status,
    props.application.hrisEmployee?.externalUrl,
    props.application.allowedHrisProvider,
  );
  if (pushToHrisAction) {
    menuActions.push(pushToHrisAction);
  }

  if (
    props.offerEnabled &&
    props.application.sendOfferPermitted == true &&
    !props.application.redacted
  ) {
    menuActions.push(sendOffer(setIsOfferModalOpen, props.setDragEnabled));
  }

  if (props.currentUser.isAdmin || allowedJobRole(props.jobHiringMemberRole)) {
    menuActions.push(rejectCandidate(props.application, onRejectApplication));
  }

  return menuActions;
}

export function DropdownCandidate(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPushToHrisModalOpen, setIsPushToHrisModalOpen] =
    useState<boolean>(false);

  const [isScheduleInterviewModalOpen, setIsScheduleInterviewModalOpen] =
    useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const menuActions = buildMenuActions(
    props,
    setIsLoading,
    setIsScheduleInterviewModalOpen,
    setIsPushToHrisModalOpen,
    setIsOfferModalOpen,
    props.onRejectApplication,
  );

  const enableDrag = () =>
    props.setDragEnabled(props.moveToStageActionInfo.allowed);

  const onCloseScheduleInterviewModal = async () => {
    setIsScheduleInterviewModalOpen(false);
    enableDrag();
  };

  const onClosePushToHrisModal = async () => {
    setIsPushToHrisModalOpen(false);
    enableDrag();
  };

  const onCloseOfferModal = async () => {
    setIsOfferModalOpen(false);
    enableDrag();
  };

  if (isLoading) {
    return (
      <LoadingSpinner
        showBackdrop
        message='Redacting candidate data from the CV...'
      />
    );
  }

  const { atsEditBeforeExportToHrisEnabled } = useFeatureFlags();

  return (
    <>
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'ms-2 fs-5 text-blue',
        }}
        buttonClassName={'px-2 py-0'}
        menuActions={menuActions}
      />
      <ScheduleInterviewModal
        isOpen={isScheduleInterviewModalOpen}
        applicationId={props.application.id}
        interviewStageId={props.application.nextInterviewStageId}
        onClose={onCloseScheduleInterviewModal}
      />
      {atsEditBeforeExportToHrisEnabled ? (
        <EditablePushToHrisModal
          applicationIds={[props.application.id]}
          applicationId={props.application.id}
          allowedHrisProvider={props.application.allowedHrisProvider}
          isOpen={isPushToHrisModalOpen}
          onClose={onClosePushToHrisModal}
          setAlert={props.setAlert}
        />
      ) : (
        <PushToHrisModal
          applicationIds={[props.application.id]}
          allowedHrisProvider={props.application.allowedHrisProvider}
          isOpen={isPushToHrisModalOpen}
          onClose={onClosePushToHrisModal}
          setAlert={props.setAlert}
          candidateName={props.application.candidate.name}
          jobName={props.application.job.name}
        />
      )}
      <SendOfferSwitch
        atsSignableOfferEnabled={props.atsSignableOfferEnabled}
        applicationId={props.application.id}
        hasEmailConnected={props.currentUser.hasEmailConnected}
        isOpen={isOfferModalOpen}
        to={props.application.candidate.email}
        onClose={onCloseOfferModal}
        setAlert={props.setAlert}
      />
    </>
  );
}
