import { SurveyRequestInfo } from '../entities/SurveyRequestInfo';

export const getSelfAssessmentSurvey = (
  surveyRequestInfo: SurveyRequestInfo,
) => {
  const referralInfo = surveyRequestInfo?.referrals?.find(
    (r) => r.relationship === 'candidate',
  );

  if (referralInfo == null) return;

  return referralInfo.surveys.length > 0 ? referralInfo.surveys[0] : null;
};
