import React from 'react';
import { InfoTooltip } from '../../../components/InfoTooltip';
import { ActivityButton } from '../../CandidatePage/ActivityContent/ActivityButton';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { NoRecordingReason } from '../../../entities/v1/interview_intelligence/NoRecordingReason';
interface PropTypes {
  noRecordingReasons: NoRecordingReason[];
}

export default function InterviewNoRecordingReasonInfo(props: PropTypes) {
  return (
    <InfoTooltip
      colorClassName='text-primary'
      className='d-flex ms-2'
      spanClassName='d-flex'
      tooltipInnerClassName='bg-white p-3'
      popperClassName='top-and-bottom-light-tooltip'
      placement='top'
      autoHide={false}
    >
      <div className='fw-semibold fs-5 text-primary'>Not Recorded</div>
      {props.noRecordingReasons.map((reason, index) => (
        <div className='text-primary d-flex' key={index}>
          <span
            className={classNames(
              'me-2 bg-yellow',
              styles['tooltip-custom-bullet-point'],
            )}
          />

          <div>
            <div className='fs-5 fw-normal mt-2 pt-1'>{reason.message}</div>
            {reason.helpUrl && (
              <ActivityButton
                url={reason.helpUrl}
                className='fs-6 ps-0 fw-normal'
                text={`View Documentation`}
                icon='bi-box-arrow-up-right'
                openInNewTab={true}
              />
            )}
          </div>
        </div>
      ))}
    </InfoTooltip>
  );
}
