import React from 'react';
import { JobDetails } from '../../../../../../entities/JobDetails';
import { IconButton } from '../../../../../../components/IconButton';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';

interface PropTypes {
  jobDetails: JobDetails;
  actionName: string;
  actionError?: string;
  disabled: boolean;
  onClick: () => void;
}

export function BaseIconButton(props: PropTypes) {
  const iconButton = (
    <IconButton
      color={props.jobDetails.status === 'published' ? 'secondary' : 'success'}
      onClick={props.onClick}
      disabled={props.disabled}
      iconName={
        props.jobDetails.status === 'published'
          ? 'bi-cloud-slash'
          : 'bi-cloud-arrow-up'
      }
      buttonClass='me-3'
      iconClass='fs-4'
      buttonText={props.actionName}
    />
  );

  if (!props.disabled) {
    return iconButton;
  }

  return (
    <SpanTooltip
      text={iconButton}
      tooltipText={props.actionError}
      placement='top'
      tooltipStyle={{ maxWidth: '250px' }}
    />
  );
}
