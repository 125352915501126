import React from 'react';
import { IconButton } from '../../../components/IconButton';
import { appendRedirectUri } from '../../../utils/url';
import { LinkButton } from '../../../components/LinkButton';
import { IconSpan } from '../../../components/IconSpan';

interface PropTypes {
  atsEnabled: boolean;
  applicationId: string | number;
  interviewRatingEnabled: boolean;
  scorecardUrl: string;
  onRatingClick: () => void;
}

export function InterviewRatingButton(props: PropTypes) {
  return (
    <>
      {props.atsEnabled ? (
        <LinkButton
          color='borderless'
          link={{
            href: appendRedirectUri(
              props.scorecardUrl ??
                `/applications/${props.applicationId}?tab=feedback`,
            ),
          }}
        >
          <IconSpan
            icon={{ name: 'bi-clipboard-check' }}
            spanText='View Scorecard'
          />
        </LinkButton>
      ) : (
        <IconButton
          buttonText='Rate Interview'
          disabled={!props.interviewRatingEnabled}
          color='borderless'
          onClick={props.onRatingClick}
          iconName={'bi-star'}
        />
      )}
    </>
  );
}
