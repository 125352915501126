import React, { useEffect, useState } from 'react';
import { Application } from '../../../entities/Application';
import { User } from '../../../entities/User';
import { PreviousCandidateActions } from './PreviousCandidateActions';
import { DropdownActions } from './DropdownActions';
import { MoveCandidateToStageButton } from './MoveCandidateToStageButton';
import { ApplicationSelection } from '../ApplicationSelection';
import { CandidateTab } from '../CandidateTab';
import { SpanTooltip } from '../../../components/SpanTooltip';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { getApplicationActionInfo } from '../../../utils/applicant_tracking/actions/applicationActions';
import { ScheduleInterviewAction } from './ScheduleInterviewAction';
import { ActionButton } from './ActionButton';
import { SendEmailModal } from '../../SendEmailModal';
import { AccountIntegration } from '../../../entities/AccountIntegration';
import { AlertObject, AlertType } from '../../../components/Alert';
import { SendEmailDto } from '../../../services/dtos/EmailDto';
import { ApplicationService as ApplicantTrackingApplicationService } from '../../../services/applicant_tracking/ApplicationService';
import consumer from '../../../channels/consumer';
import _ from 'lodash';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { appendRedirectUri } from '../../../utils/url';

interface PropTypes {
  application: Application;
  atsSignableOfferEnabled: boolean;
  applicantTrackingEnabled: boolean;
  currentTab: CandidateTab;
  interviewIntelligenceEnabled: boolean;
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  emailAccountIntegration: AccountIntegration;
  newApplicationPermitted: boolean;
  sendOfferPermitted: boolean;
  onMoveCandidateStage: (status: string) => void;
  setAlert: (alert: AlertObject) => void;
  setOpenSharePulseSurveyModal: (flag: boolean) => void;
  reloadApplication: () => void;
}

export function CandidateActions(props: PropTypes) {
  useEffect(() => {
    const dbReloadApplication = _.debounce(props.reloadApplication, 500);
    const sub = consumer.subscriptions.create(
      {
        channel: 'ApplicantTracking::ApplicationsChannel',
        id: props.application.id,
      },
      {
        received() {
          dbReloadApplication();
        },
      },
    );

    return () => consumer.subscriptions.remove(sub);
  }, []);

  if (!props.applicantTrackingEnabled) {
    return <PreviousCandidateActions {...props} />;
  }

  const baseApplicationActionInfo = getApplicationActionInfo(
    props.application,
    props.currentUser.isAdmin,
    props.jobHiringMemberRole,
    'Move to Stage',
  );

  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);

  const onDropdownAction = (succeeded: boolean) => {
    if (succeeded) {
      props.reloadApplication();
    }
  };

  const onSendEmail = async (sendEmailProps: SendEmailDto) => {
    await ApplicantTrackingApplicationService.sendEmails(
      [props.application.id],
      sendEmailProps,
    )
      .then(() => {
        props.setAlert({
          message: 'This email was successfully sent.',
          type: AlertType.Success,
        });

        props.reloadApplication();
      })
      .catch((e: ApiError) => {
        props.setAlert({
          message: e.body?.message || 'The email was not sent.',
          type: AlertType.Danger,
        });
      })
      .finally(() => setIsEmailModalOpen(false));
  };

  return (
    <div className='d-flex gap-2 text-end'>
      {props.application.candidate.applications?.length > 1 && (
        <ApplicationSelection
          currentTab={props.currentTab}
          application={props.application}
        />
      )}
      {baseApplicationActionInfo.allowed && !props.application.redacted && (
        <SpanTooltip
          text={
            <ActionButton
              iconName={'bi-pencil'}
              onClick={() =>
                (window.location.href = appendRedirectUri(
                  `/applicant_tracking/applications/${props.application.id}/edit`,
                  window.location.href,
                  true,
                ))
              }
            />
          }
          tooltipText='Edit Job Application'
        />
      )}
      <ScheduleInterviewAction
        application={props.application}
        currentUser={props.currentUser}
        jobHiringMemberRole={props.jobHiringMemberRole}
      />
      {baseApplicationActionInfo.allowed && (
        <SpanTooltip
          text={
            <MoveCandidateToStageButton
              application={props.application}
              disabled={baseApplicationActionInfo.disabled}
              onMoveCandidateStage={props.onMoveCandidateStage}
              reloadApplication={props.reloadApplication}
              setAlert={props.setAlert}
            />
          }
          tooltipText={baseApplicationActionInfo.tooltipText}
          trigger='hover'
        />
      )}
      {props.emailAccountIntegration.enabled && !props.application.redacted && (
        <SpanTooltip
          text={
            <ActionButton
              iconName='bi-envelope'
              onClick={() => setIsEmailModalOpen(true)}
              disabled={false}
            />
          }
          tooltipText='Send Email'
        />
      )}
      {isEmailModalOpen && (
        <SendEmailModal
          applicationIds={[props.application.id]}
          toEmail={props.application.candidate.email}
          hasEmailConnected={props.currentUser.hasEmailConnected}
          isOpen={isEmailModalOpen}
          onCloseModal={() => setIsEmailModalOpen(false)}
          onSendEmail={onSendEmail}
        />
      )}
      <DropdownActions
        application={props.application}
        atsSignableOfferEnabled={props.atsSignableOfferEnabled}
        disabled={
          !baseApplicationActionInfo.allowed ||
          baseApplicationActionInfo.disabled
        }
        user={props.currentUser}
        sendEmailEnabled={props.emailAccountIntegration.enabled}
        newApplicationPermitted={props.newApplicationPermitted}
        sendOfferPermitted={props.sendOfferPermitted}
        reloadApplication={props.reloadApplication}
        onClose={onDropdownAction}
        onShareCNPSSurvey={props.setOpenSharePulseSurveyModal}
        setAlert={props.setAlert}
        setIsEmailModalOpen={setIsEmailModalOpen}
      />
    </div>
  );
}
