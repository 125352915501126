import React from 'react';
import { FormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import {
  SetupHeaderButtonType,
  SetupPageHeader,
} from '../../../../../../components/SetupPageHeader';
import { replaceFinalSegment } from '../../../../../../utils/url';
import { useStateMachine } from 'little-state-machine';
import { JobDetails } from '../../../../../../services/applicant_tracking/JobService/EditJobDto';

interface PropTypes {
  setupStep: number;
  formState: FormState<CreateJobDto>;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  indeedFormValid: boolean;
  organicPostValid: boolean;
  organicEnabled: boolean;
  indeedEnabled: boolean;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  setWarningModal: (view: boolean) => void;
  onCancel: () => void;
  onSave: () => void;
  onSubmit: () => void;
  onNavigate: () => void;
}

function saveAction(
  organicEnabled: boolean,
  indeedEnabled: boolean,
  jobPublished: boolean,
  onSave: () => void,
  onSubmit: () => void,
  setWarningModal: (flag: boolean) => void,
): React.MouseEventHandler<HTMLButtonElement> {
  if (jobPublished) {
    return onSubmit;
  }

  if (!organicEnabled && !indeedEnabled) {
    return onSave;
  }

  return () => setWarningModal(true);
}

export function JobOrganicPostsHeader(props: PropTypes) {
  const navigate = useNavigate();
  const { state } = useStateMachine();
  const jobPublished = props.jobDetails?.status === 'published';

  const lastItem =
    (!jobPublished || !props.isEditFlow) &&
    ({
      title: 'Publish Job',
      type: 'Save',
      disabled:
        Object.keys(props.formState.errors).length > 0 ||
        !props.organicPostValid ||
        !props.indeedFormValid,
      action: props.onSubmit,
    } as SetupHeaderButtonType);

  return (
    <SetupPageHeader
      title='Free Job Boards'
      subTitle={
        <span className='fs-5 text-dark-200'>
          Expand your reach at no cost. Fill all job details to maximize
          visibility.
        </span>
      }
      titleClassNames='mb-3'
      firstItems={[
        {
          title: 'Previous',
          type: 'Navigate',
          action: () => {
            props.onNavigate();
            props.setSetupStep(props.setupStep - 1, state.setupJob);
            navigate(replaceFinalSegment('job_workflow'));
          },
          disabled: Object.keys(props.formState.errors).length > 0,
        },
      ]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: jobPublished ? 'Save and Publish' : 'Save and Close',
          type:
            !jobPublished && (props.organicEnabled || props.indeedEnabled)
              ? 'OpenConfirmationModal'
              : 'Save',
          disabled:
            Object.keys(props.formState.errors).length > 0 ||
            !props.organicPostValid ||
            !props.indeedFormValid,
          action: saveAction(
            props.organicEnabled,
            props.indeedEnabled,
            jobPublished,
            props.onSave,
            props.onSubmit,
            props.setWarningModal,
          ),
          tooltip:
            props.organicPostValid && props.indeedFormValid
              ? undefined
              : 'If posting to free job boards is enabled, all the mandatory fields on the job posting details card are required to proceed',
        },
      ]}
      lastItem={lastItem || null}
    />
  );
}
