import React, { useEffect, useState } from 'react';
import { ScorecardBlock } from './ScorecardBlock';
import { getQueryParam } from '../../../../../utils/url';
import { User } from '../../../../../entities/User';
import {
  ListScorecard,
  Scorecard,
} from '../../../../../entities/applicant_tracking/Scorecard';
import { FeedbackFilters } from '../../Filters';
import { ScorecardService } from '../../../../../services/v1/applicant_tracking/ScorecardService';
import { mapSelectedIds } from '..';
import { EmptyFeedback } from '../EmptyStates/EmptyFeedback';

interface PropTypes {
  applicationId: number;
  currentUser: User;
  isLoading: boolean;
  filters: FeedbackFilters;
  viewAllScorecards: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

function showScorecardId(): number {
  return parseInt(getQueryParam('show_scorecard_id'));
}

function bringOpenedScorecardToTop(scorecards: Scorecard[]): Scorecard[] {
  const openedScorecardId = showScorecardId();

  if (!openedScorecardId) return scorecards;

  const indexOfOpenScorecard =
    scorecards?.findIndex((scorecard) => scorecard.id === openedScorecardId) ||
    -1;

  if (indexOfOpenScorecard !== -1) {
    const elementToMove = scorecards.splice(indexOfOpenScorecard, 1)[0];
    scorecards.unshift(elementToMove);
  }

  return scorecards;
}

function statusSorting(a: Scorecard, b: Scorecard): number {
  if (a.survey?.status === 'completed' && b.survey?.status === 'pending') {
    return -1; // a comes before b
  } else if (
    a.survey?.status === 'pending' &&
    b.survey?.status === 'completed'
  ) {
    return 1; // b comes before a
  } else {
    return 0; // order remains the same
  }
}

async function loadScorecards(applicationId: number, filters: FeedbackFilters) {
  return await ScorecardService.list({
    applicationId: applicationId,
    status: filters?.status,
    jobStageIds: mapSelectedIds(filters?.jobStages || []),
    hiringMemberIds: mapSelectedIds(filters?.hiringMembers || []),
    expand: [
      'user',
      'job_stage',
      'survey',
      'survey.sections',
      'survey.sections.questions',
      'survey.answers',
    ],
  });
}

async function upsertApplicationScorecards(applicationId: number) {
  try {
    return await ScorecardService.batchCreate({
      applicationId: applicationId,
    });
  } catch (e) {
    if (e.status != 404) {
      throw e;
    }
  }
}

export function ScorecardListPanel(props: PropTypes) {
  const [listScorecards, setListScorecards] = useState<ListScorecard>(null);
  const [isUpsertDone, setIsUpsertDone] = useState(false);

  useEffect(() => {
    upsertApplicationScorecards(props.applicationId).finally(() =>
      setIsUpsertDone(true),
    );
  }, []);

  useEffect(() => {
    props.setIsLoading(true);
    if (!isUpsertDone) return;

    loadScorecards(props.applicationId, props.filters)
      .then((scorecards) => setListScorecards(scorecards))
      .catch((e) =>
        console.error(
          `Could not load scorecards for application ${props.applicationId} with filters ${props.filters}`,
          e,
        ),
      )
      .finally(() => props.setIsLoading(false));
  }, [props.filters, isUpsertDone]);

  const sortedScorecards = bringOpenedScorecardToTop(
    listScorecards?.scorecards?.slice().sort(statusSorting) || [],
  );

  if (props.isLoading) return null;

  return (
    <>
      {!listScorecards || listScorecards?.entriesCount === 0 ? (
        <EmptyFeedback filters={props.filters} />
      ) : (
        <>
          {sortedScorecards.map((scorecard, index) => (
            <ScorecardBlock
              key={index}
              scorecard={scorecard}
              opened={scorecard.id === showScorecardId()}
              currentUser={props.currentUser}
              viewAllScorecards={props.viewAllScorecards}
            />
          ))}
          <div className='fs-5 text-dark-200'>
            Showing 1 - {sortedScorecards.length} of {sortedScorecards.length}{' '}
            Scorecards
          </div>
        </>
      )}
    </>
  );
}
