import React, { useEffect, useState } from 'react';
import { InterviewClip } from '../../../entities/InterviewClip';
import { Interview } from '../../../entities/Interview';
import { EmptyTab } from '../../../components/EmptyTab';
import { CreateInterviewClipButton } from './CreateInterviewClipButton';
import { InterviewClipForm, UpsertInterviewClip } from './InterviewClipForm';
import { InterviewClipList } from './InterviewClipList';
import { InterviewClipService } from '../../../services/InterviewClipService';
import classNames from 'classnames';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Alert, AlertType } from '../../../components/Alert';
import styles from './styles.module.scss';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { useTranscript } from '../../../queries/v1/interview_intelligence/useTranscript';
import { Transcript } from '../../../entities/v1/interview_intelligence/Transcript';

interface PropTypes {
  interview: Interview;
  formVisible: boolean;
  clipRange: number[];
  onClipRangeChanged: (range: number[]) => void;
  onSelectClip: (clip: InterviewClip) => void;
  onFormVisibilityChanged: (visible: boolean) => void;
  onInterviewClipsUpdate: (clips: InterviewClip[]) => void;
}

interface InterviewClipsTabUIPropTypes {
  interview: Interview;
  transcript?: Transcript;
  formVisible: boolean;
  clipRange: number[];
  onClipRangeChanged: (range: number[]) => void;
  onSelectClip: (clip: InterviewClip) => void;
  onFormVisibilityChanged: (visible: boolean) => void;
  onInterviewClipsUpdate: (clips: InterviewClip[]) => void;
}

function EmptyState(props: { onClick: () => void }) {
  return (
    <div className='flex-column'>
      <EmptyTab
        title='Create Interview Clips'
        text='Start creating interview clips to easily share or view them later.'
      />
      <div className='pt-2 text-center'>
        <CreateInterviewClipButton color='primary' onClick={props.onClick} />
      </div>
    </div>
  );
}

function ClipAlert(props: {
  alertType: AlertType;
  successElement: React.ReactNode;
  onClose: () => void;
}) {
  if (props.alertType == null) {
    return null;
  }

  return (
    <Alert
      type={props.alertType}
      clearable={true}
      autoClearTimeout={4000}
      onClose={props.onClose}
    >
      {props.alertType == AlertType.Success ? (
        props.successElement
      ) : (
        <span>
          An error occurred please contact support at{' '}
          <a className='link-info' href='mailto:support@screenloop.com'>
            support@screenloop.com
          </a>
        </span>
      )}
    </Alert>
  );
}

function DeleteModal(props: {
  isOpen: boolean;
  clip?: InterviewClip | UpsertInterviewClip;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <ConfirmationModal
      title='Delete Clip'
      body={
        <div>
          <p>Are you sure that you want to delete the following clip?</p>
          <p>
            <b>{props.clip?.title}</b>
          </p>
        </div>
      }
      isOpen={props.isOpen}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      size={'lg'}
      confirmText='Delete'
      disableAfterConfirm={false}
    />
  );
}

export function InterviewClipsTab(props: PropTypes) {
  const { data: transcript } = useTranscript(props.interview.id);

  return <InterviewClipsTabUI {...props} transcript={transcript} />;
}

export function InterviewClipsTabUI(props: InterviewClipsTabUIPropTypes) {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLinkClipId, setLoadingLinkClipId] = useState<string>();
  const [interviewClips, setInterviewClips] = useState<InterviewClip[]>([]);
  const [editingClip, setEditingClip] = useState<InterviewClip>();
  const [clipConfirm, setClipConfirm] = useState<UpsertInterviewClip>();
  const [alert, setAlert] = useState<{
    alertType: AlertType;
    successElement?: React.ReactNode;
  }>();

  useEffect(() => {
    fetchInterviewClips();
  }, []);

  const fetchInterviewClips = async () => {
    const clips = await InterviewClipService.list(props.interview.id);
    setInterviewClips(clips);
    setLoading(false);
    props.onInterviewClipsUpdate(clips);
  };

  const performInterviewClipOperation = async (
    operation: () => Promise<React.ReactNode>,
    fullLoading = true,
  ) => {
    fullLoading && setLoading(true);

    try {
      const successElement = await operation();
      await fetchInterviewClips();

      setAlert({
        alertType: AlertType.Success,
        successElement: successElement,
      });
    } catch (error) {
      setAlert({ alertType: AlertType.Danger });
      fullLoading && setLoading(false);
    }
  };

  const submit = async (clip: UpsertInterviewClip) => {
    props.onFormVisibilityChanged(false);
    await performInterviewClipOperation(async () => {
      if (!clip.id) {
        await InterviewClipService.create(props.interview.id, clip);
      } else {
        await InterviewClipService.update(
          props.interview.id,
          clip.id.toString(),
          clip,
        );
      }

      return (
        <span>
          The <b>{clip.title}</b> clip has been successfully{' '}
          {clip.id ? 'updated' : 'created'}.
        </span>
      );
    });
    setEditingClip(null);
  };

  const handleDeleteConfirm = async (clip: InterviewClip) => {
    setClipConfirm(clip);
  };

  const handleDeleteConfirmed = async (clip: UpsertInterviewClip) => {
    await performInterviewClipOperation(async () => {
      await InterviewClipService.delete(props.interview.id, clip.id);

      return (
        <span>
          The <b>{clip.title}</b> clip was successfully deleted.
        </span>
      );
    });
  };

  const handleEdit = (clip: InterviewClip) => {
    props.onFormVisibilityChanged(true);
    props.onClipRangeChanged([clip.startOffsetMs, clip.endOffsetMs]);
    setInterviewClips(
      [editingClip, ...interviewClips].filter(
        (c) => c != null && c.id !== clip.id,
      ),
    );
    setEditingClip(clip);
  };

  const handleCopyLink = async (clip: InterviewClip) => {
    setLoadingLinkClipId(clip.id);
    await performInterviewClipOperation(async () => {
      const link = await InterviewClipService.generateLink(
        props.interview.id.toString(),
        clip.id,
      );
      navigator.clipboard.writeText(link.url);

      return (
        <span>The link has been successfully copied to your clipboard.</span>
      );
    }, false);
    setLoadingLinkClipId(null);
  };

  return (
    <>
      <DeleteModal
        clip={clipConfirm}
        isOpen={clipConfirm != null}
        onConfirm={() => {
          handleDeleteConfirmed(clipConfirm);
          setClipConfirm(null);
          setEditingClip(null);
        }}
        onCancel={() => setClipConfirm(null)}
      />
      <ClipAlert
        alertType={alert?.alertType}
        successElement={alert?.successElement}
        onClose={() => setAlert(null)}
      />
      {interviewClips.length === 0 && !props.formVisible && !loading ? (
        <EmptyState onClick={() => props.onFormVisibilityChanged(true)} />
      ) : (
        <div className='mt-3'>
          {props.formVisible && (
            <>
              <InterviewClipForm
                clip={editingClip}
                maxOffset={props.interview.media.duration}
                clipRange={props.clipRange}
                monologues={props.transcript.monologues}
                onCancel={() => {
                  props.onFormVisibilityChanged(false);
                  !!editingClip &&
                    setInterviewClips([editingClip, ...interviewClips]);
                  setEditingClip(null);
                }}
                onSubmit={(clip) => submit(clip)}
                onClipRangeChanged={props.onClipRangeChanged}
              />
              {interviewClips.length !== 0 && (
                <hr
                  className='w-100 my-3h bg-gray-300 '
                  style={{ position: 'absolute', marginLeft: '-24px' }}
                />
              )}
            </>
          )}
          <div
            className={classNames({
              [styles['clips-container']]: !props.formVisible,
              [styles['clips-container-open-form']]: props.formVisible,
              'mt-4': interviewClips.length !== 0 && props.formVisible,
            })}
          >
            {loading ? (
              <div style={{ minHeight: '250px' }}>
                <LoadingSpinner />
              </div>
            ) : (
              <InterviewClipList
                interviewId={props.interview.id.toString()}
                interviewClips={interviewClips.sort(
                  (a, b) => b.startOffsetMs - a.startOffsetMs,
                )}
                onSelectClip={props.onSelectClip}
                monologues={props.transcript?.monologues || []}
                onDelete={handleDeleteConfirm}
                onEdit={handleEdit}
                loadingLinkClipId={loadingLinkClipId}
                onCopyLink={handleCopyLink}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
