import { Job } from './Job';
import { Candidate } from './Candidate';
import { CompanyInfo } from '../reference_check/entities/CompanyInfo';
import { ApplicationResume } from './ApplicationResume';
import { ApplicationCoverLetter } from './applicant_tracking/ApplicationCoverLetter';
import { BriefJob } from './JobDetails';
import { ActionItem } from './ActionItem';
import { Source } from './applicant_tracking/Source';
import { Survey } from './survey_engine/Survey';
import { Currency } from './Currency';
import { Employee } from './Employee';
import { HrisProvider } from '../enums/IntegrationProvider/HrisProvider';

export enum ApplicationStatus {
  Draft = 'draft',
  Active = 'active',
  Rejected = 'rejected',
  Hired = 'hired',
  Converted = 'converted',
}

export interface ExpectedSalary {
  value: number;
  currency: Currency;
}

export interface Application {
  id: number;
  status: ApplicationStatus;
  candidate: Candidate;
  job?: Job;
  company_info: CompanyInfo;
  enabled_trigger: boolean;
  job_stage_name?: string;
  // Only available in Platform.
  external_id?: string;
  // Only available in ATS.
  nextInterviewStageId?: number;
  hasPlatformApplication?: boolean;
  appliedAt?: Date;
  academicDegree?: string;
  currentCompany?: string;
  currentTitle?: string;
  experience?: string;
  lastInteractionAt?: Date;
  portfolioUrl?: string;
  applicationResume?: ApplicationResume;
  applicationCoverLetter?: ApplicationCoverLetter;
  attributeRating?: number;
  applicationRating?: number;
  daysInJobStage: number;
  actionItems?: ActionItem[];
  details?: string;
  source?: Source;
  hasWorkingVisa?: boolean;
  survey?: Survey;
  expectedSalary?: ExpectedSalary;
  hrisEmployee?: Employee;
  allowedHrisProvider?: HrisProvider;
  sendOfferEnabled: boolean;
  redacted: boolean;
  viewAllScorecards: boolean;
  createRatingEnabled: boolean;
  candidateRatingId: number;
  canTransitionApplication: boolean;
  upsertTagsEnabled?: boolean;
}

export interface BriefApplication {
  id: number;
  status: ApplicationStatus;
  candidateName: string;
  job: BriefJob;
  jobStageName: string;
  appliedAt: Date;
  daysInJobStage: number;
  actionItems?: ActionItem[];
  nextInterviewStageId?: number;
}
