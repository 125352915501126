import React from 'react';
import { SignableOfferDocument } from '../../../../../entities/v1/applicant_tracking/SignableOfferDocument';
import { Col } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { Link } from '../../../../../components/Link';

interface PropTypes {
  offerStatus: string;
  signableOfferDocuments: SignableOfferDocument[];
}

function DocumentStatusChip(props: { offerStatus: string; status: string }) {
  if (props.offerStatus === 'rejected') {
    return (
      <Chip size='sm' color={ChipColor.DangerLight}>
        Declined
      </Chip>
    );
  }

  switch (props.status) {
    case 'pending':
      return (
        <Chip size='sm' color={ChipColor.OrangeLight}>
          Pending
        </Chip>
      );
    case 'signed':
      return (
        <Chip size='sm' color={ChipColor.SuccessLight}>
          Completed
        </Chip>
      );
  }
}

function SignableDocumentRow(props: {
  offerStatus: string;
  fileName: string;
  url: string;
  status: string;
}) {
  return (
    <div className='d-flex justify-content-between mt-2'>
      <Col className='fs-5' xs='6'>
        <Link
          target='_blank'
          textClassNames='text-info text-truncate'
          href={props.url}
        >
          {props.fileName}
        </Link>{' '}
      </Col>
      <Col className='fs-5' xs='auto'>
        <DocumentStatusChip
          offerStatus={props.offerStatus}
          status={props.status}
        />
      </Col>
    </div>
  );
}

export function SignableOfferDocuments(props: PropTypes) {
  return (
    props.signableOfferDocuments?.length > 0 && (
      <div>
        <div className='d-flex justify-content-between'>
          <Col className='fs-5' xs='6'>
            Document
          </Col>
          <Col className='fs-5' xs='auto'>
            Status
          </Col>
        </div>
        {props.signableOfferDocuments.map((doc, index) => {
          return (
            <SignableDocumentRow
              key={index}
              offerStatus={props.offerStatus}
              fileName={doc.filePath}
              url={doc.url}
              status={doc.status}
            />
          );
        })}
      </div>
    )
  );
}
