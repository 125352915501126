import snakecaseKeys from 'snakecase-keys';
import { NewListCandidates } from '../../../../entities/v1/applicant_tracking/NewListCandidate';
import { ApplicationListingFilters } from '../../../../containers/applicant_tracking/Applications/ListApplicationsPage/ListApplicationsPageBody/ListApplicationsFilters/utils';
import { ApiService } from '../../../ApiService';
import { ApplicationActions } from '../../../../entities/v1/applicant_tracking/ApplicationActions';
import { TagList } from '../../../../entities/v1/applicant_tracking/TagList';

function mapToParams(filters: ApplicationListingFilters, search: string) {
  return {
    statuses: filters.statuses?.map((status) => status.value),
    jobStageIds: filters.jobStages?.map((stage) => stage.value),
    jobIds: filters.jobs?.map((job) => job.value),
    sourceIds: filters.sources?.map((source) => source.value),
    channels: filters.channels?.map((channel) => channel.value),
    rightToWorkLocationIds: filters.rightToWorkLocations?.map(
      (location) => location.value,
    ),
    rightToWorkLocationValue: filters.rightToWorkLocationValue,
    startDate: filters.dateRange.start?.utc()?.format(),
    endDate: filters.dateRange.end?.utc()?.format(),
    dateRange: filters.dateRange.range,
    page: filters.page,
    sortColumn: filters.sortingColumn?.columnName,
    sortDirection: filters.sortingColumn?.direction,
    nameValue: filters.name,
    nameOperator: filters.nameInclusionValue,
    emailValue: filters.email,
    emailOperator: filters.emailInclusionValue,
    resumeValue: filters.resume,
    resumeOperator: filters.resumeInclusionValue,
    location: filters.location,
    search: search,
    tagIds: filters.tags?.map((tag) => tag.value),
  };
}

export class ApplicationService {
  public static search(
    filterStates: ApplicationListingFilters,
    search?: string,
  ): Promise<NewListCandidates> {
    return ApiService.get<NewListCandidates>(
      '/api/v1/applicant_tracking/applications/search',
      snakecaseKeys(mapToParams(filterStates, search)),
    );
  }

  public static listApplicationActions(params: {
    applicationId: number;
    candidateId: number;
  }): Promise<ApplicationActions> {
    return ApiService.get<ApplicationActions>(
      '/api/v1/applicant_tracking/applications/actions',
      snakecaseKeys(params),
    );
  }

  public static listAvailableTags(
    id: number,
    page?: number,
    pageSize?: number,
    name?: string,
  ): Promise<TagList> {
    return ApiService.get<TagList>(
      `/api/v1/applicant_tracking/applications/${id}/available_tags`,
      snakecaseKeys({ page, pageSize, name }),
    );
  }
}
