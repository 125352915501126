import { SignableOfferTemplate } from '../../../entities/v1/applicant_tracking/SignableOfferTemplate';
import {
  UpsertParams,
  FileParam,
} from '../../../services/v1/applicant_tracking/SignableOfferTemplateService';

export interface FormState extends Omit<UpsertParams, 'signatoryIds'> {
  signatories: { label: string; subLabel: string; value: number }[];
  draftOtherFile?: FileParam;
}

export function formToParams(form: FormState): UpsertParams {
  const signatoryIds = form.signatories.map((s) => s.value);
  return { ...form, signatoryIds };
}

export function templateToForm(template: SignableOfferTemplate): FormState {
  const offer = template.signableDocuments.find((doc) => doc.kind === 'offer');
  const other = template.signableDocuments.filter(
    (doc) => doc.kind === 'other',
  );

  return {
    name: template.name,
    daysToExpiration: template.daysToExpiration,
    signatories: template.signatories.map((signatory) => ({
      label: signatory.user.email,
      subLabel: signatory.user.name,
      value: signatory.user.id,
    })),
    offerFile: { id: offer.id, fileName: offer.fileName, content: 'USE_URL' },
    otherFiles: other.map((file) => ({
      id: file.id,
      fileName: file.fileName,
      content: 'USE_URL',
    })),
    emailSubjectJson: template.emailTemplate.subject,
    emailSubjectPlain: template.emailTemplate.subjectText,
    emailBodyJson: template.emailTemplate.template,
    emailBodyHtml: template.emailTemplate.templateHtml,
  };
}
