import classNames from 'classnames';
import { InputFormField } from '../../../components/InputFormField';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { NARROW_CONTAINER_WIDTH } from './consts';
import { FormState } from './FormState';

interface PropTypes {
  errors: FieldErrors;
  disabled?: boolean;
  register: UseFormRegister<FormState>;
}

export function DaysToExpirationInput(props: PropTypes) {
  return (
    <div>
      <div style={{ maxWidth: NARROW_CONTAINER_WIDTH }}>
        <InputFormField
          placeholder='Insert days to expiration'
          disabled={props.disabled}
          fieldName='Days to expiration'
          classNames={classNames(
            props.errors.daysToExpiration && 'is-invalid',
            'fs-5 fw-normal',
          )}
          registerReturn={props.register('daysToExpiration', {
            valueAsNumber: true,
          })}
          type='number'
        />
      </div>
      <small className='mt-2 d-flex text-dark-200'>
        The offer will automatically expire after the chosen number of days
        pass.
      </small>
    </div>
  );
}
