import React, { useEffect } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { Icon } from '../../../components/Icon';
import { FormState } from './FormState';
import { Button } from 'reactstrap';
import { FileInput } from './FileInput';

interface PropTypes {
  register: UseFormRegister<FormState>;
  control: Control<FormState>;
  watch: UseFormWatch<FormState>;
  resetField: UseFormResetField<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
}

export function OtherFiles(props: PropTypes) {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'otherFiles',
  });
  const dep = props.watch('draftOtherFile.fileName');
  useEffect(() => {
    if (!dep) return;
    const draft = props.getValues('draftOtherFile');

    append({ ...draft });

    props.resetField('draftOtherFile.fileName', { defaultValue: null });
    props.resetField('draftOtherFile.content', { defaultValue: null });
  }, [dep]);

  return (
    <div className='d-flex flex-column gap-3'>
      <div>
        <FileInput
          register={props.register}
          control={props.control}
          watch={props.watch}
          resetField={props.resetField}
          getValues={props.getValues}
          setValue={props.setValue}
          controllerFileName='draftOtherFile.fileName'
          controllerContentName='draftOtherFile.content'
          fieldName='Additional Documents (Max. 6)'
          isRequired={false}
          disabled={fields.length === 6}
        />
      </div>
      <FileList fields={fields} remove={remove} />
    </div>
  );
}

function FileList(props: {
  fields: FieldArrayWithId<FormState, 'otherFiles', 'id'>[];
  remove: UseFieldArrayRemove;
}) {
  if (props.fields.length === 0) return null;

  return (
    <div className='bg-gray p-3 rounded fs-5 d-flex flex-column gap-2'>
      {props.fields.map((field, index) => (
        <div key={field.id} className='d-flex'>
          <div>
            <Icon name={'bi-paperclip'} className='pe-2' />
            <span>{field.fileName}</span>
          </div>
          <Button
            color='borderless'
            size='sm'
            className='ms-auto'
            onClick={() => props.remove(index)}
          >
            <Icon name='bi-trash' />
          </Button>
        </div>
      ))}
    </div>
  );
}
