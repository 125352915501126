import React, { useCallback } from 'react';
import { CronofyOptions } from '../../../../../components/cronofy/CronofyOptions';
import { Application } from '../../../../../entities/applicant_tracking/Application';
import { InterviewStage } from '../../../../../entities/applicant_tracking/InterviewStage';
import { EventSlot } from '../../../../../components/cronofy/CronofyDatePicker/EventSlot';
import { InterviewService } from '../../../../../services/applicant_tracking/InterviewService';
import ScheduleInterviewPage from '../index';
import { User } from '../../../../../entities/User';
import { VcsProvider } from '../../../../../enums/IntegrationProvider/VcsProvider';
import { InterviewerParticipant } from '../../../../../entities/applicant_tracking/InterviewerParticipant';
import { appendCurrentRedirectUri } from '../../../../../utils/url';

interface PropTypes {
  cronofyDatePickerOptions: CronofyOptions;
  application: Application;
  currentUser: User;
  interviewStage: InterviewStage;
  meetingProviders: VcsProvider[];
  defaultMeetingProvider: VcsProvider;
}

export default function ScheduleInterview(props: PropTypes) {
  const onConfirm = useCallback(
    async (
      eventSlot: EventSlot,
      participants: InterviewerParticipant[],
      meetingProvider: string,
    ) => {
      try {
        const meetingOrganizer = participants.find(
          (p) => p.isMeetingOrganizer,
        ).user;

        const interview = await InterviewService.create(
          props.application.id,
          props.interviewStage.id,
          participants.map((p) => p.user.id),
          eventSlot.slot.start,
          eventSlot.slot.end,
          eventSlot.tzid,
          meetingProvider,
          meetingOrganizer.id,
        );

        window.location.href = appendCurrentRedirectUri(
          `/applicant_tracking/interviews/${interview.id}/schedule_success`,
        );
      } catch (e: unknown) {
        window.location.href = appendCurrentRedirectUri(
          `/applicant_tracking/interviews/schedule_error?application_id=${props.application.id}`,
        );
      }
    },
    [],
  );

  return (
    <ScheduleInterviewPage
      meetingOrganizer={props.currentUser}
      applicationIds={[props.application.id]}
      title='Schedule your interview'
      description='At least one participant needs to have the calendar connection'
      cronofyDatePickerOptions={props.cronofyDatePickerOptions}
      candidate={props.application.candidate}
      participants={[props.currentUser]}
      interviewStage={props.interviewStage}
      meetingProviders={props.meetingProviders}
      meetingProvider={props.defaultMeetingProvider}
      onConfirm={onConfirm}
      candidateView={false}
      selfScheduleView={false}
      allowChangingMeetingOrganizer={true}
      allowIgnoreParticipantCalendar={true}
    />
  );
}
