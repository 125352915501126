import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Layout } from '../Layout';
import { SignableOfferTemplateService } from '../../../../services/v1/applicant_tracking/SignableOfferTemplateService';
import { useForm } from 'react-hook-form';
import { FormState, formToParams, templateToForm } from '../FormState';
import { NameInput } from '../NameInput';
import { DaysToExpirationInput } from '../DaysToExpirationInput';
import { DocumentsSection } from '../DocumentsSection';
import { EmailMessageSection } from '../EmailMessageSection';
import { AlertType } from '../../../../components/Alert';
import { redirectWithAlert } from '../../../../services/FlashService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

const BACK_URL = '/organization_settings/signable_offer_templates';

interface PropTypes {
  id: number;
}

export default function EditPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    control,
    formState,
    setValue,
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
    resetField,
  } = useForm<FormState>({
    mode: 'onChange',
  });

  async function onSubmit(data: FormState) {
    setIsLoading(true);
    const params = formToParams(data);
    await SignableOfferTemplateService.update(props.id, params);
    redirectWithAlert(BACK_URL, 'Offer Template updated!', AlertType.Success);
  }

  function handleCancel() {
    window.location.href = BACK_URL;
  }

  useEffect(() => {
    SignableOfferTemplateService.show(props.id, [
      'signatories',
      'signatories.user',
      'signable_documents',
      'email_template',
    ]).then((template) => {
      reset(templateToForm(template));
      setIsLoading(false);
    });
  }, []);

  const watchOfferFileContent = watch('offerFile.content');
  useEffect(() => {
    if (!formState.dirtyFields.offerFile) return;
    setValue('offerFile.id', null);
  }, [watchOfferFileContent]);

  return (
    <div className='container'>
      {isLoading && <LoadingSpinner showBackdrop />}
      <PageHeader title='Edit Offer Template' backUrl={BACK_URL} />
      <Layout
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        isValid={formState.isValid}
      >
        <div className='d-flex flex-column fs-5 gap-4 pb-4'>
          <NameInput errors={formState.errors} register={register} />
          <DaysToExpirationInput
            errors={formState.errors}
            register={register}
          />
        </div>
        <DocumentsSection
          control={control}
          resetField={resetField}
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
        <EmailMessageSection
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      </Layout>
    </div>
  );
}
