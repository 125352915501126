import classNames from 'classnames';
import React from 'react';
import { validateEmail } from '../../../../../utils/validators/emailValidator';

export function EmailField(props: {
  label: string;
  value: string;
  name: string;
  className?: string;
  mandatory?: boolean;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}) {
  const err = validateEmail(props.value) ?? props.error;

  return (
    <div className={props.className}>
      <label className='form-label'>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
      <input
        type='email'
        className={classNames('form-control fs-5', {
          'is-invalid border-danger': err,
        })}
        value={props.value ?? undefined}
        onChange={(event) =>
          props.onInputChange(props.name, event.target.value)
        }
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
      {err && <div className='text-danger-500 mt-2 fs-5'>{err}</div>}
    </div>
  );
}
