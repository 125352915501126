import React from 'react';
import { Card } from '../../../components/Card';
import { SetupPageHeader } from '../../../components/SetupPageHeader';

interface PropTypes {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  isValid: boolean;
  children: React.ReactNode;
}

export function Layout(props: PropTypes) {
  return (
    <Card>
      <form onSubmit={props.onSubmit}>
        <SetupPageHeader
          title={'Offer Template'}
          firstItems={[]}
          intermediateItems={[
            {
              title: 'Cancel',
              type: 'Cancel',
              action: props.onCancel,
            },
            {
              title: 'Save',
              type: 'Save',
              disabled: !props.isValid,
              action: () => props.onSubmit,
            },
          ]}
        />
        <div className='py-2'>{props.children}</div>
      </form>
    </Card>
  );
}
