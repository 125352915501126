import React, { useEffect, useState } from 'react';
import { FiltersHeader } from './FiltersHeader';
import { FiltersBody } from './FiltersBody';
import {
  ApplicationListingFilters,
  DEFAULT_FILTER_STATE,
  FilterType,
  getAvailableFilters,
} from './utils';
import { mapSearchSegmentToFilters } from './utils/mapSearchSegmentToFilters';
import { AlertObject } from '../../../../../../components/Alert';
import { SearchSegmentService } from '../../../../../../services/v1/applicant_tracking/SearchSegmentService';
import { SearchSegment } from '../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { getQueryParam } from '../../../../../../utils/url';
import { SEARCH_PARAM } from '../../OpenSearch';
import { SEGMENT_PARAM } from './FiltersHeader/SegmentSelect';
import {
  getFiltersFromUrl,
  updateUrlWithFilters,
} from './utils/filterUrlParams';

interface PropTypes {
  atsTagsPanelEnabled: boolean;
  filters: ApplicationListingFilters;
  filterTypes: FilterType[];
  onSearch: () => void;
  setAlert: (alert: AlertObject) => void;
  setFilters: (filters: ApplicationListingFilters) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
}

function isNullOrUndefinedOrEmptyArray(value) {
  return value == null || (Array.isArray(value) && value.length === 0);
}

function activateSelectedFilters(
  filters: ApplicationListingFilters,
  filterTypes: FilterType[],
  setFilterTypes: (filterTypes: FilterType[]) => void,
  atsTagsPanelEnabled: boolean,
) {
  const OPTIONAL_FILTERS_TO_CHECK = [
    'name',
    'email',
    'location',
    'channels',
    'sources',
    'rightToWorkLocations',
    'resume',
    'tags',
  ];

  // This logic shall be reverted when we remove the atsTagsPanelEnabled feature flag
  const availableFilters = getAvailableFilters(atsTagsPanelEnabled);

  const validOptionalFilters = OPTIONAL_FILTERS_TO_CHECK.filter(
    (filter) => !isNullOrUndefinedOrEmptyArray(filters[filter]),
  );

  const newFilterTypes = [
    ...filterTypes,
    ...validOptionalFilters.map((filter) =>
      availableFilters.find((type) => type.value === filter),
    ),
  ].filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.value === item.value),
  );

  setFilterTypes(newFilterTypes);
}

async function reloadSegments(
  setSegments: (segments: SearchSegment[]) => void,
): Promise<SearchSegment[]> {
  const segmentList = await SearchSegmentService.list();
  setSegments(segmentList.searchSegments);

  return segmentList.searchSegments;
}

export function ListApplicationsFilters(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [prevSelectedSegment, setPrevSelectedSegment] = useState(null);
  const [segments, setSegments] = useState<SearchSegment[]>([]);

  const reloadFilters = async (segment?: SearchSegment) => {
    if (segment) {
      mapSearchSegmentToFilters(segment).then((filters) => {
        activateSelectedFilters(
          filters,
          props.filterTypes,
          props.setFilterTypes,
          props.atsTagsPanelEnabled,
        );
        props.setFilters(filters);
      });
    } else {
      // Don't reset if we have URL filters
      const urlFilters = getFiltersFromUrl();
      if (Object.keys(urlFilters).length > 0) {
        // Keep existing filters
        return;
      }
      // Only reset if no URL filters
      props.setFilters({ ...DEFAULT_FILTER_STATE, statuses: null });
    }
  };

  const changeToDefault = () => {
    const defaultSegment = segments?.find((i) => i.isDefault);

    if (selectedSegment === defaultSegment) {
      reloadFilters(defaultSegment);
    } else {
      setSelectedSegment(defaultSegment);
    }
  };

  useEffect(() => {
    const urlFilters: Partial<ApplicationListingFilters> = getFiltersFromUrl();
    const hasUrlFilters = Object.keys(urlFilters).length > 0;

    if (hasUrlFilters) {
      // Activate any filters that are in the URL
      activateSelectedFilters(
        { ...urlFilters } as ApplicationListingFilters,
        props.filterTypes,
        props.setFilterTypes,
        props.atsTagsPanelEnabled,
      );
      // Set the filters from URL
      props.setFilters({
        ...DEFAULT_FILTER_STATE,
        ...urlFilters,
      });
    }

    reloadSegments(setSegments)
      .then((segments) => {
        const param = getQueryParam(SEGMENT_PARAM);
        const segmentParameter =
          param && segments.find((i) => i.id.toString() === param);

        // Decode search parameter if it exists (required due to security feature)
        const encodedSearch = getQueryParam(SEARCH_PARAM);
        const hasSearch = encodedSearch ? true : false;

        if (!hasSearch) {
          const defaultSegment = segments.find((i) => i.isDefault);
          setSelectedSegment(segmentParameter || defaultSegment);

          // If no URL filters, load default segment filters
          if (!hasUrlFilters && defaultSegment) {
            mapSearchSegmentToFilters(defaultSegment).then((filters) => {
              activateSelectedFilters(
                filters,
                props.filterTypes,
                props.setFilterTypes,
                props.atsTagsPanelEnabled,
              );
              props.setFilters(filters);
            });
          }
        } else {
          if (segmentParameter) {
            setSelectedSegment(segmentParameter);
          }
        }
      })
      .catch((e) => console.error('Could not reload segments', e))
      .finally(() => setIsLoading(false));
  }, []); // Only run on mount

  useEffect(() => {
    if (selectedSegment && prevSelectedSegment)
      mapSearchSegmentToFilters(selectedSegment).then(updateUrlWithFilters);

    if (!selectedSegment || !prevSelectedSegment)
      return setPrevSelectedSegment(selectedSegment);

    setIsLoading(true);

    reloadFilters(selectedSegment).finally(() => setIsLoading(false));
  }, [selectedSegment]);

  return (
    <div className='p-4'>
      <FiltersHeader
        atsTagsPanelEnabled={props.atsTagsPanelEnabled}
        isFiltersOpen={isFiltersOpen}
        filters={props.filters}
        filterTypes={props.filterTypes}
        segments={segments}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        setFilterTypes={props.setFilterTypes}
        setIsFiltersOpen={setIsFiltersOpen}
        setFilters={props.setFilters}
      />
      {isFiltersOpen && (
        <FiltersBody
          filters={props.filters}
          filterTypes={props.filterTypes}
          isLoading={isLoading}
          onSearch={props.onSearch}
          setFilters={props.setFilters}
          setAlert={props.setAlert}
          onCreateSegment={(segment) => {
            reloadSegments(setSegments).then((_a) =>
              setSelectedSegment(segment),
            );
          }}
          selectDefaultSegment={changeToDefault}
        />
      )}
    </div>
  );
}
