import React from 'react';
import { Countable, Paginatable } from '../../entities/Pagination';
import { SelectOption } from '../Select';
import { LabelledMultiPaginatedSelect } from '../Select/LabelledMultiPaginatedSelect';

export interface FilterResult extends Paginatable, Countable {
  options: SelectOption[];
}

interface PropTypes<IsMulti extends boolean = false> {
  selected: SelectOption[];
  onChange: (newValue: SelectOption[]) => void;
  loadOptions: (search: string, page: number) => Promise<FilterResult>;
  cacheId?: number;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  isMulti?: IsMulti;
  label?: string;
  labelClassName?: string;
  mandatory?: boolean;
  max?: number;
  placeholder?: string;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

export function MultiSelectAsyncFilter<IsMulti extends boolean = false>(
  props: PropTypes<IsMulti>,
) {
  async function loadOptions(
    search: string,
    _loadedOptions: unknown,
    { page }: AdditionalType,
  ) {
    const result = await props.loadOptions(search, page);

    let changeSelected = false;

    const selected = props.selected.map((selected) => {
      const selectedOptionLoaded = result.options.find(
        (option) => selected.value == option.value && selected !== option,
      );

      changeSelected ||= !!selectedOptionLoaded;

      return selectedOptionLoaded ?? selected;
    });

    if (changeSelected) {
      props.onChange(selected);
    }

    return {
      options: result.options,
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <>
      <LabelledMultiPaginatedSelect
        label={props.label}
        max={props.max}
        selectedItems={props.selected}
        placeholder={props.placeholder}
        className={props.className}
        cacheId={props.cacheId}
        disabled={props.disabled}
        loadOptions={loadOptions}
        setSelectedItems={props.onChange}
        additional={defaultAdditional}
        danger={props.danger}
        mandatory={props.mandatory}
        labelClassName={props.labelClassName}
      />
    </>
  );
}
