import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { ScorecardBody } from './ScorecardBody';
import { CandidatePageHeader } from '../../../CandidatePage/CandidatePageHeader';
import { Scorecard } from '../../../../entities/applicant_tracking/Scorecard';
import { EmptyState } from '../../../../components/EmptyState';
import { Alert, AlertType } from '../../../../components/Alert';
import { User } from '../../../../entities/User';
import ScorecardAssistantPage from '../../../ScorecardAssistantPage';
import { SubmitScorecard } from './ScorecardSubmit';
import { ScorecardHeader } from './ScorecardHeader';
import { updateSurveyAnswers } from '../../../../utils/applicant_tracking/scorecardSurvey';
import { Interview } from '../../../../entities/Interview';
import { ScorecardService } from '../../../../services/v1/applicant_tracking/ScorecardService';
import { ScorecardService as ScorecardServiceDeprecated } from '../../../../services/applicant_tracking/ScorecardService';
import { Question } from '../../../../entities/v1/survey_engine/Question';
import { InterviewMarker } from '../../../../entities/InterviewMarker';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { redirectWithAlert } from '../../../../services/FlashService';
import { ApiError } from '../../../../services/ApiService/errors/ApiError';

interface PropTypes {
  scorecardId: number;
  interview?: Interview;
  user: User;
  aiScorecardFill: boolean;
  atsEnabled: boolean;
}

interface ScorecardPageUIPropTypes {
  scorecard: Scorecard;
  interview?: Interview;
  user: User;
  aiScorecardFill: boolean;
  prefilledAttributesCount: number;
  atsEnabled: boolean;
}

function canAutoFillAttributeValue(
  scorecard: Scorecard,
  scorecardAttribute: Question,
): boolean {
  // True if there is no answer on the scorecard
  const answer = scorecard.survey.answers?.find(
    (ans) => ans.questionId == scorecardAttribute.id,
  );

  return answer === undefined;
}

function findAttributeByInterviewMarker(
  scorecard: Scorecard,
  interviewMarker: InterviewMarker,
): Question {
  // True if scorecard attribute is not filled or is empty
  return scorecard.survey.sections
    .find(
      (section) =>
        (section.sectionId ?? section.id).toString() ===
        interviewMarker.scorecard_attribute.scorecard_category_external_id,
    )
    ?.questions.find(
      (question) => question.title === interviewMarker.scorecard_attribute.name,
    );
}

function setScorecardValuesFromInterviewMarkers(
  scorecard: Scorecard,
  interview: Interview,
): number {
  let prefilledAttributesCount = 0;

  const interviewMarkersWithScorecardAttributes =
    interview?.interview_markers?.filter(
      (marker) =>
        marker.scorecard_attribute && scorecard.userId === marker.user?.id,
    );

  interviewMarkersWithScorecardAttributes?.forEach((marker) => {
    const scorecardAttribute = findAttributeByInterviewMarker(
      scorecard,
      marker,
    );

    if (
      scorecardAttribute &&
      canAutoFillAttributeValue(scorecard, scorecardAttribute)
    ) {
      scorecard.survey = updateSurveyAnswers(
        scorecard.survey,
        scorecardAttribute,
        false,
        marker.score?.toString(),
      );

      prefilledAttributesCount++;
    }
  });

  return prefilledAttributesCount;
}

export default function ScorecardPage(props: PropTypes) {
  const [prefilledScorecard, setPrefilledScorecard] = useState<Scorecard>(null);
  const [prefilledAttributesCount, setPrefilledAttributesCount] =
    useState<number>(0);

  useEffect(() => {
    (async () => {
      const scorecard = await ScorecardService.show(props.scorecardId, [
        'user',
        'interview_stage',
        'job_stage',
        'application',
        'application.candidate',
        'application.job',
        'survey',
        'survey.sections',
        'survey.sections.questions',
        'survey.answers',
      ]);

      setPrefilledAttributesCount(
        setScorecardValuesFromInterviewMarkers(scorecard, props.interview),
      );
      setPrefilledScorecard(scorecard);
    })();
  }, []);

  if (prefilledScorecard == null) return null;

  return (
    <ScorecardPageUI
      {...props}
      scorecard={prefilledScorecard}
      prefilledAttributesCount={prefilledAttributesCount}
    />
  );
}

function ScorecardPageUI(props: ScorecardPageUIPropTypes) {
  const [scorecard, setScorecard] = useState(props.scorecard);
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Info);
  const [alertMessage, setAlertMessage] = useState<ReactNode>(null);
  const isEditing = props.scorecard.survey.status === 'completed';
  const redirectUrl =
    getRedirectUriQueryParam() ??
    `/applications/${props.scorecard.application.id}`;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = () => {
    ScorecardServiceDeprecated.create({
      id: scorecard.id,
      score: scorecard.score,
      answers: scorecard.survey.answers,
      isEditing: isEditing,
    })
      .then(() => {
        setIsSubmitting(true);
        redirectWithAlert(
          redirectUrl,
          `The feedback about **${
            props.scorecard.application.candidate.name
          }** was successfully ${isEditing ? 'updated' : 'added'}!`,
        );
      })
      .catch((e: ApiError) => {
        setAlertActive(true);
        setAlertType(AlertType.Danger);
        setAlertMessage(e.body?.message || 'The scorecard was not submitted.');
      });
  };

  const disableSubmission =
    props.user.id !== scorecard.user.id || scorecard.score == null;

  return (
    <>
      {alertActive && (
        <Alert
          type={alertType}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlertActive(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <CandidatePageHeader
        applicantTrackingEnabled={true}
        application={props.scorecard.application}
      >
        <SubmitScorecard
          disabled={disableSubmission}
          applicationId={scorecard.application.id}
          onSubmit={onSubmit}
        />
      </CandidatePageHeader>
      <Row className='justify-content-center'>
        <Col xs='8' className='d-flex justify-content-center'>
          <Card className='mt-4 pt-6h px-6h pb-4h'>
            <ScorecardBody
              user={props.user}
              scorecard={scorecard}
              isEditing={isEditing}
              prefilledAttributesCount={props.prefilledAttributesCount}
              aiScorecardFill={
                props.aiScorecardFill && props.interview?.hasTranscript
              }
              disabled={isSubmitting}
              setScorecard={setScorecard}
            />
            <SubmitScorecard
              className='mt-5h justify-content-center'
              applicationId={scorecard.application.id}
              disabled={disableSubmission}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
        {props.scorecard.interviewStage && (
          <Col className={classNames('mt-4')} xs='4'>
            <Card>
              {props.interview ? (
                <ScorecardAssistantPage
                  classNames='rounded-4'
                  heightClass='h-100'
                  header={
                    <ScorecardHeader
                      interview={props.interview}
                      scorecard={props.scorecard}
                    />
                  }
                  interview={props.interview}
                  atsEnabled={props.atsEnabled}
                />
              ) : (
                <EmptyState
                  header={<ScorecardHeader scorecard={props.scorecard} />}
                  classNames={classNames(
                    'col-9',
                    'd-grid',
                    'align-content-center',
                    'mx-4 mt-4 pb-8',
                  )}
                  cardless={true}
                  imageClassNames='mx-auto'
                  title='Your interview was not recorded'
                  text={
                    <span className='fw-normal'>
                      Contact{' '}
                      <a
                        className='fw-bold'
                        href='mailto:support@screenloop.com'
                      >
                        support@screenloop.com
                      </a>{' '}
                      to activate AI notes and other key interview highlights
                    </span>
                  }
                />
              )}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
}
