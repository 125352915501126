import React from 'react';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { IconSpan } from '../../../../../../components/IconSpan';
import { getHrisName } from '../../../../../../utils/hris';
import { HrisProvider } from '../../../../../../enums/IntegrationProvider/HrisProvider';

interface PropTypes {
  hrisProvider: HrisProvider;
}

export function ModalAlertMessage(props: PropTypes) {
  return (
    <AlertMessage
      className={'rounded-1 mx-0 w-100 bg-blue-gray-light'}
      hasOverlay={true}
      text={
        <div className={'px-1 py-2h'}>
          <IconSpan
            icon={{ name: 'bi-info-circle', className: 'fs-4' }}
            spanText={`Candidate details will be exported to ${getHrisName(
              props.hrisProvider,
            )} and can be edited below.`}
            spanClassName={'fw-normal fs-5'}
          />
        </div>
      }
    />
  );
}
