import React from 'react';
import { SignableOfferForm } from '../..';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { OfferDocuments } from '../../OfferDocuments';
import { Signatory } from '../../../../../../entities/v1/applicant_tracking/Signatory';
import { Avatar } from '../../../../../../components/Avatar';
import { Col, Row } from 'reactstrap';
import { AttachmentDto } from '../../../../../../services/dtos/EmailDto';
import parse from 'html-react-parser';
import { domPurify, updateLinksToNewTab } from '../../../../../../utils/dom';
import { AttachmentInputList } from '../../../../../SendEmailModal/EmailModalBody/EmailFooter/AttachmentInputList';
import classNames from 'classnames';
import { SignableDocument } from '../../../../../../entities/v1/applicant_tracking/SignableDocument';
import { AlertMessage } from '../../../../../../components/AlertMessage';

interface PropTypes {
  emailAttachments: AttachmentDto[];
  emailHtml: string;
  isLoading: boolean;
  signableDocuments: SignableDocument[];
  signatories: Signatory[];
  alertMessage?: string;
  applicationId?: number;
  className?: string;
  form?: SignableOfferForm;
}

export function CompanySignatories(props: { signatories: Signatory[] }) {
  return (
    <div>
      <span className='fw-semibold fs-5'>Company Signatories</span>
      <Row className='d-flex align-items-center gx-2h mt-2'>
        {props.signatories.map((signatory, index) => (
          <React.Fragment key={index}>
            <Col className='d-flex col-auto'>
              <Avatar name={signatory.user.name} type='initials' size='md' />
              <span className='fs-5'>{signatory.user.name}</span>
            </Col>
            {props.signatories.length - 1 !== index && (
              <Col className='col-auto'>
                <small className='text-dark-200'>OR</small>
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
}

export function EmailMessage(props: {
  emailMessageHtml: string;
  attachments: AttachmentDto[];
}) {
  return (
    <div>
      <span className='fw-semibold fs-5'>Email Message</span>
      <div className='mt-2 bg-gray border-1 p-3'>
        {parse(domPurify(updateLinksToNewTab(props.emailMessageHtml)))}
      </div>
      <AttachmentInputList attachments={props.attachments} />
    </div>
  );
}

export function OfferSummary(props: PropTypes) {
  return (
    <div
      className={classNames(props.className, 'px-4', 'mb-3', 'overflow-auto')}
    >
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='d-flex flex-column gap-4'>
          {props.alertMessage && (
            <AlertMessage
              icon={{ name: 'bi-info-circle' }}
              className='bg-yellow-light'
              textClassName='fs-5'
              text={props.alertMessage}
            />
          )}
          <div className='w-75'>
            <OfferDocuments
              signableDocuments={props.signableDocuments}
              form={props.form}
              applicationId={props.applicationId}
            />
          </div>
          <CompanySignatories signatories={props.signatories} />
          <EmailMessage
            emailMessageHtml={props.emailHtml}
            attachments={props.emailAttachments}
          />
        </div>
      )}
    </div>
  );
}
