import React from 'react';
import { FormFieldHeader } from '../../../components/FormFieldHeader';
import { RichTextEditor } from '../../../components/RichTextEditor';
import { FormState } from './FormState';
import { InputRichTextComposer } from '../../../components/RichTextEditor/composers/InputRichTextComposer';
import { UpdateStatePlugin } from '../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { PasteVariablePlugin } from '../../../components/RichTextEditor/plugins/PasteVariablePlugin';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { TEMPLATE_VARIABLES } from './EmailVariables';

interface PropTypes {
  control: Control<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
}

export function EmailSubject(props: PropTypes) {
  const jsonSubject = props.getValues('emailSubjectJson');

  return (
    <div>
      <FormFieldHeader fieldName={'Subject'} isRequired={true} />
      <RichTextEditor<FormState>
        formHookContext={{
          controllerName: 'emailSubjectJson',
          formControl: props.control,
          required: true,
        }}
        render={(field) => (
          <InputRichTextComposer
            defaultEditorState={field.value as string}
            onChangeText={(value) => props.setValue('emailSubjectPlain', value)}
            onChangeJson={(value: string) => field.onChange(value)}
            externalPlugins={[
              <UpdateStatePlugin key={1} editorState={jsonSubject} />,
              <PasteVariablePlugin
                key={2}
                variables={Object.values(TEMPLATE_VARIABLES)}
              />,
            ]}
            placeholder='Insert subject'
          />
        )}
      />
    </div>
  );
}
