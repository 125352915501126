const UNHANDLED_REJECTION = 'unhandledrejection';

const EXPECTED_EVENT_REASONS = ['AbortError', 'UnauthorizedError'];
const EXPECTED_ERROR_STATUS = [401];

export const setupGlobalErrorHandlers = () => {
  window.addEventListener(UNHANDLED_REJECTION, (event) => {
    // Ignore error if it is expected
    if (
      EXPECTED_EVENT_REASONS.find((reason) => reason == event.reason?.name) ||
      EXPECTED_ERROR_STATUS.find((status) => status == event.reason?.status)
    ) {
      event.preventDefault();
      console.warn('Handled Promise Rejection:', event.reason);
    } else {
      console.error('Unhandled Promise Rejection:', event.reason);
    }
  });
};
