import React from 'react';
import { JobDetails } from '../../../../../../entities/JobDetails';
import { JobUnpublishPermissions } from '../../../../../../entities/v1/applicant_tracking/JobUnpublishPermissions';
import { BaseIconButton } from '../BaseIconButton';

interface PropTypes {
  jobDetails: JobDetails;
  actionName: string;
  isLoadingPermissions: boolean;
  jobUnpublishPermissions?: JobUnpublishPermissions;
  onUnpublish: () => void;
}

function validateUnpublish(jobUnpublishPermissions?: JobUnpublishPermissions) {
  if (jobUnpublishPermissions == null) return null;

  if (!jobUnpublishPermissions.currentRoleAllowed)
    return 'Your role does not have permission to unpublish this job';

  if (!jobUnpublishPermissions.currentStatusAllowed)
    return 'Only published jobs can be unpublished';
}

export function UnpublishIconButton(props: PropTypes) {
  const actionError = validateUnpublish(props.jobUnpublishPermissions);
  const disabled = props.isLoadingPermissions || actionError != null;

  return (
    <BaseIconButton
      jobDetails={props.jobDetails}
      actionName={props.actionName}
      actionError={actionError}
      disabled={disabled}
      onClick={props.onUnpublish}
    />
  );
}
