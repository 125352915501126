import React, { useState } from 'react';
import { getQueryParam } from '../../../../../utils/url';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Col, Row } from 'reactstrap';

export const SEARCH_PARAM = 'search';

export function encodeSearchQuery(query?: string): string {
  return query ? btoa(encodeURIComponent(query)) : '';
}

export function decodeSearchQuery(encoded?: string): string {
  try {
    return encoded ? decodeURIComponent(atob(encoded)) : '';
  } catch (e) {
    console.error(`Could not decode search query: ${encoded}`, e);
    return '';
  }
}

interface PropTypes {
  url: string;
  disabled?: boolean;
}

function AdvancedSearchTooltip() {
  return (
    <InfoTooltip>
      <>
        <ul>
          <li>
            Use AND or OR to include or exclude specific terms from your search
          </li>
          <li>
            Use field prefixes for targeted search: <b>name:</b>, <b>email:</b>,{' '}
            <b>location:</b>, <b>job:</b> or <b>cv:</b>
          </li>
          <li>Example: name:John AND job:engineer</li>
        </ul>
      </>
    </InfoTooltip>
  );
}

export default function OpenSearch(props: PropTypes) {
  // Decode the search parameter when reading from URL (required due to security feature)
  const encodedSearch = getQueryParam(SEARCH_PARAM) ?? '';
  const [searchText, setSearchText] = useState<string>(
    decodeSearchQuery(encodedSearch),
  );

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();

    const currentParams = new URLSearchParams(window.location.search);

    if (searchText) {
      // Encode the search text before adding to URL  (required due to security feature)
      currentParams.set(SEARCH_PARAM, encodeSearchQuery(searchText));
    } else {
      currentParams.delete(SEARCH_PARAM);
    }

    // Construct new URL preserving other parameters
    window.location.href = `${props.url}${
      currentParams.toString() ? '?' + currentParams.toString() : ''
    }`;
  };

  return (
    <Row className='g-2 flex-nowrap justify-content-start justify-content-md-end align-items-center'>
      <Col xs='auto'>
        <form className='row gx-1 align-items-center' onSubmit={handleSubmit}>
          <div className='col-auto'>
            <input
              type='text'
              name='open_search'
              value={searchText}
              onChange={(ev) => setSearchText(ev.target.value)}
              placeholder='Search Candidates by Name, Email, Job, Location'
              disabled={props.disabled}
              className={classNames(
                'form-control',
                'search-input',
                styles['open-search'],
              )}
            />
          </div>
        </form>
      </Col>
      <Col xs='auto'>
        <AdvancedSearchTooltip />
      </Col>
    </Row>
  );
}
