import React from 'react';
import { RateOption } from '../../../../entities/RateOption';
import { RatingOption } from './RatingOption';

interface PropTypes {
  ratingOptions: RateOption[];
  activeOption: RateOption | null;
  ratingOptionClass?: string;
  readonly?: boolean;
  setActiveOption: (option: RateOption | null) => void;
}

export function RatingOptions(props: PropTypes) {
  return (
    <div className='d-flex justify-content-center gap-2 my-5h'>
      {props.ratingOptions.map((option) => {
        return (
          <RatingOption
            classNames={props.ratingOptionClass}
            rateOption={option}
            key={option.title}
            active={props.activeOption?.value === option.value}
            readonly={props.readonly}
            onClick={() => props.setActiveOption(option)}
          />
        );
      })}
    </div>
  );
}
