import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';

interface PropTypes {
  label: string;
  value?: string;
  setValue: (value: string) => void;
}

export function StringInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={props.label} isRequired={true} />
      <Input
        type='text'
        className='fs-5'
        placeholder={`Insert ${props.label}`}
        defaultValue={props.value}
        onChange={(event) => props.setValue(event.target.value)}
      />
    </>
  );
}
