import React from 'react';
import { Select, SelectOption } from '../../../../../../components/Select';
import { targetGroupFromOption } from './utils';

export function TargetGroupSelect(props: {
  name: string;
  label: string;
  values: { id: number | string; name: string }[];
  selected?: { id: number | string; description: string };
  disabled?: boolean;
  onInputChange: (
    name: string,
    value: { id?: number | string; description: string },
  ) => void;
}) {
  const options = props.values
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((value) => ({
      value: value.id.toString(),
      label: value.name,
    }));
  const selected = options?.find(
    (option) => option.label === props.selected?.description,
  );

  return (
    <div>
      <label className='form-label'>
        {props.label} <span className='ps-1 text-danger'>*</span>
      </label>
      <Select
        options={options}
        isLoading={options == null}
        selected={selected}
        placeholder={`Select ${props.label}`}
        disabled={props.disabled}
        onChange={(option: SelectOption) =>
          props.onInputChange(props.name, targetGroupFromOption(option))
        }
      />
    </div>
  );
}
