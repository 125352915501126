import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ApplicationFormHeader } from './ApplicationFormHeader';
import { ApplicationFormBody } from './ApplicationFormBody';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { JobAndJobPost, initCreateJobDto } from '..';
import { getReferrer } from '../referrers';
import { replaceFinalSegment } from '../../../../../utils/url';
import { FormErrorMessage } from '../../../../../components/FormErrorMessage';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { ChangeFormConfirmationModal } from './ChangeFormConfirmationModal';
import { ApplicationFormTemplateService } from '../../../../../services/v1/applicant_tracking/ApplicationFormTemplateService';
import { ApplicationFormTemplateList } from '../../../../../entities/v1/applicant_tracking/ApplicationFormTemplateList';
import { Card } from '../../../../../components/Card';

interface PropTypes {
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
}

export function SetupJobApplicationFormPage(props: PropTypes) {
  const navigate = useNavigate();
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });

  const {
    register,
    handleSubmit,
    formState,
    watch,
    control,
    reset,
    getValues,
    setValue,
    setError,
  } = useForm<CreateJobDto>({
    mode: 'onChange',
    defaultValues: { ...props.initJobDto, ...state.setupJob },
  });

  useEffect(() => {
    reset(props.initJobDto);
  }, [props.initJobDto]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await props.onSubmit(data);
      const nextStepUrl = replaceFinalSegment('job_workflow');

      actions.updateLocalStorage({ ...getValues() });

      props.setSetupStep(props.setupStep + 1, state.setupJob);
      navigate(nextStepUrl);
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationFormTemplates, setApplicationFormTemplates] =
    useState<ApplicationFormTemplateList>(null);

  const onNavigate = () => {
    actions.updateLocalStorage({ ...getValues() });
  };

  useEffect(() => {
    (async () => {
      const templates = await ApplicationFormTemplateService.list({
        expand: ['survey_template'],
      });
      setApplicationFormTemplates(templates);

      setIsLoading(false);
    })();
  }, []);

  const watchApplicationFormTemplateId = watch('applicationFormTemplateId');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [previouslySelectedTemplateId, setPreviouslySelectedTemplateId] =
    useState<string>(watchApplicationFormTemplateId);
  const autoRejectTriggersExist =
    getValues('triggers')?.filter((trigger) => trigger.type === 'auto_reject')
      .length > 0;

  useEffect(() => {
    if (autoRejectTriggersExist && previouslySelectedTemplateId) {
      previouslySelectedTemplateId != watchApplicationFormTemplateId &&
        setIsConfirmModalOpen(true);
    } else {
      setPreviouslySelectedTemplateId(watchApplicationFormTemplateId);
    }
  }, [watchApplicationFormTemplateId]);

  const deleteAutoRejectionJobTriggers = () => {
    const newJobDto = { ...getValues() };
    newJobDto.triggers = newJobDto.triggers.filter(
      (trigger) => trigger.type != 'auto_reject',
    );
    actions.updateLocalStorage(newJobDto);
  };

  return (
    <Card className={'px-4 pt-4 pb-5 mb-5'}>
      <form onSubmit={onSubmit}>
        <FormErrorMessage error={formState.errors} />
        <ApplicationFormHeader
          formState={formState}
          {...props}
          onCancel={() => {
            actions.updateLocalStorage(initCreateJobDto);
            window.location.href = getReferrer();
          }}
          isEditFlow={props.isEditFlow}
          onNavigate={onNavigate}
        />

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ApplicationFormBody
            watch={watch}
            key={watchApplicationFormTemplateId}
            register={register}
            formControl={control}
            applicationFormTemplates={applicationFormTemplates}
          />
        )}
        {isConfirmModalOpen && (
          <ChangeFormConfirmationModal
            isOpen={isConfirmModalOpen}
            onSuccess={() => {
              deleteAutoRejectionJobTriggers();
              setPreviouslySelectedTemplateId(watchApplicationFormTemplateId);
              setIsConfirmModalOpen(false);
            }}
            onCancel={() => {
              setValue(
                'applicationFormTemplateId',
                previouslySelectedTemplateId,
              );
              setIsConfirmModalOpen(false);
            }}
          />
        )}
      </form>
    </Card>
  );
}
