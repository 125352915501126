import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  allowedFormats: string[];
  hideAllowedFormats?: boolean;
  maxFileSizeMb: number;
  errors: any[];
}

export function AttachmentInputFooter(props: PropTypes) {
  const hideFormat = props.hideAllowedFormats;

  return (
    <div
      className={classNames('d-flex', 'flex-column', 'text-dark-200', 'mt-2')}
    >
      {props.errors.map((v) => (
        <small key={v.name} className='text-danger-500'>
          {v.reason}
        </small>
      ))}
      {!hideFormat && (
        <div className='d-flex'>
          <small>
            {'Allowed file formats: ' + props.allowedFormats.join(', ')}
          </small>
          <small className='ms-auto'>{`Max file size: ${props.maxFileSizeMb}MB`}</small>
        </div>
      )}
    </div>
  );
}
