import React from 'react';
import { Col, Row } from 'reactstrap';
import { SingleAttachmentInputFormField } from '../../../../../../../components/SingleAttachmentInputFormField';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { PushToHrisFormValues } from '../../..';

interface PropTypes {
  getValues: UseFormGetValues<PushToHrisFormValues>;
  setValue: UseFormSetValue<PushToHrisFormValues>;
  register: UseFormRegister<PushToHrisFormValues>;
}

export function ResumeRow(props: PropTypes) {
  return (
    <Row className='mb-3'>
      <Col xs={12}>
        <SingleAttachmentInputFormField
          getValues={props.getValues}
          setValue={props.setValue}
          formRegister={props.register}
          headerClassNames='fw-semibold'
          controllerContentName='resume.fileContent'
          controllerFileName='resume.fileName'
          fieldName={'Resume / Curriculum Vitae'}
          allowedFormats={['.pdf']}
          maxFileSizeMb={10}
          isRequired={false}
          disabled={false}
          hideAlert={true}
        />
      </Col>
    </Row>
  );
}
