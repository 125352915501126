import React from 'react';

interface PropTypes {
  title: string;
  children: React.ReactNode;
}

export function Section(props: PropTypes) {
  return (
    <div className='border-top py-4'>
      <h4 className='fw-semibold'>{props.title}</h4>
      <div className='py-3'>{props.children}</div>
    </div>
  );
}
