import { useForm } from 'react-hook-form';
import { HrisProvider } from '../../../../../enums/IntegrationProvider/HrisProvider';
import React, { useEffect } from 'react';
import { PersonalInformationRows } from './PersonalInformationRows';
import { ProfessionalInformationRows } from './ProfessionalInformationRows';
import { ModalAlertMessage } from './ModalAlertMessage';
import { PushToHrisFormValues } from '..';
import { ExportAndCloseButtons } from './ExportAndCloseButtons';
import { Application } from '../../../../../entities/v1/applicant_tracking/Application';
import { AlertObject, AlertType } from '../../../../../components/Alert';
import { ApplicationService } from '../../../../../services/applicant_tracking/ApplicationService';
import { getHrisName } from '../../../../../utils/hris';
import { getLocationString } from '../../../../../components/LocationSelect';

interface PropTypes {
  application: Application;
  applicationIds: number[];
  setAlert: (alert: AlertObject) => void;
  setIsLoading: (isLoading: boolean) => void;
  hrisProvider: HrisProvider;
  onClose: (succeeded: boolean) => void;
}

function getAlertSuccessMessage(hrisProvider: HrisProvider) {
  return `The candidate was successfully exported to ${getHrisName(
    hrisProvider,
  )}!`;
}

function getAlertErrorMessage(hrisProvider: HrisProvider) {
  return `The candidate could not be exported to ${getHrisName(hrisProvider)}!`;
}

export function ModalBody(props: PropTypes) {
  const [firstName, lastName] = props.application.candidate.name.split(' ');

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState,
    reset,
  } = useForm<PushToHrisFormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      personalEmail: props.application.candidate.email,
      workEmail: props.application.candidate.email,
      phoneNumber: props.application.candidate.phoneNumber
        ? `+${props.application.candidate.phoneNumber}`
        : null,
      location: {
        name: getLocationString(props.application.candidate),
        city: props.application.candidate.city,
        country: props.application.candidate.country,
        state: props.application.candidate.state,
      },
      office: {
        value: props.application.jobPost.location.name,
        label: props.application.jobPost.location.name,
      },
      department: {
        value: props.application.job.department.name,
        label: props.application.job.department.name,
      },
      jobName: props.application.job.name,
      resume: {
        id: props.application?.applicationResume?.id,
        fileName: props.application?.applicationResume?.id ? 'CV.pdf' : null,
        fileContent: null,
      },
      coverLetter: {
        id: props.application?.applicationCoverLetter?.id,
        fileName: props.application?.applicationCoverLetter?.id
          ? 'cover_letter.pdf'
          : null,
        fileContent: null,
      },
    },
  });

  useEffect(() => {
    const { resume, coverLetter } = watch();

    if (formState.dirtyFields.resume?.fileContent || !resume?.fileName) {
      setValue('resume.id', null);
    }

    if (
      formState.dirtyFields.coverLetter?.fileContent ||
      !coverLetter?.fileName
    ) {
      setValue('coverLetter.id', null);
    }
  }, [
    watch('resume.fileContent'),
    watch('resume.fileName'),
    watch('coverLetter.fileContent'),
    watch('coverLetter.fileName'),
  ]);

  const onPushingToHris = async (
    data: PushToHrisFormValues,
    _e: React.SyntheticEvent,
  ) => {
    try {
      if (props.applicationIds?.length > 0) {
        props.setIsLoading(true);

        await ApplicationService.pushToHris({
          applicationIds: props.applicationIds,
          pushToHrisFormValues: data,
        });

        props.setAlert({
          type: AlertType.Success,
          message: getAlertSuccessMessage(props.hrisProvider),
        });

        reset();
        props.onClose(true);
      }
    } catch (e) {
      props.setAlert({
        type: AlertType.Danger,
        message: getAlertErrorMessage(props.hrisProvider),
      });

      props.onClose(false);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <>
      <ModalAlertMessage hrisProvider={props.hrisProvider} />
      <div className='app-card-body px-3 w-100'>
        <form onSubmit={handleSubmit(onPushingToHris)}>
          <div className='item mt-4'>
            <PersonalInformationRows
              register={register}
              control={control}
              errors={formState.errors}
            />
            <hr className='mb-4 mt-4' />
            <ProfessionalInformationRows
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              errors={formState.errors}
            />
          </div>
          <ExportAndCloseButtons
            onClose={props.onClose}
            reset={reset}
            isValid={formState.isValid}
          />
        </form>
      </div>
    </>
  );
}
