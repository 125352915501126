import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { InterviewUserList } from '../../../../entities/v1/applicant_tracking/InterviewUserList';

interface ListAvailableParticipantsRequest {
  page: number;
  pageSize: number;
  name?: string;
}

export class InterviewService {
  public static listAvailableParticipants(
    request: ListAvailableParticipantsRequest,
    expand: string[] = [
      'scheduling_user_integration',
      'scheduling_availabilities',
    ],
  ): Promise<InterviewUserList> {
    return ApiService.get<InterviewUserList>(
      `/api/v1/applicant_tracking/interviews/available_participants`,
      snakecaseKeys({
        expand,
        page: request.page,
        pageSize: request.pageSize,
        name: request.name,
      }),
    );
  }
}
