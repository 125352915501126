import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { JobPost } from '../../../../../../../entities/applicant_tracking/JobPost';
import { JobPostCardChip } from './JobPostCardChip';
import { JobPostCardTitle } from './JobPostCardTitle';
import styles from './styles.module.scss';
import { getUrlOriginPath } from '../../../../../../../utils/url';
import ahoy from 'ahoy.js';
import { CompanyInfo } from '../../../../../../../entities/CompanyInfo';
import { useFeatureFlags } from '../../../../../../../contexts/FeatureFlagContext';
import { JobPostClickEvents } from '../../../../../../../enums/ClickEvents/JobPostClickEvents';

interface PropTypes {
  jobPost: JobPost;
  whitelabelEnabled: boolean;
  companyInfo?: CompanyInfo;
}

interface JobPostCardContainerPropTypes {
  jobPost: JobPost;
  whitelabelEnabled: boolean;
  children: React.ReactNode;
  companyInfo: CompanyInfo;
}

function JobPostCardContainer(props: JobPostCardContainerPropTypes) {
  const { atsCaptureEventsAhoy } = useFeatureFlags();
  const params = new URLSearchParams(window.location.search);
  const inIframe = window.self !== window.top;
  const sourceParam = params.get('sl_source') ?? (inIframe ? 'website' : null);

  const link = new URL(`${getUrlOriginPath()}/job_posts/${props.jobPost.id}`);
  link.searchParams.set('tab', 'overview');

  if (sourceParam) {
    link.searchParams.set('sl_source', sourceParam);
  }

  const handleClick = () => {
    if (atsCaptureEventsAhoy) {
      ahoy.track(JobPostClickEvents.JobPostClicked, {
        applicant_tracking_job_post_id: props.jobPost.id,
        organization_id: props.companyInfo.organizationId,
      });
    }
  };

  return (
    <a
      className={classNames(
        'card mb-3 app-card-body py-3 ps-4 pe-6h ps-sm-6h border rounded-3 shadow-sm',
        props.whitelabelEnabled ? styles['card-whitelabelled'] : styles.card,
      )}
      href={link.toString()}
      onClick={handleClick}
    >
      {props.children}
    </a>
  );
}

export function JobPostCard(props: PropTypes) {
  return (
    <JobPostCardContainer
      whitelabelEnabled={props.whitelabelEnabled}
      jobPost={props.jobPost}
      companyInfo={props.companyInfo}
    >
      <Row>
        <Col>
          <JobPostCardTitle
            title={props.jobPost.name}
            whitelabelEnabled={props.whitelabelEnabled}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <div className='d-flex flex-nowrap gap-2 pt-3'>
            <JobPostCardChip
              title={props.jobPost.job.department.name}
              whitelabelEnabled={props.whitelabelEnabled}
            />
            <JobPostCardChip
              title={props.jobPost.location.name}
              whitelabelEnabled={props.whitelabelEnabled}
            />
            <JobPostCardChip
              title={props.jobPost.job.locationType}
              whitelabelEnabled={props.whitelabelEnabled}
            />
          </div>
        </Col>
        <div className='col-4 text-end'>
          <span className='fs-5 fw-normal text-info'>Apply</span>
        </div>
      </Row>
    </JobPostCardContainer>
  );
}
