import React from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { PushToHrisFormValues } from '../..';
import { FirstAndLastNameRow } from './FirstAndLastNameRow';
import { ContactsRow } from './ContactsRow';
import { LocationRow } from './LocationRow';

interface PropTypes {
  register: UseFormRegister<PushToHrisFormValues>;
  control: Control<PushToHrisFormValues>;
  errors: FieldErrors;
}

export function PersonalInformationRows(props: PropTypes) {
  return (
    <>
      <h4 className='mb-3'>Personal Information</h4>
      <FirstAndLastNameRow register={props.register} errors={props.errors} />
      <ContactsRow
        register={props.register}
        errors={props.errors}
        control={props.control}
      />
      <LocationRow control={props.control} />
    </>
  );
}
