import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { EmptyState } from '../../../../components/EmptyState';
import { CreateJobPageRoutes } from '../CreateJobPageRoutes';
import { SetupJobPageRoutes } from '../SetupJobPageRoutes';
import { JobPage } from '../../../JobPage';
import { EditJobPage } from '../EditJobPage';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { JobPageTab } from '../../../JobPage/JobPageTab';
import { AccountIntegration } from '../../../../entities/AccountIntegration';
import { FeatureFlagContext } from '../../../../contexts/FeatureFlagContext';

interface PropTypes {
  atsSignableOfferEnabled: boolean;
  atsEditBeforeExportToHrisEnabled: boolean;
  emailAccountIntegration: AccountIntegration;
  jobBoardEnabled: boolean;
  duplicateJobEnabled: boolean;
  standardNpsCalculation: boolean;
  hrisProvider?: HrisProvider;
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  organicPostSkillsEnabled: boolean;
  generateJobDescriptionEnabled: boolean;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
  atsIndeedIntegrationEnabled: boolean;
}

function JobPageRouteTab(props: PropTypes & { activeTab: JobPageTab }) {
  return (
    <FeatureFlagContext.Provider
      value={{
        atsEditBeforeExportToHrisEnabled:
          props.atsEditBeforeExportToHrisEnabled,
      }}
    >
      <JobPage
        activeTab={props.activeTab}
        atsSignableOfferEnabled={props.atsSignableOfferEnabled}
        atsEditBeforeExportToHrisEnabled={
          props.atsEditBeforeExportToHrisEnabled
        }
        jobBoardEnabled={props.jobBoardEnabled}
        emailAccountIntegration={props.emailAccountIntegration}
        standardNpsCalculation={props.standardNpsCalculation}
        applyAndScheduleLinkEnabled={props.applyAndScheduleLinkEnabled}
        duplicateJobEnabled={props.duplicateJobEnabled}
        selfSchedulingLinkOptionsEnabled={
          props.selfSchedulingLinkOptionsEnabled
        }
      />
    </FeatureFlagContext.Provider>
  );
}

export function ApplicantTrackingJobsApp(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'jobs'}>
          <Route path={'new/*'} element={<CreateJobPageRoutes />} />
          <Route
            path={'setup/*'}
            element={
              <SetupJobPageRoutes
                hrisProvider={props.hrisProvider}
                jobRequisitionEnabled={props.jobRequisitionEnabled}
                jobRequisitionMandatory={props.jobRequisitionMandatory}
                updateJobRequisitionEnabled={true}
                organicPostSkillsEnabled={props.organicPostSkillsEnabled}
                generateJobDescriptionEnabled={
                  props.generateJobDescriptionEnabled
                }
                atsIndeedIntegrationEnabled={props.atsIndeedIntegrationEnabled}
              />
            }
          />
          <Route
            path={':id/edit/*'}
            element={
              <EditJobPage
                hrisProvider={props.hrisProvider}
                jobRequisitionMandatory={props.jobRequisitionMandatory}
                organicPostSkillsEnabled={props.organicPostSkillsEnabled}
                generateJobDescriptionEnabled={
                  props.generateJobDescriptionEnabled
                }
                atsIndeedIntegrationEnabled={props.atsIndeedIntegrationEnabled}
              />
            }
          />
          <Route
            path={':id/overview'}
            element={<JobPageRouteTab {...props} activeTab='Overview' />}
          />
          <Route
            path={':id/pipeline'}
            element={<JobPageRouteTab {...props} activeTab='Pipeline' />}
          />
          {props.jobBoardEnabled && (
            <Route
              path={':id/job_boards'}
              element={<JobPageRouteTab {...props} activeTab='Job Boards' />}
            />
          )}
          <Route
            path={':id'}
            element={<JobPageRouteTab {...props} activeTab='Overview' />}
          />
          <Route
            path='*'
            element={
              <EmptyState
                title={'Page not found!'}
                text={
                  'For more information contact our support team at support@screenloop.com'
                }
                cardless={true}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
