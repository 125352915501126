import React from 'react';
import { Controller, Path } from 'react-hook-form';
import {
  ArrayContext,
  updateArrayAttributeAt,
  ControllerContext,
} from '../../utils/reactHookForms';
import { LabelledSelect } from '../LabelledSelect';
import { SelectOption } from '../Select';

interface PropTypes<ControlType> {
  label: string;
  placeholder: string;
  formResetField?: (controllerName: string) => void;
  formHookContext?: ControllerContext<ControlType>;
  options: SelectOption[];
  visibleOptions?: SelectOption[];
  arrayContext?: ArrayContext;
  clearable?: boolean;
  danger?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  autoHideKeyboardOnSmallerScreen?: boolean;
}

export function LabelledSelectFormHook<ControlType>(
  props: PropTypes<ControlType>,
) {
  if (props.formHookContext == null) return null;

  return (
    <Controller
      control={props.formHookContext.formControl}
      name={props.formHookContext.controllerName as Path<ControlType>}
      rules={{ required: props.formHookContext.required }}
      render={({ field }) => (
        <LabelledSelect
          options={props.visibleOptions ?? props.options}
          selected={
            props.options.find((option) => field.value == option.value) ?? null
          }
          label={props.label}
          onChange={(option: SelectOption) => {
            if (props.arrayContext != null) {
              const metadata = props.arrayContext?.getArrayMetadata();
              const updated = updateArrayAttributeAt(
                props.arrayContext.arrayData,
                metadata,
                option,
              );
              props.arrayContext.updateArrayData(updated);
            }

            field.onChange(option?.value ?? null);
          }}
          onClear={() =>
            props.formResetField(props.formHookContext.controllerName)
          }
          placeholder={props.placeholder}
          mandatory={props.formHookContext.required}
          className={'fs-5'}
          labelClassName={'fw-semibold'}
          isClearable={props.clearable ?? true}
          danger={props.danger}
          disabled={props.disabled}
          isLoading={props.isLoading}
          isSearchable={props.isSearchable}
          autoHideKeyboardOnSmallerScreen={
            props.autoHideKeyboardOnSmallerScreen
          }
        />
      )}
    />
  );
}
