import React from 'react';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { Button } from 'reactstrap';

interface PropTypes {
  isOpen: boolean;
  onSubmit: () => void;
  setIsOpen: (open: boolean) => void;
}

function ModalBody(props: {
  onSubmit: () => void;
  setIsOpen: (open: boolean) => void;
}) {
  return (
    <>
      <div className='fs-5'>
        Are you sure you want to save and close? <br />
        Your job will only be posted after it&apos;s published.
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button
          color='danger'
          className='me-2'
          onClick={() => props.setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color='primary' onClick={props.onSubmit}>
          Save and Close
        </Button>
      </div>
    </>
  );
}

export function NotPublishedJobWarningModal(props: PropTypes) {
  return (
    <CloseableModal
      bodyChildren={
        <ModalBody onSubmit={props.onSubmit} setIsOpen={props.setIsOpen} />
      }
      className='modal-dialog-centered'
      isOpen={props.isOpen}
      size='md'
      headerTitle='Save and Close Job'
      onClose={() => props.setIsOpen(false)}
    />
  );
}
