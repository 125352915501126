import React, { useState } from 'react';
import { Section } from './Section';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { multiSelectStyle } from '../../../components/Select/multiSelectStyle';
import { UserService } from '../../../services/v1/UserService';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormFieldHeader } from '../../../components/FormFieldHeader';
import { selectTheme } from '../../../components/Select/selectTheme';
import { Option } from '../../../components/EmailRecipientCreatableMultiSelect/Option';
import { WIDE_CONTAINER_WIDTH } from './consts';
import { OtherFiles } from './OtherFiles';
import { FormState } from './FormState';
import { AlertMessage } from '../../../components/AlertMessage';
import { Icon } from '../../../components/Icon';
import { Button } from 'reactstrap';
import { VariablesModal } from './VariablesModal';
import { SelectOption } from '../../../components/Select';
import { FileInput } from './FileInput';

interface PropTypes {
  register: UseFormRegister<FormState>;
  control: Control<FormState>;
  watch: UseFormWatch<FormState>;
  resetField: UseFormResetField<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
}

export function DocumentsSection(props: PropTypes) {
  async function loadUsers(q: string, loadedOptions, { page }) {
    const list = await UserService.list({ name: q, page });
    const options = list.users.map((user) => ({
      label: user.email,
      subLabel: user.name,
      value: user.id,
    }));

    return {
      options,
      hasMore: !!list.nextPage,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <Section title='Documents'>
      <div className='d-flex flex-column gap-4'>
        <div style={{ maxWidth: WIDE_CONTAINER_WIDTH }}>
          <FormFieldHeader fieldName='Company Signatory' />
          <Controller
            control={props.control}
            name='signatories'
            render={({ field }) => (
              <AsyncPaginate
                isMulti
                defaultOptions
                loadOptions={loadUsers}
                additional={{ page: 1 }}
                value={field.value}
                onChange={(options: SelectOption[]) => field.onChange(options)}
                hideSelectedOptions={true}
                tabSelectsValue
                isClearable={false}
                className='fs-5'
                components={{ DropdownIndicator: null, Option }}
                theme={selectTheme}
                styles={multiSelectStyle}
                placeholder='Type to add one or more signatories'
              />
            )}
          />
          <small className='mt-2 d-flex text-dark-200'>
            If multiple signatories are added, the document is sent to the
            candidate once one signs.
          </small>
        </div>
        <AlertMessage
          icon={{ name: 'bi-info-circle' }}
          text='Upload documents with variables and e-signatures for candidates. Personalise it by creating a Word template (.docx) with variables in braces, e.g. {{{candidate_signature}}}. These will auto-fill with candidate details upon generation.'
          hasOverlay={false}
          className='bg-light-info rounded align-self-start fs-5'
        />
        <DocumentVariables />
        <div style={{ maxWidth: WIDE_CONTAINER_WIDTH }}>
          <FileInput
            register={props.register}
            control={props.control}
            watch={props.watch}
            resetField={props.resetField}
            getValues={props.getValues}
            setValue={props.setValue}
            controllerFileName='offerFile.fileName'
            controllerContentName='offerFile.content'
            fieldName='Offer Document'
            isRequired={true}
            disabled={false}
          />
        </div>
        <div style={{ maxWidth: WIDE_CONTAINER_WIDTH }}>
          <OtherFiles {...props} />
        </div>
      </div>
    </Section>
  );
}

function DocumentVariables() {
  const [open, setOpen] = useState(false);

  return (
    <div className='fs-5 fw-semibold d-flex align-items-baseline gap-2'>
      <VariablesModal open={open} onOpen={setOpen} />
      <Icon name={'bi-card-checklist'} />
      <span>Document Variables</span>
      <Button size='sm' color='secondary' onClick={() => setOpen(true)}>
        View
      </Button>
    </div>
  );
}
