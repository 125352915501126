import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { AlertObject } from '../../../../components/Alert';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { getHrisName } from '../../../../utils/hris';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ModalBody } from './ModalBody';
import { LocationEntity } from '../../../../services/GeoLocationService';
import { ApplicationService as V1ApplicationService } from '../../../../services/v1/ApplicationService';
import { Application } from '../../../../entities/v1/applicant_tracking/Application';

interface PropTypes {
  applicationIds: number[];
  applicationId: number;
  allowedHrisProvider: HrisProvider;
  isOpen: boolean;
  onClose: (succeeded: boolean) => void;
  setAlert: (alert: AlertObject) => void;
}
interface DocumentFile {
  id: number;
  fileName: string;
  fileContent: string;
}

interface FormValuesSelectOption {
  value: string;
  label: string;
}
export interface PushToHrisFormValues {
  firstName: string;
  lastName: string;
  personalEmail: string;
  workEmail: string;
  phoneNumber: string;
  location: LocationEntity;
  office: FormValuesSelectOption;
  jobName: string;
  department: FormValuesSelectOption;
  resume: DocumentFile;
  coverLetter: DocumentFile;
}

export function EditablePushToHrisModal(props: PropTypes) {
  const [application, setApplication] = useState<Application>(null);
  const [applicatioIsLoading, setApplicationIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!props.isOpen) return;

    (async () => {
      try {
        const app = await V1ApplicationService.show(props.applicationId, [
          'candidate',
          'job',
          'job.department',
          'application_resume',
          'application_cover_letter',
          'job_post',
          'job_post.location',
        ]);
        setApplication(app);
      } catch (error) {
        console.error('Error fetching application:', error);
      } finally {
        setApplicationIsLoading(false);
      }
    })();
  }, [props.isOpen]);

  if (!props.isOpen) return null;

  return applicatioIsLoading ? (
    <LoadingSpinner />
  ) : (
    <CloseableModal
      className={classNames('modal-dialog-centered', styles['modal'])}
      onClose={() => props.onClose(null)}
      isOpen={true}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle={`Export to ${getHrisName(props.allowedHrisProvider)}`}
      bodyChildren={
        <>
          {isLoading && <LoadingSpinner showBackdrop={true} />}
          <ModalBody
            application={application}
            applicationIds={props.applicationIds}
            setAlert={props.setAlert}
            setIsLoading={setIsLoading}
            hrisProvider={props.allowedHrisProvider}
            onClose={props.onClose}
          />
        </>
      }
    />
  );
}
