import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  FileTypeValidator,
  SingleAttachmentInputFormField,
} from '../../../components/SingleAttachmentInputFormField';
import React from 'react';
import { UseFilePickerConfig, Validator } from 'use-file-picker';
import { FileWithPath } from 'file-selector';
import { FormState } from './FormState';
import { FileService } from '../../../services/v1/security/FileService';
import { SignableOfferTemplateService } from '../../../services/v1/applicant_tracking/SignableOfferTemplateService';

interface PropTypes {
  register: UseFormRegister<FormState>;
  control: Control<FormState>;
  watch: UseFormWatch<FormState>;
  resetField: UseFormResetField<FormState>;
  getValues: UseFormGetValues<FormState>;
  setValue: UseFormSetValue<FormState>;
  controllerFileName: string;
  controllerContentName: string;
  fieldName: string;
  isRequired: boolean;
  disabled: boolean;
}

export function FileInput(props: PropTypes) {
  return (
    <SingleAttachmentInputFormField<FormState>
      getValues={props.getValues}
      setValue={props.setValue}
      formRegister={props.register}
      headerClassNames='fw-semibold'
      controllerFileName={props.controllerFileName}
      controllerContentName={props.controllerContentName}
      fieldName={props.fieldName}
      allowedFormats={['.docx']}
      maxFileSizeMb={2}
      isRequired={props.isRequired}
      disabled={props.disabled}
      hideAlert={true}
      validators={[new FileTypeValidator(), new VariablesValidator()]}
    />
  );
}

class VariablesValidator implements Validator {
  async validateBeforeParsing(
    _config: UseFilePickerConfig,
    plainFiles: File[],
  ) {
    const file = plainFiles[0];
    const content = await FileService.readFile(file);
    const vars = await this.fetchVariables(content);
    if (!vars.includes('candidate_signature')) {
      throw {
        code: 'missingVariableError',
        reason:
          'E-signature variable is missing or your variables do not follow the {{{example_example}}} format.',
      };
    }
  }

  private async fetchVariables(content: string) {
    try {
      const { documentVariables } =
        await SignableOfferTemplateService.listVariables(content);
      return documentVariables;
    } catch (err) {
      throw { name: err.code, reason: err.message };
    }
  }

  async validateAfterParsing(
    _config: UseFilePickerConfig,
    _file: FileWithPath,
    _reader: FileReader,
  ) {
    return Promise.resolve();
  }
}
