import { HrisProvider } from '../enums/IntegrationProvider/HrisProvider';
import BambooHRLogo from '../images/logos/bamboo_hr.png';
import FactorialHRLogo from '../images/logos/factorial_hr.png';
import HiBobLogo from '../images/logos/hi_bob.png';
import ZeltLogo from '../images/logos/zelt.png';
import PersonioLogo from '../images/logos/personio.png';

export function getHrisName(hrisProvider: HrisProvider) {
  switch (hrisProvider) {
    case HrisProvider.BambooHr:
      return 'BambooHR';
    case HrisProvider.FactorialHr:
      return 'FactorialHr';
    case HrisProvider.HiBob:
      return 'HiBob';
    case HrisProvider.Personio:
      return 'Personio';
    case HrisProvider.Zelt:
      return 'Zelt';
    default:
      return 'Hris';
  }
}

export function getHrisLogo(hrisProvider?: HrisProvider | string) {
  if (!hrisProvider) return null;

  const hrisProviderStr = hrisProvider.toString();

  switch (hrisProviderStr) {
    case HrisProvider.BambooHr.toString():
      return BambooHRLogo;
    case HrisProvider.FactorialHr.toString():
      return FactorialHRLogo;
    case HrisProvider.HiBob.toString():
      return HiBobLogo;
    case HrisProvider.Personio.toString():
      return PersonioLogo;
    case HrisProvider.Zelt.toString():
      return ZeltLogo;
  }
}
