import React, { useState } from 'react';
import { JobDetails } from '../../../../entities/JobDetails';
import { appendQueryParam, appendRedirectUri } from '../../../../utils/url';
import { PublishButton } from './PublishButton';
import { jobPostLink } from './JobPostButton';
import { IconButton } from '../../../../components/IconButton';
import { Dropdown } from '../../../../components/Dropdown';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {
  CREATE_JOB_URL,
  REFERENCE_JOB_ID_QUERY_PARAM,
} from '../../../applicant_tracking/Jobs/DuplicateJob/DuplicateJobButton';
import { IconSpan } from '../../../../components/IconSpan';
import { ArchiveJobConfirmationModal } from '../../../applicant_tracking/Jobs/ArchiveJob/ArchiveJobConfirmationModal';

interface PropTypes {
  jobDetails: JobDetails;
  updateEnabled: boolean;
  subdomain?: string;
  duplicateJobEnabled: boolean;
}

function duplicateJobButton(jobId: number) {
  return {
    action: () =>
      (window.location.href = appendQueryParam(
        CREATE_JOB_URL,
        REFERENCE_JOB_ID_QUERY_PARAM,
        jobId.toString(),
      )),
    buttonChild: (
      <IconSpan
        className='text-info'
        spanText='Duplicate'
        icon={{ name: 'bi-files-alt' }}
      />
    ),
  };
}

function archiveJobButton(archiveAction: string, onClick: () => void) {
  return {
    action: onClick,
    buttonChild: (
      <IconSpan
        className='text-info'
        spanText={archiveAction}
        icon={{ name: 'bi-archive' }}
      />
    ),
  };
}

export function NavActions(props: PropTypes) {
  const [isArchiveJobModalOpen, setIsArchiveJobModalOpen] = useState(false);
  const archiveAction =
    props.jobDetails.status === 'archived' ? 'Unarchive' : 'Archive';

  const editJob = () => {
    const jobId = window.location.pathname.split('/')[2];

    window.location.href = appendRedirectUri(`/jobs/${jobId}/edit/job_details`);
  };

  return (
    <div
      className='d-flex align-items-center text-nowrap'
      data-testid='job-page-nav-actions'
    >
      {props.jobDetails.status === 'published' && (
        <IconButton
          color='borderless'
          onClick={() =>
            window.open(
              jobPostLink(props.jobDetails.jobPosts, props.subdomain),
              '_blank',
              'noopener,noreferrer',
            )
          }
          iconName='bi-box-arrow-up-right'
          buttonText='View Job Post'
          buttonClass='me-3'
        />
      )}
      <PublishButton {...props} />
      <IconButton
        color='secondary'
        onClick={editJob}
        disabled={!props.updateEnabled}
        iconName='bi-pencil'
        buttonClass='me-2'
      />
      {props.duplicateJobEnabled && (
        <Dropdown
          buttonClassName={classNames(
            'btn btn-secondary',
            styles['dropdown-button'],
          )}
          buttonIcon={{
            name: 'bi-three-dots-vertical',
            className: 'text-info',
          }}
          menuPlacementClass={'dropdown-menu-end'}
          menuActions={[
            duplicateJobButton(props.jobDetails.id),
            archiveJobButton(archiveAction, () =>
              setIsArchiveJobModalOpen(true),
            ),
          ]}
        />
      )}
      <ArchiveJobConfirmationModal
        jobId={props.jobDetails.id}
        jobName={props.jobDetails.name}
        archiveAction={archiveAction}
        isModalOpen={isArchiveJobModalOpen}
        redirectUri={window.location.href}
        setIsModalOpen={setIsArchiveJobModalOpen}
      />
    </div>
  );
}
