import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { BodyWrapper } from './BodyWrapper';
import { Footer } from './Footer';
import { ConnectEmailIntegrationModal } from '../../../SendEmailModal/ConnectEmailIntegrationModal';
import { SelectOption } from '../../../../components/Select';
import { AlertObject, AlertType } from '../../../../components/Alert';
import classNames from 'classnames';
import { AttachmentDto } from '../../../../services/dtos/EmailDto';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import { DEFAULT_CURRENCY } from '../../../../utils/currency';
import { EmailUserIntegration } from '../../../../entities/EmailUserIntegration';
import { UserService } from '../../../../services/v1/UserService';
import { ReconnectEmailIntegrationModalBody } from '../../../../components/ReconnectEmailIntegrationModal/Modal';
import {
  isFailedIntegration,
  getConnectionEmailUrl,
} from '../../../../utils/espUserIntegration';
import { EmailSizeValidator, fileSizeBytes } from '../../../../utils/emails';
import { SignableOfferTemplate } from '../../../../entities/v1/applicant_tracking/SignableOfferTemplate';
import { SignableOfferService } from '../../../../services/v1/applicant_tracking/SignableOfferService';
import { SignableOffer } from '../../../../entities/v1/applicant_tracking/SignableOffer';
import { ApiError } from '../../../../services/ApiService/errors/ApiError';
import { getNounWithPossessiveCase } from '../../../../utils/grammar';
import { variablesToMap } from '../variables';

interface PropTypes {
  applicationId: number;
  hasEmailConnected: boolean;
  isOpen: boolean;
  to: string;
  onClose: (succedded?: boolean) => void;
  setAlert: (alert: AlertObject) => void;
}

export interface SignableOfferForm {
  // email related
  attachments: AttachmentDto[];
  to: string;
  cc?: SelectOption[];
  bcc?: SelectOption[];
  email?: string;
  emailJson?: string;
  subject?: string;
  subjectJson?: string;

  // offer form related
  signableOfferTemplate: SignableOfferTemplate;
  bonus?: number;
  bonusCurrency?: string;
  candidateName?: string;
  companyName?: string;
  directManager?: string;
  equity?: number;
  jobTitle?: string;
  offerExpirationDate?: string;
  paidTimeOff?: number;
  salary?: number;
  salaryCurrency?: string;
  senderName?: string;
  sickDays?: number;
  startingDate?: string;
  todayDate?: string;
  workingHours?: number;
  customVariables?: Map<string, any>;
}

const ATTACHMENT_MAX_FILES = 6;
const ATTACHMENT_MAX_SIZE_MB = 10;

const INITIAL_FORM = {
  attachments: [],
  to: null,
  cc: null,
  bcc: null,
  email: null,
  emailJson: null,
  subject: null,
  subjectJson: null,

  signableOfferTemplate: null,
  bonus: null,
  bonusCurrency: DEFAULT_CURRENCY.label,
  candidateName: null,
  companyName: null,
  directManager: null,
  equity: null,
  jobTitle: null,
  offerExpirationDate: null,
  paidTimeOff: null,
  salary: null,
  salaryCurrency: DEFAULT_CURRENCY.label,
  senderName: null,
  sickDays: null,
  startingDate: null,
  todayDate: null,
  workingHours: null,
  customVariables: new Map<string, any>(),
};

async function handleSubmit(
  form: SignableOfferForm,
  applicationId: number,
  onClose: (succedded?: boolean) => void,
  setAlert: (alert: AlertObject) => void,
  setLoading: (loading: boolean) => void,
) {
  setLoading(true);
  await SignableOfferService.create({
    applicationId: applicationId,
    emailHtml: form.email,
    signableOfferTemplateId: form.signableOfferTemplate.id,
    subjectText: form.subject,
    to: form.to,
    bcc: form.bcc?.map((c: SelectOption) => c.value),
    cc: form.cc?.map((c: SelectOption) => c.value),
    variables: JSON.stringify(variablesToMap(form)),
    attachments: form.attachments,
  })
    .then((offer: SignableOffer) => {
      setAlert({
        message: (
          <span>
            <b>
              {getNounWithPossessiveCase(
                offer.application?.candidate?.name || 'Candidate',
              )}
            </b>{' '}
            Offer for <b>{offer.application?.job?.name || 'the job'}</b> was
            successfully sent!
          </span>
        ),
        type: AlertType.Success,
      });
      setLoading(false);
      onClose(true);
    })
    .catch((e: ApiError) => {
      setAlert({
        message: e.body?.message || 'It was not possible to send the offer',
        type: AlertType.Danger,
      });
      setLoading(false);
      onClose(false);
    });
}

function buildTitle(step: number, failedIntegration: boolean) {
  if (failedIntegration) {
    return 'Email Integration Expired';
  }

  return step === 3 ? 'Offer Summary' : 'Send Offer';
}

export function SendSignableOfferModal(props: PropTypes) {
  const [form, setForm] = useState<SignableOfferForm>(INITIAL_FORM);
  const [step, setStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [validStep, setValidStep] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [emailIntegration, setEmailIntegration] =
    useState<EmailUserIntegration>(null);
  const [emailIntegrationsLoading, setEmailIntegrationsLoading] =
    useState<boolean>(true);
  const hasEmailConnected =
    (emailIntegrationsLoading || props.hasEmailConnected) &&
    isFailedIntegration(emailIntegration) === false;
  const bodyIdentifier = 'offer-email-body';

  const handleRemoveFile = (index: number) => {
    const fileToDelete = form.attachments[index];
    const plainFileToDelete = plainFiles.find(
      (i) => i.name === fileToDelete.filename,
    );

    if (plainFileToDelete != null) {
      removeFileByReference(plainFileToDelete);
    }

    setForm({
      ...form,
      attachments: form.attachments.filter((_i, idx) => idx !== index),
    });
  };

  const [
    openFileSelector,
    {
      filesContent,
      errors,
      removeFileByReference,
      plainFiles,
      clear: clearUploadedFiles,
    },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: ATTACHMENT_MAX_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: {
      max:
        ATTACHMENT_MAX_FILES -
        form.attachments.filter((i) => i.id != null).length,
    },
    validators: [new EmailSizeValidator(fileSizeBytes(form.attachments))],
  });

  useEffect(() => {
    const newAttachmentDto = [...form.attachments];

    filesContent.forEach((fileContent: FileContent, index: number) => {
      if (
        newAttachmentDto.find((i) => fileContent.name == i.filename) == null
      ) {
        newAttachmentDto.push({
          size: plainFiles[index].size,
          filename: fileContent.name,
          contentBase64: fileContent.content,
        });
      }
    });

    setForm({
      ...form,
      attachments: newAttachmentDto,
    });

    setIsValid(validStep);
  }, [filesContent, validStep]);

  useEffect(() => {
    const emailBodyContainer = document.querySelector(`#${bodyIdentifier}`);
    if (emailBodyContainer) {
      emailBodyContainer.scrollTop = emailBodyContainer.scrollHeight;
    }
  }, [form.attachments]);

  useEffect(() => {
    props.isOpen &&
      UserService.showEmailIntegration()
        .then(setEmailIntegration)
        .finally(() => setEmailIntegrationsLoading(false));
  }, [props.isOpen]);

  return (
    <CloseableModal
      size='xl'
      isOpen={props.isOpen}
      className='modal-dialog-centered'
      onClose={props.onClose}
      headerTitle={buildTitle(step, isFailedIntegration(emailIntegration))}
      bodyClassName={classNames({ 'p-0': hasEmailConnected && step < 3 })}
      bodyChildren={
        isFailedIntegration(emailIntegration) ? (
          <ReconnectEmailIntegrationModalBody
            largeImage
            url={getConnectionEmailUrl()}
            onClose={props.onClose}
          />
        ) : hasEmailConnected ? (
          <BodyWrapper
            elementIdentifier={bodyIdentifier}
            applicationId={props.applicationId}
            form={form}
            isLoading={isLoading}
            to={props.to}
            step={step}
            clearUploadedFiles={clearUploadedFiles}
            setForm={setForm}
            setValidStep={setValidStep}
            files={plainFiles}
            removeAttachmentFileByIndex={handleRemoveFile}
          />
        ) : (
          <ConnectEmailIntegrationModal
            url={getConnectionEmailUrl()}
            onClose={() => {}}
          />
        )
      }
      footerChildren={
        hasEmailConnected && (
          <Footer
            files={plainFiles}
            isValid={isValid}
            isLoading={emailIntegrationsLoading}
            step={step}
            setStep={setStep}
            onClose={props.onClose}
            onSubmit={() =>
              handleSubmit(
                form,
                props.applicationId,
                props.onClose,
                props.setAlert,
                setIsLoading,
              )
            }
            openFileSelector={openFileSelector}
            attachmentErrors={errors}
            maxFiles={ATTACHMENT_MAX_FILES}
            maxFileSizeMb={ATTACHMENT_MAX_SIZE_MB}
          />
        )
      }
    />
  );
}
