import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';

interface PropTypes {
  label: string;
  placeholder?: string;
  value?: number;
  setValue: (value: number) => void;
}

export function NumericInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={props.label} isRequired={true} />
      <Input
        type='number'
        className='fs-5'
        placeholder={props.placeholder ?? `Insert ${props.label}`}
        defaultValue={props.value}
        onChange={(event) =>
          props.setValue(event.target.value as unknown as number)
        }
      />
    </>
  );
}
