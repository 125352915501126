import snakecaseKeys from 'snakecase-keys';
import { SignableOfferForm } from './SendSignableOfferModal';

export interface Variable {
  name: string;
  variable: string;
  type: 'Numeric' | 'Signature' | 'Date' | 'Text' | 'Salary';
  label: string;
  form: boolean;
}

export const VARIABLES: Variable[] = [
  {
    name: 'bonus',
    variable: 'Bonus',
    type: 'Numeric',
    label: 'Bonus',
    form: true,
  },
  {
    name: 'candidate_signature',
    variable: 'Candidate E-Signature',
    type: 'Signature',
    label: 'Candidate E-Signature',
    form: false,
  },
  {
    name: 'candidate_date',
    variable: 'Candidate E-Signature Date',
    type: 'Date',
    label: 'Candidate E-Signature Date',
    form: false,
  },
  {
    name: 'candidate_initials',
    variable: 'Candidate E-Signature Initials',
    type: 'Signature',
    label: 'Candidate E-Signature Initials',
    form: false,
  },
  {
    name: 'candidate_name',
    variable: "Candidate's Name",
    type: 'Text',
    label: "Candidate's Name",
    form: true,
  },
  {
    name: 'company_signature',
    variable: 'Company E-Signature',
    type: 'Signature',
    label: 'Company Signature',
    form: false,
  },
  {
    name: 'company_date',
    variable: 'Company E-Signature Date',
    type: 'Date',
    label: 'Company Signature Date',
    form: false,
  },
  {
    name: 'company_initials',
    variable: 'Company E-Signature Initials',
    type: 'Signature',
    label: 'Company Signature Initials',
    form: false,
  },
  {
    name: 'company_name',
    variable: 'Company Name',
    type: 'Text',
    label: 'Company Name',
    form: true,
  },
  {
    name: 'direct_manager',
    variable: 'Direct Manager',
    type: 'Text',
    label: 'Company Signature',
    form: true,
  },
  {
    name: 'equity',
    variable: 'Equity',
    type: 'Numeric',
    label: 'Equity',
    form: true,
  },
  {
    name: 'job_title',
    variable: 'Job Title',
    type: 'Text',
    label: 'Job Title',
    form: true,
  },
  {
    name: 'offer_expiration_date',
    variable: 'Offer Expiration Date',
    type: 'Date',
    label: 'Offer Expiration Date',
    form: true,
  },
  {
    name: 'paid_time_off',
    variable: 'Paid Time Off',
    type: 'Numeric',
    label: 'Paid Time Off',
    form: true,
  },
  {
    name: 'salary',
    variable: 'Salary',
    type: 'Salary',
    label: 'Salary',
    form: true,
  },
  {
    name: 'sender_name',
    variable: 'Sender Name',
    type: 'Text',
    label: 'Sender Name',
    form: true,
  },
  {
    name: 'sick_days',
    variable: 'Sick Days',
    type: 'Numeric',
    label: 'Sick Days',
    form: true,
  },
  {
    name: 'starting_date',
    variable: 'Starting Date',
    type: 'Date',
    label: 'Starting Date',
    form: true,
  },
  {
    name: 'today_date',
    variable: "Today's Date",
    type: 'Date',
    label: "Today's Date",
    form: true,
  },
  {
    name: 'working_hours',
    variable: 'Working Hours',
    type: 'Numeric',
    label: 'Working Hours',
    form: true,
  },
];

export function variablesToMap(form: SignableOfferForm): Record<string, any> {
  const newMap = form.customVariables;

  const snakeCasedForm = snakecaseKeys(form);

  VARIABLES.filter((v) => v.form === true).forEach((v) => {
    const fieldName = v.name as keyof SignableOfferForm;

    newMap.set(v.name, snakeCasedForm[fieldName]);
  });

  return Object.fromEntries(newMap);
}
