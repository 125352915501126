import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../../../../../components/ToggleButton';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { OrganicPostService } from '../../../../../../services/applicant_tracking/analytics/jobboards/OrganicPostService';
import { OrganicPost } from '../../../../../../entities/applicant_tracking/jobboards/OrganicPost';
import { OrganicPostRow } from './OrganicPostRow';
import { JobPostingDetailsForm } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails';
import { HorizontalDivider } from '../../../../../../components/HorizontallDivider';
import classNames from 'classnames';

interface PropTypes {
  enabled: boolean;
  form: JobPostingDetailsForm;
  setOrganicEnabled: () => void;
}

export function PostingBundle(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [organicPosts, setOrganicPosts] = useState<OrganicPost[]>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await OrganicPostService.list();
        setOrganicPosts(
          result?.organicPosts?.sort((a, b) => a.order - b.order),
        );
      } catch (e: any) {
        console.error('It was not able to get the organic job posts', e);
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    <div className='border border-blue-gray-500 rounded-3 py-4 ps-4 pe-6h'>
      <div className='d-flex flex-nowrap justify-content-between mb-4 align-items-center'>
        <h3 className='mb-0'>Posting Bundle</h3>
        <div>
          <span className='fs-5'>{props.enabled ? 'Enabled' : 'Disabled'}</span>
          <ToggleButton
            name='JobBoards'
            checked={props.enabled}
            onChange={props.setOrganicEnabled}
            color='info'
            classNames='ms-2h'
          />
        </div>
      </div>
      <HorizontalDivider classNames='mb-3' />
      <div
        className={classNames(
          'd-flex flex-column gap-4',
          !props.enabled && 'opacity-50',
        )}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          organicPosts?.map((v: OrganicPost, i: number) => (
            <OrganicPostRow
              key={i}
              index={i}
              organicPost={v}
              hasCompanyIdFilled={!!props.form?.companyId || false}
            />
          ))
        )}
      </div>
    </div>
  );
}
